import React, { useContext } from 'react';
import Typography from '@mui/material/Typography';
import Title from '../../resources/Title';
import { OTAContext } from '../../../context/OTAContext'
import Link from '@mui/material/Link';
import * as Constants from '../../../components/api/constants' 
import fileDownload from 'js-file-download'

export default function OTAFirmware() {
  const state = useContext(OTAContext);
  const downloadFirmware = () => {
    const requestOptions = {
      method: 'GET', 
      credentials: "include"
    };

    fetch(Constants.baseURL + Constants.apiFirmwareDownload + '/0', requestOptions) 
    .then(response => { 
      if (!response.ok && response.status !== 404) {
          this.props.history.push('/');
      }
      return response })
    .then(data => {  
      fileDownload(data, 'V' + state.version + '.bin');
    }) 
    .catch(error => { console.log('request failed', error); }); 
  };

  return (
    <React.Fragment>
      <Title>Current Firmware Version</Title>
      <Typography component="p" variant="h4" sx={{ flex: 1, textAlign: 'center', pt: 8 }}>
        {state.version === undefined || state.version === '' ? 'Please upload a firmware file' : state.version}
      </Typography>
    </React.Fragment>
  );
}