import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import RemoteControlIcon from '@mui/icons-material/SettingsRemote';
import TimelineIcon from '@mui/icons-material/Timeline';
import SettingsIcon from '@mui/icons-material/Settings';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import BlacklistIcon from '@mui/icons-material/DoDisturb';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PersonIcon from '@mui/icons-material/Person';
import ClientIcon from '@mui/icons-material/Group';
import HotTubIcon from '@mui/icons-material/HotTub';
import HailIcon from '@mui/icons-material/Hail';
import { Link } from 'react-router-dom';

export const mainListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      <b>Spa Controller</b>
    </ListSubheader>
    <ListItemButton to='/Dashboard' component={Link}>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItemButton>
    <ListItemButton to='/Control' component={Link}>
      <ListItemIcon>
        <RemoteControlIcon />
      </ListItemIcon>
      <ListItemText primary="Control" />
    </ListItemButton>
    <ListItemButton to='/History' component={Link}>
      <ListItemIcon>
        <TimelineIcon />
      </ListItemIcon>
      <ListItemText primary="History" />
    </ListItemButton>
    <ListItemButton to='/EEPROM' component={Link}>
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText primary="Settings" />
    </ListItemButton>
  </React.Fragment>
);

export const mainListItemsHeatPump = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      <b>Spa Controller</b>
    </ListSubheader>
    <ListItemButton to='/Dashboard' component={Link}>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItemButton>
    <ListItemButton to='/Control' component={Link}>
      <ListItemIcon>
        <RemoteControlIcon />
      </ListItemIcon>
      <ListItemText primary="Control" />
    </ListItemButton>
    <ListItemButton to='/History' component={Link}>
      <ListItemIcon>
        <TimelineIcon />
      </ListItemIcon>
      <ListItemText primary="History" />
    </ListItemButton>
    <ListItemButton to='/EEPROM' component={Link}>
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText primary="Settings" />
    </ListItemButton>
    <ListItemButton to='/HPDashboard' component={Link}>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="HP Dashboard" />
    </ListItemButton>
  </React.Fragment>
);

export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      <b>Heat Pump</b>
    </ListSubheader>
    <ListItemButton to='/HPDashboard' component={Link}>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItemButton>
    <ListItemButton to='/HPSpecialFunctions' component={Link}>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Special Functions" />
    </ListItemButton>
    <ListItemButton to='/HPHistory' component={Link}>
      <ListItemIcon>
        <TimelineIcon />
      </ListItemIcon>
      <ListItemText primary="History" />
    </ListItemButton>
    <ListItemButton to='/HPEEPROM' component={Link}>
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText primary="Settings" />
    </ListItemButton>
  </React.Fragment>
);

export const adminListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      <b>Admin</b>
    </ListSubheader>
    <ListItemButton to='/Users' component={Link}>
      <ListItemIcon>
        <PersonIcon />
      </ListItemIcon>
      <ListItemText primary="Users" />
    </ListItemButton>
    <ListItemButton to='/Spas' component={Link}>
      <ListItemIcon>
        <HotTubIcon />
      </ListItemIcon>
      <ListItemText primary="Spas" />
    </ListItemButton>
    <ListItemButton to='/Clients' component={Link}>
      <ListItemIcon>
        <ClientIcon />
      </ListItemIcon>
      <ListItemText primary="Clients" />
    </ListItemButton>
    <ListItemButton to='/Dealers' component={Link}>
      <ListItemIcon>
        <HailIcon />
      </ListItemIcon>
      <ListItemText primary="Dealers" />
    </ListItemButton>
    <ListItemButton to='/OTA' component={Link}>
      <ListItemIcon>
        <SystemUpdateAltIcon />
      </ListItemIcon>
      <ListItemText primary="OTA Firmware" />
    </ListItemButton>
    <ListItemButton to='/Blacklist' component={Link}>
      <ListItemIcon>
        <BlacklistIcon />
      </ListItemIcon>
      <ListItemText primary="Blacklist" />
    </ListItemButton>
  </React.Fragment>
);

export const adminDealerListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      <b>Admin</b>
    </ListSubheader>
    <ListItemButton to='/Users' component={Link}>
      <ListItemIcon>
        <PersonIcon />
      </ListItemIcon>
      <ListItemText primary="Users" />
    </ListItemButton>
  </React.Fragment>
);

export const dealerListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      <b>Dealer</b>
    </ListSubheader>
    <ListItemButton to='/DealerSpas' component={Link}>
      <ListItemIcon>
        <HotTubIcon />
      </ListItemIcon>
      <ListItemText primary="Spas" />
    </ListItemButton>
  </React.Fragment>
);