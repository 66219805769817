import React, { useContext } from 'react';
import Title from '../resources/Title';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DynamicSelect from '../resources/DynamicSelect';
import { MainContext } from '../../context/MainContext';
import * as Constants from '../../components/api/constants'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Generate Order Data
function createData(id, property, state, possibleStates1, possibleStates2, possibleStates3) {
  return { id, property, state, possibleStates1, possibleStates2, possibleStates3 };
}

let deviceId = 0;

const buttonWidth = '20%';

const selectDataArray = [
  {
    id: 0,
    value: ''    
  },
  {
    id: 1,
    value: ''    
  },
  {
    id: 2,
    value: ''    
  },
  {
    id: 3,
    value: ''    
  },
  {
    id: 4,
    value: ''    
  },
  {
    id: 5,
    value: ''    
  },
  {
    id: 6,
    value: ''    
  },
];

const arrayOfData5 = [
  {
    id: '1',
    name: '1'    
  },
  {
    id: '2',
    name: '2'    
  },
  {
    id: '3',
    name: '3'    
  },
  {
    id: '4',
    name: '4'    
  },
  {
    id: '5',
    name: '5'    
  },
];

const lightArrayData = [];

for (let i = 1; i <= 30; i++) {
  lightArrayData.push({
    id: i,
    name: i.toString()    
  });
}

const lightModeData = [
  {
    id: '1',
    name: 'WHITE'    
  },
  {
    id: '2',
    name: 'UCLR'    
  },
  {
    id: '3',
    name: 'FADE'    
  },
  {
    id: '4',
    name: 'STEP'    
  },
  {
    id: '5',
    name: 'PARTY'    
  },
];

const hourData = [
  {
    id: '1',
    name: '12'    
  },
  {
    id: '2',
    name: '24'    
  }
];

const temperatureArrayData = [];

var i = 10.0;
var idCounter = 1;
while (i <= 41.0) {
  i = i + 0.2;
  temperatureArrayData.push({
    id: idCounter,
    name: i.toFixed(1)    
  });
  idCounter++;
}

const handleSelectChange = (id, selectedValue) => {
  selectDataArray[id].value = selectedValue;
}

export default function EEPROMGeneral() {
  const state = useContext(MainContext).state;
  deviceId = state.selectedDevice;
  
  const sendCommand = (command, value) => {
    const requestOptions = { 
      method: 'POST',
      headers: { 'Accept': 'application/json', 
                 'Content-Type': 'application/json', }, 
      body: JSON.stringify({ DeviceId: deviceId,
                              Command: command, 
                              Value: value }),  
      credentials: "include"
    };
  
    fetch(Constants.baseURL + Constants.apiSendCommand, requestOptions) 
        .then((response) => { 
            if (response.ok) {
              toast.success('Command sent successfully. Refreshing...', {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
                
              setTimeout(function() {
                state.refreshDeviceRF(deviceId);
              }, 6000);

              setTimeout(function() {
                state.getUserDeviceRf(deviceId);
              }, 12000);
            } else {
              toast.error('Send failed. Please try again', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            }
        }).catch(error => { console.log('request failed', error); });
  }

  const onClick = (id) => {
    if (id === 0) {
      sendCommand('blowerLevel', selectDataArray[id].value);
    } else if (id === 1) {
      sendCommand('lightBrightness', selectDataArray[id].value);
    } else if (id === 2) {
      sendCommand('lightColour', selectDataArray[id].value);
    } else if (id === 3) {
      sendCommand('lightMode', selectDataArray[id].value);
    } else if (id === 4) {
      sendCommand('lightSpeed', selectDataArray[id].value);
    } else if (id === 5) {
      sendCommand('hourType', selectDataArray[id].value);
    } else if (id === 6) {
      sendCommand('setTemperature', selectDataArray[id].value);
    }
  }

  const rows = [
    createData(0, 'Blower level', 'N/A', <DynamicSelect arrayOfData={arrayOfData5} onSelectChange={handleSelectChange} id={0} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick(0)}}>Send</Button>),
    createData(1, 'Light brightness', 'N/A', <DynamicSelect arrayOfData={arrayOfData5} onSelectChange={handleSelectChange} id={1} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick(1)}}>Send</Button>),
    createData(2, 'Light colour', 'N/A', <DynamicSelect arrayOfData={lightArrayData} onSelectChange={handleSelectChange} id={2} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick(2)}}>Send</Button>),
    createData(3, 'Light mode', 'N/A', <DynamicSelect arrayOfData={lightModeData} onSelectChange={handleSelectChange} id={3} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick(3)}}>Send</Button>),
    createData(4, 'Light speed', 'N/A', <DynamicSelect arrayOfData={arrayOfData5} onSelectChange={handleSelectChange} id={4} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick(4)}}>Send</Button>),
    createData(5, '12/24 hours', 'N/A', <DynamicSelect arrayOfData={hourData} onSelectChange={handleSelectChange} id={5} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick(5)}}>Send</Button>),
    createData(6, 'Set temperature', 'N/A', <DynamicSelect arrayOfData={temperatureArrayData} onSelectChange={handleSelectChange} id={6} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick(6)}}>Send</Button>),
  ];

  const r6 = state.R6.split(',');
  rows[0].state = r6[1];
  rows[1].state = r6[2];
  rows[2].state = r6[3];

  if (r6[4] === '0') rows[3].state = 'White';
  if (r6[4] === '1') rows[3].state = 'UCLR';
  if (r6[4] === '2') rows[3].state = 'FADE';
  if (r6[4] === '3') rows[3].state = 'STEP';
  if (r6[4] === '4') rows[3].state = 'PARTY';

  rows[4].state = r6[5];
  
  if (r6[9] === '0') rows[5].state = '24';
  if (r6[9] === '1') rows[5].state = '12';

  rows[6].state = (r6[8]/10.0).toFixed(1) + '°C';

  return (
    <React.Fragment>
      <ToastContainer />
      <Title>General</Title>
      <Table size="small" >
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.property}</TableCell>
              <TableCell>{row.state}</TableCell>
              <TableCell style={{textAlign: 'center'}}>{row.possibleStates1}</TableCell>
              <TableCell style={{textAlign: 'left'}}>{row.possibleStates2}</TableCell>
              <TableCell style={{textAlign: 'center'}}>{row.possibleStates3}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}