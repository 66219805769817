import React, { Component } from 'react'; 
import * as Constants from './api/constants' 
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Copyright from './copyright.component/copyright'
import { MainContext } from '../context/MainContext'
import { UserContext } from '../context/UserContext'
import DealerAddDealer from './dealer.components/DealerAddDealer';
import DealersList from './dealer.components/DealersList';
import DealerAssociatedSpaList from './dealer.components/DealerAssociatedSpaList';


export default class Users extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      dealersList: [],
      spasList: [],
      spinner: false
    };
  }

  reload = () => {
    console.log('Reloaded')
    this.getDealersList();
  }

  getDealersList = (name = '', email = '') => {
    const requestOptions = {
      method: 'GET', 
      credentials: "include"
    };
    
    const parameters = new URLSearchParams({
      name: name,
      email: email,
    });

    fetch(Constants.baseURL + Constants.apiDealersList + '?' + parameters, requestOptions) 
      .then(response => { 
          if (!response.ok && response.status !== 404) {
              this.props.history.push('/');
          }
          return response.json() })
      .then(data => {  
        this.setState({ 
          dealersList: data.dealers,
          spinner: false
        });
      }) 
      .catch(error => { console.log('request failed', error); }); 
  }

  getSpasList = (name = '', email = '', macAddress = '', userId = 0) => {
    if (userId === 0 || userId < 1) {
      userId = -1;
    }

    const requestOptions = {
      method: 'GET', 
      credentials: "include"
    };
    
    const parameters = new URLSearchParams({
      searchName: name,
      searchEmail: email,
      searchMacAddress: macAddress, 
      userId: userId === null || userId === "" ? 0 : userId
    });

    fetch(Constants.baseURL + Constants.apiDealerSpasList + '?' + parameters, requestOptions) 
      .then(response => { 
          if (!response.ok && response.status !== 404) {
              this.props.history.push('/');
          }
          return response.json() })
      .then(data => {  
        this.setState({ 
          spasList: data.spas,
          spinner: false
        });
      }) 
      .catch(error => { console.log('request failed', error); }); 
  }

  reload = () => {
    this.getDealersList();
    this.getSpasList();
  }

  componentDidMount() {
    this._isMounted = true;

    this.setState({
      dealersList: [],
      spinner: false
    })

    this.getDealersList();
    this.getSpasList();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.setState = (state,callback)=>{
      return;
    };
  }

  render() {
    return (<Container sx={{ mt: 4, mb: 4 }}>
    <Grid container spacing={3}>
      {/* Add Dealer */}  
      <Grid item xs={22} md={12} lg={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 220 }}>
          <DealerAddDealer reload={this.reload} value={this.state} />
        </Paper>
      </Grid>
      {/* Dealers list */}
      <Grid item xs={12} md={12} lg={12}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 500,
              }}
            >
            <UserContext.Provider value={this.props} >
              <MainContext.Provider value={this.state.dealersList} >
                <DealersList getDealersList={this.getDealersList} getSpasList={this.getSpasList} />
              </MainContext.Provider>
            </UserContext.Provider>
          </Paper>
        </Grid>
        {/* Dealer spa list */}
        <Grid item xs={12} md={12} lg={12}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 713,
            }}
          >
          <UserContext.Provider value={this.props} >
            <MainContext.Provider value={this.state.spasList} >
              <DealerAssociatedSpaList getSpasList={this.getSpasList} />
            </MainContext.Provider>
          </UserContext.Provider>
        </Paper>
      </Grid>
    </Grid>
    <Copyright sx={{ pt: 4 }} />
  </Container>);
  }
}