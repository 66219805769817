import React, { useContext } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Title from '../resources/Title';
import { MainContext } from '../../context/MainContext';

// Generate Order Data
function createData(id, property, value) {
  return { id, property, value };
}

var rows = [
  createData(0, 'Flow switch', 'N/A',),
  createData(1, 'High pressure switch', 'N/A',),
  createData(2, 'Low pressure switch', 'N/A',),
  createData(3, 'Comp thernal cutout', 'N/A',),
  createData(4, 'Heat exch thermal cutout', 'N/A',),
  createData(5, 'D1', 'N/A',),
  createData(6, 'D2', 'N/A',),
  createData(7, 'D3', 'N/A',)
];

var rowsV2 = [
  createData(0, 'Flow switch', 'N/A',),
  createData(1, 'High pressure switch', 'N/A',),
  createData(2, 'Low pressure switch', 'N/A',),
  createData(3, 'Spare 1', 'N/A',),
  createData(4, 'Spare 2', 'N/A',)
];

export default function HPDashboardSwitches() {
  const state = useContext(MainContext).state;
  var re = state.RE.split(',');

  if (re[1] === '1') {
    rows = [
      createData(0, 'Flow switch', 'N/A',),
      createData(1, 'High pressure switch', 'N/A',),
      createData(2, 'Low pressure switch', 'N/A',),
      createData(3, 'Comp thernal cutout', 'N/A',),
      createData(4, 'Heat exch thermal cutout', 'N/A',),
      createData(5, 'D1', 'N/A',),
      createData(6, 'D2', 'N/A',),
      createData(7, 'D3', 'N/A',)
    ];
    
    rows[0].value = '?';
    if (re[2] > 30) {
      rows[0].value = 'Open';
    } else {
      rows[0].value = 'Closed';
    }
  
    rows[1].value = '?';
    if (re[3] > 30) {
      rows[1].value = 'Open';
    } else {
      rows[1].value = 'Closed';
    }
  
    rows[2].value = '?';
    if (re[4] > 30) {
      rows[2].value = 'Open';
    } else {
      rows[2].value = 'Closed';
    }
  
    rows[3].value = '?';
    if (re[5] > 30) {
      rows[3].value = 'Open';
    } else {
      rows[3].value = 'Closed';
    }
  
    rows[4].value = '?';
    if (re[6] > 30) {
      rows[4].value = 'Open';
    } else {
      rows[4].value = 'Closed';
    }
  
    rows[5].value = '?';
    if (re[7] > 30) {
      rows[5].value = 'Open';
    } else {
      rows[5].value = 'Closed';
    }
  
    rows[6].value = '?';
    if (re[8] > 30) {
      rows[6].value = 'Open';
    } else {
      rows[6].value = 'Closed';
    }
  
    rows[7].value = '?';
    if (re[9] > 30) {
      rows[7].value = 'Open';
    } else {
      rows[7].value = 'Closed';
    }
  } else {
    rows = rowsV2;

    rows[0].value = '?';
    if (re[2] === '0') {
      rows[0].value = 'Open';
    } else {
      rows[0].value = 'Closed';
    }
  
    rows[1].value = '?';
    if (re[3] === '0') {
      rows[1].value = 'Open';
    } else {
      rows[1].value = 'Closed';
    }
  
    rows[2].value = '?';
    if (re[4] === '0') {
      rows[2].value = 'Open';
    } else {
      rows[2].value = 'Closed';
    }
  
    rows[3].value = '?';
    if (re[5] === '0') {
      rows[3].value = 'Open';
    } else {
      rows[3].value = 'Closed';
    }
  
    rows[4].value = '?';
    if (re[6] === '0') {
      rows[4].value = 'Open';
    } else {
      rows[4].value = 'Closed';
    }
  }
  
  return (
    <React.Fragment>
      <Title>Switches</Title>
      <Table size="small">
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.property}</TableCell>
              <TableCell>{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}