import React, { useContext } from 'react';
import Title from '../resources/Title';
import { MainContext } from '../../context/MainContext';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import * as Constants from '../api/constants'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function createData(id, property, log1, log2, log3) {
  return { id, property, log1, log2, log3 };
}

let deviceId = 0;

const rows = [
  createData(0, 'Hr Meter', 'No log', 'No log', 'No log',),
  createData(1, 'Time', '...', '...', '...',),
  createData(2, 'Error Code', '...', '...', '...'),
  createData(3, 'Mains V', '...', '...', '...'),
  createData(4, 'Heater temp', '...', '...', '...'),
  createData(5, 'Set temp', '...', '...', '...'),
  createData(6, 'spare', '...', '...', '...'),
  createData(7, 'spare', '...', '...', '...'),
  createData(8, 'spare', '...', '...', '...'),
  createData(9, 'spare', '...', '...', '...'),
  createData(10, 'spare', '...', '...', '...'),
];

export default function HPHistoryVoltageLogs() {
  const state = useContext(MainContext).state;
  const r9 = state.R9.split(',');
  const ra = state.RA.split(',');
  const rb = state.RB.split(',');

  deviceId = state.selectedDevice;

  // Column 1
  rows[0].log1 = r9[2]/10.0;
  rows[1].log1 = ConvertTime(r9[3]);
  rows[2].log1 = DecodeErrorHP(r9[4]);
  rows[3].log1 = (r9[7]/10.0).toFixed(1) + 'V';
  rows[4].log1 = (r9[8]/10.0).toFixed(1) + '°C';
  rows[5].log1 = (r9[12]/10.0).toFixed(1) + '°C';

  // Column 2
  rows[0].log2 = ra[2]/10.0;
  rows[1].log2 = ConvertTime(ra[3]);
  rows[2].log2 = DecodeErrorHP(ra[4]);
  rows[3].log2 = (ra[7]/10.0).toFixed(1) + 'V';
  rows[4].log2 = (ra[8]/10.0).toFixed(1) + '°C';
  rows[5].log2 = (ra[12]/10.0).toFixed(1) + '°C';
  
  // Column 3
  rows[0].log3 = rb[2]/10.0;
  rows[1].log3 = ConvertTime(rb[3]);
  rows[2].log3 = DecodeErrorHP(rb[4]);
  rows[3].log3 = (rb[7]/10.0).toFixed(1) + 'V';
  rows[4].log3 = (rb[8]/10.0).toFixed(1) + '°C';
  rows[5].log3 = (rb[12]/10.0).toFixed(1) + '°C';
  

  const sendCommand = (command, value) => {
    const requestOptions = { 
      method: 'POST',
      headers: { 'Accept': 'application/json', 
                 'Content-Type': 'application/json', }, 
      body: JSON.stringify({ DeviceId: deviceId,
                              Command: command, 
                              Value: value }),  
      credentials: "include"
    };
  
    fetch(Constants.baseURL + Constants.apiSendCommand, requestOptions) 
        .then((response) => { 
            if (response.ok) {
              toast.success('Command sent successfully. Refreshing...', {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
                
              setTimeout(function() {
                state.refreshDeviceRF(deviceId);
              }, 6000);

              setTimeout(function() {
                state.getUserDeviceRf(deviceId);
              }, 12000);
            } else {
              toast.error('Send failed. Please try again', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            }
        }).catch(error => { console.log('request failed', error); });
  }

  const onClickListener = () => {
    sendCommand('clearFaultLogs', '');
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <Title>Fault Logs</Title>
      <Table size="small" sx={{ mb: 3 }} >
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.property}</TableCell>
              <TableCell>{row.log1}</TableCell>
              <TableCell>{row.log2}</TableCell>
              <TableCell>{row.log3}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Button variant="outlined" onClick={() => onClickListener()}>Clear</Button>
    </React.Fragment>
  );
}

function ConvertTime(value) {
  var hours = value >> 8;
  var minutes = value & 0xFF;
  var hoursString = '';
  var minutesString = '';

  if (hours < 10) {
    hoursString = '0' + hours;
  } else {
    hoursString = hours;
  }

  if (minutes < 10) {
    minutesString = '0' + minutes;
  } else {
    minutesString = minutes; 
  }

  return hoursString + ':' + minutesString;
}

function DecodeErrorHP(error) {
  if (error === '0') return 'ER0 None';
  if (error === '2') return 'R2 Comms error';
  if (error === '3') return 'ER3 Water prime';
  if (error === '4') return 'ER4 Thermal trip';
  if (error === '5') return 'ER5 Pool too hot';
  if (error === '7') return 'ER7 Pool sensor';
  if (error === '8') return 'ER8 Mains volts';
  if (error === '9') return 'ER9 EEPROM error';
  if (error === '12') return 'ER12 Water flow';
  
  if (error === '17') return 'ER17 Water in thermistor';
  if (error === '18') return 'ER18 Water in thermistor';
  if (error === '10') return 'ER10 Ambient thermistor';
  if (error === '11') return 'ER11 Condenser thermistor';
  if (error === '19') return 'ER19 Compressor in thermistor';
  if (error === '15') return 'ER15 Compressor out thermistor';
  if (error === '20') return 'ER20 Heat exch in thermistor';
  if (error === '16') return 'ER16 Heat exch out thermistor';
  if (error === '23') return 'ER23 WaterEx out thermistor';
  if (error === '24') return 'ER24 Spare1 thermistor';

  if (error === '13') return 'ER13 Low pressure switch';
  if (error === '14') return 'ER14 High pressure switch';
  if (error === '21') return 'ER21 Spare 1 switch';
  if (error === '22') return 'R22 Spare 2 switch';
}