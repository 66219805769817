import React, { useContext } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Title from '../resources/Title';
import { MainContext } from '../../context/MainContext';
import { makeStyles } from '@mui/styles';
import TitleOutlinedIcon from '@mui/icons-material/TitleOutlined';
import CropFreeOutlinedIcon from '@mui/icons-material/CropFreeOutlined';
import TerminalOutlinedIcon from '@mui/icons-material/TerminalOutlined';
import DeviceThermostatOutlinedIcon from '@mui/icons-material/DeviceThermostatOutlined';
import LocalFireDepartmentOutlinedIcon from '@mui/icons-material/LocalFireDepartmentOutlined';

// Generate Order Data
function createData(id, icon, property, value) {
  return { id, icon, property, value };
}

const useStyles = makeStyles({
  combinedCell: {
    alignItems: 'center',
  },
  gap: {
    marginLeft: '20px', 
  },
  rightAlign: {
    textAlign: 'right',
  },
});

const rows = [
  createData(0, <TitleOutlinedIcon />, 'Type', 'N/A',),
  createData(1, <CropFreeOutlinedIcon />, 'Serial', 'N/A',),
  createData(2, <TerminalOutlinedIcon />, 'Software Ver.', 'N/A',),
  createData(3, <DeviceThermostatOutlinedIcon />, 'STMP', 'N/A',),
  createData(4, <LocalFireDepartmentOutlinedIcon />, 'Heater', 'N/A',),
];

export default function DashboardSpaDetails() {
  const state = useContext(MainContext).state;
  var r2 = state.R2.split(',');
  var r3 = state.R3.split(',');
  var r6 = state.R6.split(',');
  rows[0].value = r3[7]
  rows[1].value = r3[8] + ' ' + r3[9]
  rows[2].value = r3[6] 
  rows[3].value = (r6[8]/10.0).toFixed(1) + '°C'
  rows[4].value = (r2[12]/10.0).toFixed(1) + '°C'

  const classes = useStyles();

  return (
    <React.Fragment>
      <Title>Spa Details</Title>
      <Table size="small">
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell colSpan={2} className={classes.combinedCell}>
                {row.icon}
                <span className={classes.gap}></span>
                {row.property}
              </TableCell>
              <TableCell align="right" className={classes.rightAlign}>
                {row.value}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}