import React, { useContext } from 'react';
import Title from '../resources/Title';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DynamicSelect from '../resources/DynamicSelect';
import Box from '@mui/material/Box';
import { MainContext } from '../../context/MainContext';
import * as Constants from '../../components/api/constants';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Generate Order Data
function createData(id, property, state, possibleStates1, possibleStates2, possibleStates3) {
  return { id, property, state, possibleStates1, possibleStates2, possibleStates3 };
}

const selectDataArray = [
  {
    id: 0,
    value: ''    
  },
  {
    id: 1,
    value: ''    
  },
  {
    id: 2,
    value: ''    
  },
  {
    id: 3,
    value: ''    
  },
  {
    id: 4,
    value: ''    
  },
  {
    id: 5,
    value: ''    
  },
  {
    id: 6,
    value: ''    
  },
  {
    id: 7,
    value: ''    
  }
];

const typeData = [
  {
    id: '1',
    name: 'SV2'    
  },
  {
    id: '2',
    name: 'SV3'    
  },
  {
    id: '3',
    name: 'SV4'    
  },
  {
    id: '4',
    name: 'SM1'    
  },
  {
    id: '5',
    name: 'SM2'    
  },
];

const tmaxData = [];
var i = 28.0;
var idCounter = 1;
while (i <= 41.2) {
  tmaxData.push({
    id: idCounter,
    name: i.toFixed(1)    
  });
  i = i + 0.2;
  idCounter++;
}

const elementData = [
  {
    id: '1',
    name: '1'    
  },
  {
    id: '2',
    name: '2'    
  }
];

const brandData = [
  {
    id: '1',
    name: 'SPAN'    
  },
  {
    id: '2',
    name: 'SAPP'    
  },
  {
    id: '3',
    name: 'VORT'    
  },
  {
    id: '4',
    name: 'COAS'    
  },
  {
    id: '5',
    name: 'OASI'    
  },
  {
    id: '6',
    name: 'SPF'    
  },
  {
    id: '7',
    name: 'AUST'    
  },
  {
    id: '8',
    name: 'ALPN'    
  },
  {
    id: '9',
    name: 'VOLI'    
  },
  {
    id: '10',
    name: 'TFRM'    
  },
  {
    id: '11',
    name: 'ROTO'    
  },
  {
    id: '12',
    name: 'MAGN'    
  }
];

const vpmpData = [
  {
    id: '1',
    name: 'On'    
  },
  {
    id: '2',
    name: 'Off'    
  }
];

const hifiData = [
  {
    id: '1',
    name: 'On'    
  },
  {
    id: '2',
    name: 'Off'    
  }
];

let deviceId = 0;
const buttonWidth = '20%';

const handleSelectChange = (id, selectedValue) => {
  selectDataArray[id].value = selectedValue;
}

export default function EEPROMOptionsMenu() {
  const state = useContext(MainContext).state;
  const r3 = state.R3.split(',');
  const r6 = state.R6.split(',');
  const rc = state.RC.split(',');
  deviceId = state.selectedDevice;

  const sendCommand = (command, value) => {
    const requestOptions = { 
      method: 'POST',
      headers: { 'Accept': 'application/json', 
                 'Content-Type': 'application/json', }, 
      body: JSON.stringify({ DeviceId: deviceId,
                              Command: command, 
                              Value: value }),  
      credentials: "include"
    };
  
    fetch(Constants.baseURL + Constants.apiSendCommand, requestOptions) 
        .then((response) => { 
            if (response.ok) {
              toast.success('Command sent successfully. Refreshing...', {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
                
              setTimeout(function() {
                state.refreshDeviceRF(deviceId);
              }, 6000);

              setTimeout(function() {
                state.getUserDeviceRf(deviceId);
              }, 12000);
            } else {
              toast.error('Send failed. Please try again', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            }
        }).catch(error => { console.log('request failed', error); });
  }

  const onClick = (id) => {
    if (id === 0) {
      sendCommand('type', selectDataArray[id].value);
    } else if (id === 1) {
      sendCommand('TMax', selectDataArray[id].value);
    } else if (id === 2) {
      sendCommand('elements', selectDataArray[id].value);
    } else if (id === 3) {
      sendCommand('brand', selectDataArray[id].value);
    } else if (id === 4) {
      sendCommand('vpmp', selectDataArray[id].value);
    } else if (id === 5) {
      sendCommand('hifi', selectDataArray[id].value);
    } else if (id === 6) {
      sendCommand('hsig', selectDataArray[id].value);
    } else if (id === 7) {
      sendCommand('oxy', selectDataArray[id].value);
    }
  }

  const clearDataOnClick = () => {
    sendCommand('clearData', '');
  }

  const eepromResetOnClick = () => {
    sendCommand('eepromReset', '');
  }

  const rows = [
    createData(0, 'Type', 'N/A', <DynamicSelect arrayOfData={typeData} onSelectChange={handleSelectChange} id={0} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick(0)}}>Send</Button>),
    createData(1, 'T.MAX', 'N/A', <DynamicSelect arrayOfData={tmaxData} onSelectChange={handleSelectChange} id={1} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick(1)}}>Send</Button>),
    createData(2, 'Elements', 'N/A', <DynamicSelect arrayOfData={elementData} onSelectChange={handleSelectChange} id={2} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick(2)}}>Send</Button>),
    createData(3, 'Brand', 'N/A', <DynamicSelect arrayOfData={brandData} onSelectChange={handleSelectChange} id={3} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick(3)}}>Send</Button>),
    createData(4, 'VPMP', 'N/A', <DynamicSelect arrayOfData={vpmpData} onSelectChange={handleSelectChange} id={4} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick(4)}}>Send</Button>),
    createData(5, 'HIFI', 'N/A', <DynamicSelect arrayOfData={hifiData} onSelectChange={handleSelectChange} id={5} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick(5)}}>Send</Button>),
    createData(6, 'H.SIG', 'N/A', <DynamicSelect arrayOfData={hifiData} onSelectChange={handleSelectChange} id={6} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick(6)}}>Send</Button>),
    createData(7, 'OXY', 'N/A', <DynamicSelect arrayOfData={hifiData} onSelectChange={handleSelectChange} id={7} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick(7)}}>Send</Button>),
  ];

  if (r6[26] === '2') rows[0].state = 'SV2';
  if (r6[26] === '3') rows[0].state = 'SV3';
  if (r6[26] === '4') rows[0].state = 'SV4';
  if (r6[26] === '5') rows[0].state = 'SM1';
  if (r6[26] === '6') rows[0].state = 'SM2';

  rows[1].state = (r6[28]/10.0).toFixed(1);

  rows[2].state = r6[25];
  if (r6[25] === '0') rows[2].state = '1';

  if (r6[23] === '0') rows[3].state = "SPAN";
  if (r6[23] === '1') rows[3].state = "SAPP";
  if (r6[23] === '2') rows[3].state = "VORT";
  if (r6[23] === '3') rows[3].state = "COAS";
  if (r6[23] === '4') rows[3].state = "OASI";
  if (r6[23] === '5') rows[3].state = "SPF";
  if (r6[23] === '6') rows[3].state = "AUST";
  if (r6[23] === '7') rows[3].state = "ALPN";
  if (r6[23] === '8') rows[3].state = "VOLI";
  if (r6[23] === '9') rows[3].state = "TFRM";
  if (r6[23] === '10') rows[3].state = "ROTO";
  if (r6[23] === '11') rows[3].state = "MAGN";

  if (r6[21] === '0') rows[4].state = "Off";
  if (r6[21] === '1') rows[4].state = "On";

  if (r6[22] === '0') rows[5].state = "Off";
  if (r6[22] === '1') rows[5].state = "On";

  if (r3[28] === '0') rows[6].state = "Off";
  if (r3[28] === '1') rows[6].state = "On";

  if (rc[16] === '0') rows[7].state = "Off";
  if (rc[16] === '1') rows[7].state = "On";

  return (
    <React.Fragment>
      <Title>Options Menu</Title>
      <Table size="small" >
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.property}</TableCell>
              <TableCell>{row.state}</TableCell>
              <TableCell style={{textAlign: 'center'}}>{row.possibleStates1}</TableCell>
              <TableCell style={{textAlign: 'left'}}>{row.possibleStates2}</TableCell>
              <TableCell style={{textAlign: 'center'}}>{row.possibleStates3}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Title></Title>
      <Title></Title>
      <Title>Controls</Title>
      <Box textAlign='center'>
        <Button variant="outlined" sx={{ width: '90%' }} onClick={() => {clearDataOnClick()}}>Clear Data</Button>
        <Button variant="contained" sx={{ mt: 2, width: '90%' }} onClick={() => {eepromResetOnClick()}}>EEPROM RESET</Button>
      </Box>
      
    </React.Fragment>
  );
}