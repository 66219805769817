import React, { useContext } from 'react';
import Typography from '@mui/material/Typography';
import Title from '../resources/Title';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import FlashlightOnIcon from '@mui/icons-material/FlashlightOn';
import FlashlightOffIcon from '@mui/icons-material/FlashlightOff';
import { MainContext } from '../../context/MainContext';
import * as Constants from '../../components/api/constants'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let deviceId = 0;
let lightMode = 'Off';
let brightness = 0;
let speed = 0;
const buttonWidth = '30%';

export default function ControlLight() {
  const state = useContext(MainContext).state;
  deviceId = state.selectedDevice;
  const r5 = state.R5.split(',');
  const r6 = state.R6.split(',');

  if (r5[14] === '1') {
    if (r6[4] === '1') lightMode = 'Colour';
    if (r6[4] === '2') lightMode = 'Fade';
    if (r6[4] === '3') lightMode = 'Step';
    if (r6[4] === '4') lightMode = 'Party';
  }
  brightness = r6[2];
  speed = r6[5];

  const sendCommand = (command, value) => {
    const requestOptions = { 
      method: 'POST',
      headers: { 'Accept': 'application/json', 
                 'Content-Type': 'application/json', }, 
      body: JSON.stringify({ DeviceId: deviceId,
                              Command: command, 
                              Value: value }),  
      credentials: "include"
    };
  
    fetch(Constants.baseURL + Constants.apiSendCommand, requestOptions) 
        .then((response) => { 
            if (response.ok) {
              toast.success('Command sent successfully. Refreshing...', {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
                
              setTimeout(function() {
                state.refreshDeviceRF(deviceId);
              }, 6000);

              setTimeout(function() {
                state.getUserDeviceRf(deviceId);
              }, 12000);
            } else {
              toast.error('Send failed. Please try again', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            }
        }).catch(error => { console.log('request failed', error); });
  }

  const onClick = (id, value) => {
    if (id === 'lightOn') {
      sendCommand('lightOn', '');
    }
    if (id === 'lightOff') {
      sendCommand('lightOff', '');
    } 

    if (id === 'white') sendCommand('lightMode', id);
    if (id === 'colour') sendCommand('lightMode', id);
    if (id === 'step') sendCommand('lightMode', id);
    if (id === 'fade') sendCommand('lightMode', id);
    if (id === 'party') sendCommand('lightMode', id);

    if (id === 'lightBrightness') sendCommand('lightBrightness', value); 
    if (id === 'lightSpeed') sendCommand('lightSpeed', value); 

    if (id === 'red') sendCommand('lightColour', id);
    if (id === 'green') sendCommand('lightColour', id);
    if (id === 'blue') sendCommand('lightColour', id);
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <Title>Lights</Title>
      <Typography component="p" variant="h5" sx={{ textAlign: 'center', pt: 0 }}>
        Current state
      </Typography>
      <Typography color="text.secondary" sx={{ textAlign: 'center', mt: 1, pb: 1 }}>
        Mode: {lightMode} | Brightness: {brightness} | Speed: {speed}
      </Typography>
      <Container maxWidth="lg" sx={{ mt: 0, mb: 4 }} >
        <Grid container spacing={3} align='center' justify='center' alignItems='center'>
          <Grid item xs = {false} sm = {4} md = {6}> 
            <IconButton color={"primary"} aria-label="" component="label" onClick={() => onClick('lightOn')}>
              <FlashlightOnIcon sx={{ fontSize: "40px" }} />
            </IconButton>
          </Grid>
          <Grid item xs = {false} sm = {4} md = {6}>
            <IconButton color={"primary"} aria-label="" component="label" onClick={() => onClick('lightOff')}>
              <FlashlightOffIcon sx={{ fontSize: "40px" }} />
            </IconButton>
          </Grid>
        </Grid>
      </Container>
      <Typography color="text.secondary" sx={{ textAlign: 'left', pt: 0 }}>
        Modes:
      </Typography>
      <Container maxWidth="lg" sx={{ mt: 1, mb: 1 }} >
        <Grid container spacing={1.5} align='center' justifyContent='center' alignItems='center' direction={'row'}>
          <Grid item> 
            <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => onClick('white')}>White</Button>
          </Grid>
          <Grid item>
            <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => onClick('colour')}>Colour</Button>
          </Grid>
          <Grid item>
            <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => onClick('step')}>Step</Button>
          </Grid>
          <Grid item>
            <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => onClick('fade')}>Fade</Button>
          </Grid>
          <Grid item>
            <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => onClick('party')}>Party</Button>
          </Grid>
        </Grid>
      </Container>
      <Typography color="text.secondary" sx={{ textAlign: 'left', pt: 0 }}>
        Brightness:
      </Typography>
      <Container maxWidth="lg" sx={{ mt: 1, mb: 1 }} >
        <Grid container spacing={1.5} align='center' justifyContent='center' alignItems='center' direction={'row'}>
          <Grid item> 
            <Button variant="outlined" onClick={() => onClick('lightBrightness', '1')}>1</Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={() => onClick('lightBrightness', '2')}>2</Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={() => onClick('lightBrightness', '3')}>3</Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={() => onClick('lightBrightness', '4')}>4</Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={() => onClick('lightBrightness', '5')}>5</Button>
          </Grid>
        </Grid>
      </Container>
      <Typography color="text.secondary" sx={{ textAlign: 'left', pt: 0 }}>
        Speed:
      </Typography>
      <Container maxWidth="lg" sx={{ mt: 1, mb: 1 }} >
        <Grid container spacing={1.5} align='center' justifyContent='center' alignItems='center' direction={'row'}>
          <Grid item> 
            <Button variant="outlined" onClick={() => onClick('lightSpeed', '1')}>1</Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={() => onClick('lightSpeed', '2')}>2</Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={() => onClick('lightSpeed', '3')}>3</Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={() => onClick('lightSpeed', '4')}>4</Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={() => onClick('lightSpeed', '5')}>5</Button>
          </Grid>
        </Grid>
      </Container>
      <Typography color="text.secondary" sx={{ textAlign: 'left', pt: 0 }}>
        Colour:
      </Typography>
      <Container maxWidth="lg" sx={{ mt: 1, mb: 1 }} >
        <Grid container spacing={1.5} align='center' justifyContent='center' alignItems='center' direction={'row'}>
          <Grid item> 
            <Button sx={{ width: buttonWidth, color: '#F40B27', borderColor: '#F40B27' }} variant="outlined" onClick={() => onClick('red')}>Red</Button>
          </Grid>
          <Grid item>
            <Button sx={{ width: buttonWidth, color: '#00FF00', borderColor: '#00FF00' }} variant="outlined" onClick={() => onClick('green')}>Green</Button>
          </Grid>
          <Grid item>
            <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => onClick('blue')}>Blue</Button>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}