import React, { useContext } from 'react';
import Title from '../resources/Title';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import DynamicSelect from '../resources/DynamicSelect';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { MainContext } from '../../context/MainContext';
import * as Constants from '../../components/api/constants';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Generate Order Data
function createData(id, property, state, possibleStates1, possibleStates2, possibleStates3) {
  return { id, property, state, possibleStates1, possibleStates2, possibleStates3 };
}

const modeData = [
  {
    id: '1',
    name: 'NORM'    
  },
  {
    id: '2',
    name: 'ECON'    
  },
  {
    id: '3',
    name: 'AWAY'    
  },
  {
    id: '4',
    name: 'WEEK'    
  }
];

const filtData = [];
var i = 1;
while (i <= 24) {
  filtData.push({
    id: i,
    name: i.toString()   
  });
  i++;
}

const timeData = [
  {
  id: '1',
  name: '00:00'    
  },
  {
  id: '2',
  name: '00:30'    
  },
  {
  id: '3',
  name: '01:00'    
  },
  {
  id: '4',
  name: '01:30'    
  },
  {
  id: '5',
  name: '02:00'    
  },
  {
  id: '6',
  name: '02:30'    
  },
  {
  id: '7',
  name: '03:00'    
  },
  {
  id: '8',
  name: '03:30'    
  },
  {
  id: '9',
  name: '04:00'    
  },
  {
  id: '10',
  name: '04:30'    
  },
  {
  id: '11',
  name: '05:00'    
  },
  {
  id: '12',
  name: '05:30'    
  },
  {
  id: '13',
  name: '06:00'    
  },
  {
  id: '14',
  name: '06:30'    
  },
  {
  id: '15',
  name: '07:00'    
  },
  {
  id: '16',
  name: '07:30'    
  },
  {
  id: '17',
  name: '08:00'    
  },
  {
  id: '18',
  name: '08:30'    
  },
  {
  id: '19',
  name: '09:00'    
  },
  {
  id: '20',
  name: '09:30'    
  },
  {
  id: '21',
  name: '10:00'    
  },
  {
  id: '22',
  name: '10:30'    
  },
  {
  id: '23',
  name: '11:00'    
  },
  {
  id: '24',
  name: '11:30'    
  },
  {
  id: '25',
  name: '12:00'    
  },
  {
  id: '26',
  name: '12:30'    
  },
  {
  id: '27',
  name: '13:00'    
  },
  {
  id: '28',
  name: '13:30'    
  },
  {
  id: '29',
  name: '14:00'    
  },
  {
  id: '30',
  name: '14:30'    
  },
  {
  id: '31',
  name: '15:00'    
  },
  {
  id: '32',
  name: '15:30'    
  },
  {
  id: '33',
  name: '16:00'    
  },
  {
  id: '34',
  name: '16:30'    
  },
  {
  id: '35',
  name: '17:00'    
  },
  {
  id: '36',
  name: '17:30'    
  },
  {
  id: '37',
  name: '18:00'    
  },
  {
  id: '38',
  name: '18:30'    
  },
  {
  id: '39',
  name: '19:00'    
  },
  {
  id: '40',
  name: '19:30'    
  },
  {
  id: '41',
  name: '20:00'    
  },
  {
  id: '42',
  name: '20:30'    
  },
  {
  id: '43',
  name: '21:00'    
  },
  {
  id: '44',
  name: '21:30'    
  },
  {
  id: '45',
  name: '22:00'    
  },
  {
  id: '46',
  name: '22:30'    
  },
  {
  id: '47',
  name: '23:00'    
  },
  {
  id: '48',
  name: '23:30'    
  }
];

const ddisData = [
  {
    id: '1',
    name: 'WTMP'    
  },
  {
    id: '2',
    name: 'STMP'    
  },
  {
    id: '3',
    name: 'TIME'    
  }
];

const toutData = [];
var a = 10;
var aoutCounter = 1;
while (a <= 60) {
  toutData.push({
    id: aoutCounter,
    name: a.toString()   
  });
  a++;
  aoutCounter++;
}

const hpmpData = [
  {
    id: '1',
    name: 'AUTO'    
  },
  {
    id: '2',
    name: 'HEAT ONLY'    
  },
  {
    id: '3',
    name: 'COOL ONLY'    
  },
  {
    id: '4',
    name: 'OFF'    
  }
];

const onoffData = [
  {
    id: '1',
    name: 'ON'    
  },
  {
    id: '2',
    name: 'OFF'    
  }
]

const daysData = [
  {
    id: '1',
    name: 'DISABLED'    
  },
  {
    id: '2',
    name: 'WEEK'    
  },
  {
    id: '3',
    name: 'WEEKDAY'    
  },
  {
    id: '4',
    name: 'WEEKEND'    
  },
  {
    id: '5',
    name: 'MONDAY'    
  },
  {
    id: '6',
    name: 'TUESDAY'    
  },
  {
    id: '7',
    name: 'WEDNESDAY'    
  },
  {
    id: '8',
    name: 'THURSDAY'    
  },
  {
    id: '9',
    name: 'FRIDAY'    
  },
  {
    id: '10',
    name: 'SATURDAY'    
  },
  {
    id: '11',
    name: 'SUNDAY'    
  }
]

const powersaveData = [
  {
    id: '1',
    name: 'OFF'    
  },
  {
    id: '2',
    name: 'LOW'    
  },
  {
    id: '3',
    name: 'HIGH'    
  },
]

const selectDataArray = [
  {
    id: 0,
    value: ''    
  },
  {
    id: 1,
    value: ''    
  },
  {
    id: 2,
    value: ''    
  },
  {
    id: 3,
    value: ''    
  },
  {
    id: 4,
    value: ''    
  },
  {
    id: 5,
    value: ''    
  },
  {
    id: 6,
    value: ''    
  }
];

const selectSleep1Array = [
  {
    id: 0,
    value: ''    
  },
  {
    id: 1,
    value: ''    
  },
  {
    id: 2,
    value: ''    
  }
];

const selectSleep2Array = [
  {
    id: 0,
    value: ''    
  },
  {
    id: 1,
    value: ''    
  },
  {
    id: 2,
    value: ''    
  }
];

const SelectPowersaveArray = [
  {
    id: 0,
    value: ''    
  },
  {
    id: 1,
    value: ''    
  },
  {
    id: 2,
    value: ''    
  }
];

const lockModeData = [
  {
    id: '1',
    name: 'OFF'    
  },
  {
    id: '2',
    name: 'PARTIAL'    
  },
  {
    id: '3',
    name: 'FULL'    
  },
]

const SelectLockModeArray = [
  {
    id: 0,
    value: ''    
  }
];

const handleSelectChange = (id, selectedValue) => {
  selectDataArray[id].value = selectedValue;
}

const handleSleep1Change = (id, selectedValue) => {
  selectSleep1Array[id].value = selectedValue;
}

const handleSleep2Change = (id, selectedValue) => {
  selectSleep2Array[id].value = selectedValue;
}

const handlePowersaveChange = (id, selectedValue) => {
  SelectPowersaveArray[id].value = selectedValue;
}

const handleLockModeChange = (id, selectedValue) => {
  SelectLockModeArray[id].value = selectedValue;
}

let deviceId = 0;
const buttonWidth = '20%';

export default function EEPROMUserMenu() {
  const state = useContext(MainContext).state;
  const r4 = state.R4.split(',');
  const r6 = state.R6.split(',');
  const r7 = state.R7.split(',');
  const rg = state.RG.split(',');

  deviceId = state.selectedDevice;

  const sendCommand = (command, value) => {
    const requestOptions = { 
      method: 'POST',
      headers: { 'Accept': 'application/json', 
                 'Content-Type': 'application/json', }, 
      body: JSON.stringify({ DeviceId: deviceId,
                              Command: command, 
                              Value: value }),  
      credentials: "include"
    };
  
    fetch(Constants.baseURL + Constants.apiSendCommand, requestOptions) 
        .then((response) => { 
            if (response.ok) {
              toast.success('Command sent successfully. Refreshing...', {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
                
              setTimeout(function() {
                state.refreshDeviceRF(deviceId);
              }, 6000);

              setTimeout(function() {
                state.getUserDeviceRf(deviceId);
              }, 12000);
            } else {
              toast.error('Send failed. Please try again', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            }
        }).catch(error => { console.log('request failed', error); });
  }

  const onClick = (id) => {
    if (id === 0) {
      sendCommand('operationMode', selectDataArray[id].value);
    } else if (id === 1) {
      sendCommand('filtration', selectDataArray[id].value);
    } else if (id === 2) {
      sendCommand('wcln', selectDataArray[id].value);
    } else if (id === 3) {
      sendCommand('ddis', selectDataArray[id].value);
    } else if (id === 4) {
      sendCommand('tout', selectDataArray[id].value);
    } else if (id === 5) {
      sendCommand('hpmp', selectDataArray[id].value);
    } else if (id === 6) {
      sendCommand('hele', selectDataArray[id].value);
    }
  }

  const onClickSleep1 = (id) => {
    if (id === 0) {
      sendCommand('st1days', selectSleep1Array[id].value);
    } else if (id === 1) {
      sendCommand('st1bgn', selectSleep1Array[id].value);
    } else if (id === 2) {
      sendCommand('st1end', selectSleep1Array[id].value);
    } 
  }

  const onClickSleep2 = (id) => {
    if (id === 0) {
      sendCommand('st2days', selectSleep2Array[id].value);
    } else if (id === 1) {
      sendCommand('st2bgn', selectSleep2Array[id].value);
    } else if (id === 2) {
      sendCommand('st2end', selectSleep2Array[id].value);
    } 
  }

  const onClickPowersave = (id) => {
    if (id === 0) {
      sendCommand('powersaveLevel', SelectPowersaveArray[id].value);
    } else if (id === 1) {
      sendCommand('powersaveBgn', SelectPowersaveArray[id].value);
    } else if (id === 2) {
      sendCommand('powersaveEnd', SelectPowersaveArray[id].value);
    } 
  }

  const onClickLockMode = (id) => {
    if (id === 0) {
      console.log(SelectLockModeArray[id].value)
      sendCommand('lockMode', SelectLockModeArray[id].value);
    } 
  }

  const rows = [
    createData(0, 'MODE', 'N/A', <DynamicSelect arrayOfData={modeData} onSelectChange={handleSelectChange} id={0} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick(0)}}>Send</Button>),
    createData(1, 'FILT', 'N/A', <DynamicSelect arrayOfData={filtData} onSelectChange={handleSelectChange} id={1} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick(1)}}>Send</Button>),
    createData(2, 'WCLN', 'N/A', <DynamicSelect arrayOfData={timeData} onSelectChange={handleSelectChange} id={2} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick(2)}}>Send</Button>),
    createData(3, 'D.DIS', 'N/A', <DynamicSelect arrayOfData={ddisData} onSelectChange={handleSelectChange} id={3} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick(3)}}>Send</Button>),
    createData(4, 'T.OUT', 'N/A', <DynamicSelect arrayOfData={toutData} onSelectChange={handleSelectChange} id={4} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick(4)}}>Send</Button>),
    createData(5, 'H.PMP', 'N/A', <DynamicSelect arrayOfData={hpmpData} onSelectChange={handleSelectChange} id={5} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick(5)}}>Send</Button>),
    createData(6, 'H.ELE', 'N/A', <DynamicSelect arrayOfData={onoffData} onSelectChange={handleSelectChange} id={6} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick(6)}}>Send</Button>),
  ];
  
  const sleepTimerRow1 = [
    createData(0, 'DAYS', 'N/A', <DynamicSelect arrayOfData={daysData} onSelectChange={handleSleep1Change} id={0} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClickSleep1(0)}}>Send</Button>),
    createData(1, 'BGN', 'N/A', <DynamicSelect arrayOfData={timeData} onSelectChange={handleSleep1Change} id={1} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClickSleep1(1)}}>Send</Button>),
    createData(2, 'END', 'N/A', <DynamicSelect arrayOfData={timeData} onSelectChange={handleSleep1Change} id={2} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClickSleep1(2)}}>Send</Button>),
  ];
  
  const sleepTimerRow2 = [
    createData(0, 'DAYS', 'N/A', <DynamicSelect arrayOfData={daysData} onSelectChange={handleSleep2Change} id={0} name={'Circ Pump'} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClickSleep2(0)}}>Send</Button>),
    createData(1, 'BGN', 'N/A', <DynamicSelect arrayOfData={timeData} onSelectChange={handleSleep2Change} id={1} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClickSleep2(1)}}>Send</Button>),
    createData(2, 'END', 'N/A', <DynamicSelect arrayOfData={timeData} onSelectChange={handleSleep2Change} id={2} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClickSleep2(2)}}>Send</Button>),
  ];
  
  const powersaveRow = [
    createData(0, 'LEVEL', 'N/A', <DynamicSelect arrayOfData={powersaveData} onSelectChange={handlePowersaveChange} id={0} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClickPowersave(0)}}>Send</Button>),
    createData(1, 'BGN', 'N/A', <DynamicSelect arrayOfData={timeData} onSelectChange={handlePowersaveChange} id={1} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClickPowersave(1)}}>Send</Button>),
    createData(2, 'END', 'N/A', <DynamicSelect arrayOfData={timeData} onSelectChange={handlePowersaveChange} id={2} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClickPowersave(2)}}>Send</Button>),
  ];

  const lockModeRow = [
    createData(0, 'Lock Mode', 'N/A', <DynamicSelect arrayOfData={lockModeData} onSelectChange={handleLockModeChange} id={0} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClickLockMode(0)}}>Send</Button>),
  ];

  rows[0].state = r4[1];
  rows[1].state = r6[6];
  
  rows[2].state = ConvertTime(r7[1]);;

  if (r6[19] === '0') rows[3].state = 'WTMP';
  if (r6[19] === '1') rows[3].state = 'STMP';
  if (r6[19] === '2') rows[3].state = 'TIME';

  rows[4].state = r6[20];

  if (r7[26] === '0') rows[5].state = 'Auto';
  if (r7[26] === '1') rows[5].state = 'Heat';
  if (r7[26] === '2') rows[5].state = 'Cool';
  if (r7[26] === '3') rows[5].state = 'Off';

  if (r7[25] === '0') rows[6].state = 'Off';
  if (r7[25] === '1') rows[6].state = 'On';

  if (r6[13] === '1') sleepTimerRow1[0].state = 'Friday';
  if (r6[13] === '2') sleepTimerRow1[0].state = 'Thursday';
  if (r6[13] === '4') sleepTimerRow1[0].state = 'Wednesday';
  if (r6[13] === '8') sleepTimerRow1[0].state = 'Tuesday';
  if (r6[13] === '16') sleepTimerRow1[0].state = 'Monday';
  if (r6[13] === '32') sleepTimerRow1[0].state = 'Sunday';
  if (r6[13] === '64') sleepTimerRow1[0].state = 'Saturday';
  if (r6[13] === '127') sleepTimerRow1[0].state = 'All week';
  if (r6[13] === '128') sleepTimerRow1[0].state = 'Disabled';
  if (r6[13] === '0') sleepTimerRow1[0].state = 'Disabled';
  if (r6[13] === '96') sleepTimerRow1[0].state = 'Weekend';
  if (r6[13] === '31') sleepTimerRow1[0].state = 'Weekdays';

  sleepTimerRow1[1].state = ConvertTime(r6[15]);
  sleepTimerRow1[2].state = ConvertTime(r6[17]);

  if (r6[14] === '1') sleepTimerRow2[0].state = 'Friday';
  if (r6[14] === '2') sleepTimerRow2[0].state = 'Thursday';
  if (r6[14] === '4') sleepTimerRow2[0].state = 'Wednesday';
  if (r6[14] === '8') sleepTimerRow2[0].state = 'Tuesday';
  if (r6[14] === '16') sleepTimerRow2[0].state = 'Monday';
  if (r6[14] === '32') sleepTimerRow2[0].state = 'Sunday';
  if (r6[14] === '64') sleepTimerRow2[0].state = 'Saturday';
  if (r6[14] === '127') sleepTimerRow2[0].state = 'All week';
  if (r6[14] === '128') sleepTimerRow2[0].state = 'Disabled';
  if (r6[14] === '0') sleepTimerRow2[0].state = 'Disabled';
  if (r6[14] === '96') sleepTimerRow2[0].state = 'Weekend';
  if (r6[14] === '31') sleepTimerRow2[0].state = 'Weekdays';
  
  sleepTimerRow2[1].state = ConvertTime(r6[16]);
  sleepTimerRow2[2].state = ConvertTime(r6[18]);

  if (r6[10] === '0') powersaveRow[0].state = 'Off';
  if (r6[10] === '1') powersaveRow[0].state = 'Low';
  if (r6[10] === '2') powersaveRow[0].state = 'High';

  powersaveRow[1].state = ConvertTime(r6[11]);
  powersaveRow[2].state = ConvertTime(r6[12]);

  if (rg[12] === '0' ) lockModeRow[0].state = 'OFF';
  if (rg[12] === '1' ) lockModeRow[0].state = 'PARTIAL';
  if (rg[12] === '2' ) lockModeRow[0].state = 'FULL';
  
  return (
    <React.Fragment>
    <Title>User Menu</Title>
    <Container maxWidth="lg" sx={{ flexDirection: 'row', paddingHorizontal: 0, mt: 2 }}>
      <Grid container spacing={3}> 
        <Grid item sx={{ flex: 1, flexDirection: 'column' }}>
          <Table size="small" xs={12} md={4} lg={4}>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.property}</TableCell>
                  <TableCell>{row.state}</TableCell>
                  <TableCell style={{textAlign: 'center'}}>{row.possibleStates1}</TableCell>
                  <TableCell style={{textAlign: 'left'}}>{row.possibleStates2}</TableCell>
                  <TableCell style={{textAlign: 'center'}}>{row.possibleStates3}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
        
        <Grid item sx={{ flex: 1, flexDirection: 'column' }}>
          <Typography variant="body2" color="text.secondary" align="left">
              <b>Sleep Timer 1</b>
          </Typography>
          <Table size="small">
            <TableBody>
              {sleepTimerRow1.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.property}</TableCell>
                  <TableCell>{row.state}</TableCell>
                  <TableCell style={{textAlign: 'center'}}>{row.possibleStates1}</TableCell>
                  <TableCell style={{textAlign: 'left'}}>{row.possibleStates2}</TableCell>
                  <TableCell style={{textAlign: 'center'}}>{row.possibleStates3}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Typography variant="body2" color="text.secondary" align="left" sx={{ mt: 3.6 }}>
            <b>Sleep Timer 2</b>
          </Typography>
          <Table size="small">
            <TableBody>
              {sleepTimerRow2.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.property}</TableCell>
                  <TableCell>{row.state}</TableCell>
                  <TableCell style={{textAlign: 'center'}}>{row.possibleStates1}</TableCell>
                  <TableCell style={{textAlign: 'left'}}>{row.possibleStates2}</TableCell>
                  <TableCell style={{textAlign: 'center'}}>{row.possibleStates3}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>

        {/* Combine Power Save Timer and Keylock in the same grid */}
        <Grid item sx={{ flex: 1, flexDirection: 'column' }}>
        <Typography variant="body2" color="text.secondary" align="left" sx={{ mt: 4 }}>
              <b>Power save timer</b>
            </Typography>
            <Table size="small">
              <TableBody>
                {powersaveRow.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.property}</TableCell>
                    <TableCell>{row.state}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{row.possibleStates1}</TableCell>
                    <TableCell style={{ textAlign: 'left' }}>{row.possibleStates2}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{row.possibleStates3}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
        </Grid>

        <Grid item sx={{ flex: 1, flexDirection: 'column' }}>
          <Typography variant="body2" color="text.secondary" align="left" sx={{ mt: 4 }}>
              <b>Keylock</b>
            </Typography>
            <Table size="small">
              <TableBody>
                {lockModeRow.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.property}</TableCell>
                    <TableCell>{row.state}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{row.possibleStates1}</TableCell>
                    <TableCell style={{ textAlign: 'left' }}>{row.possibleStates2}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{row.possibleStates3}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
        </Grid>
      </Grid>
    </Container>
  </React.Fragment>
  );
}

function ConvertTime(value) {
  var hours = value >> 8;
  var minutes = value & 0xFF;
  var hoursString = '';
  var minutesString = '';

  if (hours < 10) {
    hoursString = '0' + hours;
  } else {
    hoursString = hours;
  }

  if (minutes < 10) {
    minutesString = '0' + minutes;
  } else {
    minutesString = minutes; 
  }

  return hoursString + ':' + minutesString;
}