import React, { useContext } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../resources/Title';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { MainContext } from '../../context/MainContext';
import { makeStyles } from '@mui/styles';
import LocalFireDepartmentOutlinedIcon from '@mui/icons-material/LocalFireDepartmentOutlined';
import AvTimerOutlinedIcon from '@mui/icons-material/AvTimerOutlined';
import TimerOffOutlinedIcon from '@mui/icons-material/TimerOffOutlined';
import PoolOutlinedIcon from '@mui/icons-material/PoolOutlined';
import HeatPumpOutlinedIcon from '@mui/icons-material/HeatPumpOutlined';
import WavesOutlinedIcon from '@mui/icons-material/WavesOutlined';
import AcUnitOutlinedIcon from '@mui/icons-material/AcUnitOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import SettingsInputHdmiOutlinedIcon from '@mui/icons-material/SettingsInputHdmiOutlined';
import StraightenOutlinedIcon from '@mui/icons-material/StraightenOutlined';
import StayCurrentLandscapeOutlinedIcon from '@mui/icons-material/StayCurrentLandscapeOutlined';
import SettingsBrightnessOutlinedIcon from '@mui/icons-material/SettingsBrightnessOutlined';
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined';

// Generate Order Data
function createData(id, icon, property, value) {
  return { id, icon, property, value };
}

const useStyles = makeStyles({
  combinedCell: {
    alignItems: 'center',
  },
  gap: {
    marginLeft: '20px', 
  },
  rightAlign: {
    textAlign: 'right',
  },
});

function toHoursAndMinutes(totalMinutes) {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return `${padToTwoDigits(hours)}:${padToTwoDigits(minutes)}`;
}

function padToTwoDigits(num) {
  return num.toString().padStart(2, '0');
}

const ControlRows = [
  createData(0, <LocalFireDepartmentOutlinedIcon />, 'Heat mode', 'N/A'),
  createData(1, <AvTimerOutlinedIcon />, 'Pump idle timer', 'N/A'),
  createData(2, <AvTimerOutlinedIcon />, 'Pump run timer', 'N/A'),
  createData(3, <TimerOffOutlinedIcon />, 'Heat source off time', 'N/A'),
  createData(4, <PoolOutlinedIcon />, 'Adaptive pool hys', 'N/A'),
  createData(5, <HeatPumpOutlinedIcon />, 'Adaptive heater hys', 'N/A'),
  createData(6, <WavesOutlinedIcon />, 'Element offset', 'N/A'),
  createData(7, <LocalFireDepartmentOutlinedIcon />, 'HP heat offset', 'N/A'),
  createData(8, <AcUnitOutlinedIcon />, 'HP cool offset', 'N/A'),
  createData(9, <LocalFireDepartmentOutlinedIcon />, 'Thermal cutout', 'N/A'),
  createData(10, <AccessTimeOutlinedIcon />, 'Ozone run time', 'N/A'),
  createData(11, <SettingsInputHdmiOutlinedIcon />, 'H.SIG input', 'N/A'),
];

const ElementsRows = [
  createData(0, <StraightenOutlinedIcon />, 'Element 1 level (sm)', 'N/A'),
  createData(1, <StraightenOutlinedIcon />, 'Element 2 level (lg)', 'N/A'),
  createData(2, <StayCurrentLandscapeOutlinedIcon />, 'Element current', 'N/A'),
];

const FiltrationRows = [
  createData(0, <AccessTimeOutlinedIcon />, 'Awake mins left', 'N/A'),
  createData(1, <AccessTimeOutlinedIcon />, 'Pump total run time', 'N/A'),
  createData(2, <AccessTimeOutlinedIcon />, 'Pump time per block', 'N/A'),
];

const VariPumpRows = [
  createData(0, <SettingsBrightnessOutlinedIcon />, 'Mode', 'N/A'),
  createData(1, <SpeedOutlinedIcon />, 'Speed level', 'N/A'),
  createData(2, <PercentOutlinedIcon />, 'Speed %', 'N/A'),
];

export default function DashboardControl() {
  const state = useContext(MainContext).state;
  var r2 = state.R2.split(',');
  var r3 = state.R3.split(',');
  var r4 = state.R4.split(',');

  if (r4[5].includes('0')) ControlRows[0].value = 'Off';
  if (r4[5].includes('1')) ControlRows[0].value = 'Standby';
  if (r4[5].includes('2')) ControlRows[0].value = 'Heat mix';
  if (r4[5].includes('3')) ControlRows[0].value = 'Heat test';
  if (r4[5].includes('4')) ControlRows[0].value = 'Heat';
  if (r4[5].includes('5')) ControlRows[0].value = 'Cool mix';
  if (r4[5].includes('6')) ControlRows[0].value = 'Cool test';
  if (r4[5].includes('7')) ControlRows[0].value = 'Cool';
  
  ControlRows[1].value = new Date(r4[6] * 1000).toISOString().slice(11, 19);
  ControlRows[2].value = new Date(r4[7] * 1000).toISOString().slice(11, 19);
  ControlRows[3].value = r4[22]
  ControlRows[4].value = (r4[8]/10.0).toFixed(1);
  ControlRows[5].value = (r4[9]/10.0).toFixed(1);
  ControlRows[6].value = (r4[18]/10.0).toFixed(1);
  ControlRows[7].value = (r4[19]/10.0).toFixed(1);
  ControlRows[8].value = (r4[21]/10.0).toFixed(1);
  ControlRows[9].value = r4[14] === '1' ? 'Tripped' : 'Ok';
  ControlRows[10].value = toHoursAndMinutes(r2[30]) + ':00';
  if (r3.length >= 29) {
    ControlRows[11].value = r3[28] === '1' ? 'ON' : 'OFF';
  }

  ElementsRows[0].value = r3[18]
  if (r3[18] === '0') {
    ElementsRows[0].value = 'Off'
  } 
  if (r3[18] === '200') {
    ElementsRows[0].value = 'On'
  } 

  ElementsRows[1].value = r3[25]
  if (r3[25] === '0') {
    ElementsRows[1].value = 'Off'
  } 
  if (r3[25] === '200') {
    ElementsRows[1].value = 'On'
  } 

  ElementsRows[2].value = (r3[22]/10.0).toFixed(1);

  FiltrationRows[0].value = toHoursAndMinutes(r2[16]) + ':00';
  FiltrationRows[1].value = toHoursAndMinutes(r2[17]) + ':00';
  FiltrationRows[2].value = toHoursAndMinutes(r2[18]) + ':00';

  if (r4[23] === '0') VariPumpRows[0].value = 'Vari';
  if (r4[23] === '1') VariPumpRows[0].value = '1.SEQ';
  if (r4[23] === '2') VariPumpRows[0].value = '2.SEQ';
  if (r4[23] === '3') VariPumpRows[0].value = '3.SEQ';
  if (r4[23] === '4') VariPumpRows[0].value = 'Off';
  if (r4[23] === '5') VariPumpRows[0].value = 'Filt';
  if (r4[23] === '6') VariPumpRows[0].value = 'Heat';
  if (r4[23] === '7') VariPumpRows[0].value = 'Cool';
  VariPumpRows[1].value = r4[24];
  VariPumpRows[2].value = r4[25];

  const classes = useStyles();

  return (
    <React.Fragment>
      <Title>Control</Title>
      <Container disableGutters maxWidth="lg" sx={{ flexDirection: 'row', paddingHorizontal: 0 }}>
        <Grid container spacing={3}> 
          <Grid item sx={{ flex: 1, flexDirection: 'column' }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell><b>General</b></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ControlRows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell colSpan={2} className={classes.combinedCell}>
                      {row.icon}
                      <span className={classes.gap}></span>
                      {row.property}
                    </TableCell>
                    <TableCell align="right" className={classes.rightAlign}>
                      {row.value}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <Grid item sx={{ flex: 1, flexDirection: 'column' }}>
          <Grid item>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell><b>Elements</b></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ElementsRows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell colSpan={2} className={classes.combinedCell}>
                      {row.icon}
                      <span className={classes.gap}></span>
                      {row.property}
                    </TableCell>
                    <TableCell align="right" className={classes.rightAlign}>
                      {row.value}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <Grid item>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell><b>Filtration</b></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {FiltrationRows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell colSpan={2} className={classes.combinedCell}>
                      {row.icon}
                      <span className={classes.gap}></span>
                      {row.property}
                    </TableCell>
                    <TableCell align="right" className={classes.rightAlign}>
                      {row.value}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <Grid item>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell><b>Vari Pump</b></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {VariPumpRows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell colSpan={2} className={classes.combinedCell}>
                      {row.icon}
                      <span className={classes.gap}></span>
                      {row.property}
                    </TableCell>
                    <TableCell align="right" className={classes.rightAlign}>
                      {row.value}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}