import React, { useContext } from 'react';
import Title from '../resources/Title';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DynamicSelect from '../resources/DynamicSelect';
import { MainContext } from '../../context/MainContext';
import * as Constants from '../api/constants'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Generate Order Data
function createData(id, property, state, possibleStates1, possibleStates2, possibleStates3) {
  return { id, property, state, possibleStates1, possibleStates2, possibleStates3 };
}

let deviceId = 0;

const buttonWidth = '20%';

const selectDataArray = [
  {
    id: 0,
    value: ''    
  },
  {
    id: 1,
    value: ''    
  },
  {
    id: 2,
    value: ''    
  },
  {
    id: 3,
    value: ''    
  }
];

const typeData = [
  {
    id: '1',
    name: 'SV2'    
  },
  {
    id: '2',
    name: 'SV3'    
  },
  {
    id: '3',
    name: 'SV4'    
  },
  {
    id: '4',
    name: 'SM1'    
  },
  {
    id: '5',
    name: 'SM2'    
  },
];

const hourData = [
  {
    id: '1',
    name: '12'    
  },
  {
    id: '2',
    name: '24'    
  }
];

const temperatureArrayData = [];
var i = 10.0;
var idCounter = 1;
while (i <= 41.2) {
  temperatureArrayData.push({
    id: idCounter,
    name: i.toFixed(1)    
  });
  i = i + 0.2;
  idCounter++;
}

const brandData = [
  {
    id: '1',
    name: 'SPAN'    
  },
  {
    id: '2',
    name: 'SAPP'    
  },
  {
    id: '3',
    name: 'VORT'    
  },
  {
    id: '4',
    name: 'COAS'    
  },
  {
    id: '5',
    name: 'OASI'    
  },
  {
    id: '6',
    name: 'SPF'    
  },
  {
    id: '7',
    name: 'ASPA'    
  },
  {
    id: '8',
    name: 'ALPN'    
  },
  {
    id: '9',
    name: 'VOLI'    
  }
];

const handleSelectChange = (id, selectedValue) => {
  selectDataArray[id].value = selectedValue;
}

export default function HPEEPROMGeneral() {
  const state = useContext(MainContext).state;
  deviceId = state.selectedDevice;
  
  const sendCommand = (command, value) => {
    const requestOptions = { 
      method: 'POST',
      headers: { 'Accept': 'application/json', 
                 'Content-Type': 'application/json', }, 
      body: JSON.stringify({ DeviceId: deviceId,
                              Command: command, 
                              Value: value.toString() }),  
      credentials: "include"
    };
  
    fetch(Constants.baseURL + Constants.apiSendCommand, requestOptions) 
        .then((response) => { 
            if (response.ok) {
              toast.success('Command sent successfully. Refreshing...', {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
                
              setTimeout(function() {
                state.refreshDeviceRF(deviceId);
              }, 6000);

              setTimeout(function() {
                state.getUserDeviceRf(deviceId);
              }, 12000);
            } else {
              toast.error('Send failed. Please try again', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            }
        }).catch(error => { console.log('request failed', error); });
  }

  const onClick = (id) => {
    if (id === 0) {
      sendCommand('HPEEPROMHour', selectDataArray[id].value);
    } else if (id === 1) {
      sendCommand('HPSetTemperature', selectDataArray[id].value);
    } else if (id === 2) {
      sendCommand('type', selectDataArray[id].value);
    } else if (id === 3) {
      sendCommand('brand', selectDataArray[id].value);
    } 
  }

  const rows = [
    createData(0, '12/24 Hr', 'N/A', <DynamicSelect arrayOfData={hourData} onSelectChange={handleSelectChange} id={0} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick(0)}}>Send</Button>),
    createData(1, 'Set temperature', 'N/A', <DynamicSelect arrayOfData={temperatureArrayData} onSelectChange={handleSelectChange} id={1} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick(1)}}>Send</Button>),
    createData(2, 'Type', 'N/A', <DynamicSelect arrayOfData={typeData} onSelectChange={handleSelectChange} id={2} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick(2)}}>Send</Button>),
    createData(3, 'Brand', 'N/A', <DynamicSelect arrayOfData={brandData} onSelectChange={handleSelectChange} id={3} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick(3)}}>Send</Button>),
  ];

  const r6 = state.R6.split(',');
  rows[0].state = '?';
  if (r6[9] === '0') rows[0].state = '24';
  if (r6[9] === '1') rows[0].state = '12';

  rows[1].state = (r6[8]/10.0).toFixed(1) + '°C';

  if (r6[26] === '2') rows[2].state = 'SV2';
  if (r6[26] === '3') rows[2].state = 'SV3';
  if (r6[26] === '4') rows[2].state = 'SV4';
  if (r6[26] === '5') rows[2].state = 'SM1';
  if (r6[26] === '6') rows[2].state = 'SM2';

  if (r6[23] === '0') rows[3].state = 'SPAN';
  if (r6[23] === '1') rows[3].state = 'SAPP';
  if (r6[23] === '2') rows[3].state = 'VORT';
  if (r6[23] === '3') rows[3].state = 'COAS';
  if (r6[23] === '4') rows[3].state = 'OASI';
  if (r6[23] === '5') rows[3].state = 'SPF';
  if (r6[23] === '6') rows[3].state = 'AUST';
  if (r6[23] === '7') rows[3].state = 'ALPN';
  if (r6[23] === '8') rows[3].state = 'VOLI';
  if (r6[23] === '9') rows[3].state = 'TFRM';
  if (r6[23] === '10') rows[3].state = 'ROTO';

  return (
    <React.Fragment>
      <ToastContainer />
      <Title>General</Title>
      <Table size="small" >
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.property}</TableCell>
              <TableCell>{row.state}</TableCell>
              <TableCell style={{textAlign: 'center'}}>{row.possibleStates1}</TableCell>
              <TableCell style={{textAlign: 'left'}}>{row.possibleStates2}</TableCell>
              <TableCell style={{textAlign: 'center'}}>{row.possibleStates3}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}