import React, { useContext } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Copyright from './copyright.component/copyright'
import HPDashboardTouchpad from './hpDashboard.component/HPDashboardTouchpad';
import HPDashboardControlTemp from './hpDashboard.component/HPDashboardControlTemp';
import HPDashboardRelays from './hpDashboard.component/HPDashboardRelays';
import HPDashboardEEV from './hpDashboard.component/HPDashboardEEV';
import HPDashboardEVI from './hpDashboard.component/HPDashboardEVI';
import HPDashboardDips from './hpDashboard.component/HPDashboardDips';
import HPDashboardSwitches from './hpDashboard.component/HPDashboardSwitches';
import HPDashboardThermistorsAnalog from './hpDashboard.component/HPDashboardThermistorsAnalog';
import HPDashboardDetails from './hpDashboard.component/HPDashboardDetails'; 
import { MainRfContext } from '../context/MainRfContext';
import { MainContext } from '../context/MainContext';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

export default function HPDashboard() {
  const state = useContext(MainRfContext);
  let re = state.RE.split(',');

  if ((state.selectedDevice === 0)) {
    return (<Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
    <Stack sx={{ width: '100%' }} spacing={2}>
      <Alert variant="filled" severity="error">
        No spa associated with this account
      </Alert>
    </Stack>
  </Container>)
  } else {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <MainContext.Provider value={{ state }}>
          <Grid container spacing={3}>
            {/* Offline */}
            {state.online === false ? <Stack sx={{ width: '100%', pt: 2, pl: 3 }} spacing={2}>
              <Alert variant="filled" severity="error">
                Spa is Offline
              </Alert>
            </Stack> : <div></div>}
            {/* Touchpad */}
            <Grid item xs={12} md={4} lg={4}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: 330,
                }}
              >
                <HPDashboardTouchpad />
              </Paper>
            </Grid>
            {/* Spa Details */}
            {/* <Grid item xs={12} md={4} lg={4}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: 300,
                }}
              >
                <HPDashboardDetails />
              </Paper>
            </Grid> */}
            {/* Switches */}
            <Grid item xs={12} md={4} lg={4}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: 330,
                }}
              >
                <HPDashboardSwitches />
              </Paper>
            </Grid>
            {/* Relays */}
            <Grid item xs={12} md={4} lg={4}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: 330,
                }}
              >
                <HPDashboardRelays />
              </Paper>
            </Grid>
            {/* Thermistors + Analog */}
            <Grid item xs={23} md={12} lg={12}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                }}
              >
                <HPDashboardThermistorsAnalog />
              </Paper>
            </Grid>
            {/* Control */}
            <Grid item xs={12}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <HPDashboardControlTemp />
              </Paper>
            </Grid>
            {/* EEV */}
            {re[1] === '2' ? <Grid item xs={12} md={4} lg={4}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: 300,
                }}
              >
                <HPDashboardEEV />
              </Paper>
            </Grid> : ''}
            {/* EVI */}
            {re[1] === '2' ? <Grid item xs={12} md={4} lg={4}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: 300,
                }}
              >
                <HPDashboardEVI />
              </Paper>
            </Grid> : ''}
            {/* Dips */}
            {re[1] === '2' ? <Grid item xs={12} md={4} lg={4}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: 300,
                }}
              >
                <HPDashboardDips />
              </Paper>
            </Grid> : ''}
          </Grid>
        </MainContext.Provider>
        <Copyright sx={{ pt: 4 }} />
      </Container>
    );
  }
}