import React, { useContext } from 'react';
import Typography from '@mui/material/Typography';
import Title from '../resources/Title';
import { MainContext } from '../../context/MainContext';
import { format } from "date-fns";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

export default function DashboardTouchpad() {
  const state = useContext(MainContext).state;
  const r2 = state.R2.split(',');
  const r5 = state.R5.split(',');
  const rg = state.RG.split(',');
  
  var version = '';
  if (state.deviceType === 1) {
    version = 'V1/2';
  } else if (state.deviceType === 2) {
    version = 'V3';
  }
  var year = r2[11];
  var month = (r2[10].length === 2 ? r2[10] : '0' + r2[10]) === '00' ? '01' : (r2[10].length === 2 ? r2[10] : '0' + r2[10]);
  var day = (r2[9].length === 2 ? r2[9] : '0' + r2[9]) === '00' ? '01' : (r2[9].length === 2 ? r2[9] : '0' + r2[9]);
  var hour = r2[6].length === 2 ? r2[6] : '0' + r2[6];
  var minutes = r2[7].length === 2 ? r2[7] : '0' + r2[7];
  const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const datetime = new Date(year + '-' + month + '-' + day + ' ' + hour + ':' + minutes + ':00');
  var dayString = weekday[datetime.getDay()];
  var time = format(datetime, "hh:mm a");
  var date = dayString + ' ' + format(datetime, "dd MMMM yyyy");
  var statusList = [];
  if (r5[13] === '1') {
    statusList.push('Auto');
  }
  if (r5[12] === '1') {
    statusList.push('Heating');
  } 
  if (r5[11] === '1') {
    statusList.push('Sanitising');
  } 
  if (r5[10] === '1') {
    statusList.push('Sleeping');
  } 
  if (rg[12].includes('2')) {
    statusList.push('Sleeping');
  } 
  var statusListString = statusList.join(' | ');

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item sx={{ flex: 1, flexDirection: 'column' }}>
          <Title>{state.deviceName}</Title>
        </Grid>
        <Grid item sx={{ flex: 1, flexDirection: 'column' }} container justifyContent="flex-end">
          <Box display="flex" justifyContent="flex-end">
            <Title>{state.rssi}dB</Title>
          </Box>
        </Grid>
      </Grid>

      <Typography component="p" variant="h3" sx={{ flex: 1, textAlign: 'center', mt: 3, border: '1px solid #000', borderRadius: '5px', p: 2 }}>
        {state.R5.split(',')[15] / 10.0}°C
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1, textAlign: 'center', pt: 1 }}>
        <strong>{version}</strong>
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1, textAlign: 'center' }}>
        {time}
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 5, textAlign: 'center' }}>
        {date}
      </Typography>
      <Typography color="text.primary" sx={{ flex: 1, pt: 1 }}>
        Status
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
        {statusListString}
      </Typography>
    </React.Fragment>
  );
}