import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Copyright from './copyright.component/copyright'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo from './resources/login_logo.png';
import backgroundImage from './resources/bg-remote-login.png'
import * as Constants from './api/constants' 
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoggedInUserContext } from '../context/LoggedInUserContext';
import { reactLocalStorage } from 'reactjs-localstorage';

const theme = createTheme();

export default function SignInSide() {
  const history = useHistory();
  const state = useContext(LoggedInUserContext);
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const requestOptions = { 
      method: 'POST',
      headers: { 'Accept': 'application/json', 
                 'Content-Type': 'application/json', }, 
      body: JSON.stringify({ UserId: data.get('userId'),
                             Pin: data.get('PIN'), UserDeviceId: 'web' }),  
      credentials: "include"
    };
    fetch(Constants.baseURL + Constants.apiLoginPin, requestOptions) 
        .then((response) => { 
            if (response.ok) {
              reactLocalStorage.set('isLoggedIn', true);
              history.push('/Dashboard');
            } else if (response.status === 401) {
              toast.error('Incorrect user ID or PIN. Please try again', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else if (response.status === 204) {
              toast.error('user not active. Please try again', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else if (response.status === 403) {
              return response.json()
            }
        })
        .then(data => {
          if (data !== undefined) {
            toast.error('The account is locked due to 5 failed logins. Please try again in ' + data + ' minutes', {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch(error => { console.log('request failed', error); });
  };

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer />
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(' + backgroundImage + ')', // https://source.unsplash.com/random
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 15,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box m={2} pb={3}>
              <img className="login-logo" src={logo} alt="SpaNET Logo" />
            </Box>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="userId"
                label="User ID"
                name="userId"
                autoComplete="userId"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="PIN"
                label="PIN"
                type="pin"
                id="pin"
                autoComplete="current-password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}