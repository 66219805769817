import React, { useContext } from 'react';
import Title from '../../resources/Title';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton'
import { OTAContext } from '../../../context/OTAContext'
import * as Constants from '../../api/constants' 
import { DataGrid } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Generate Order Data
function createData(id, type, visibility) {
  return { id, type, visibility };
}

let rows = [];

const cellRenderer = (params) => {
  return params.value;
};

const requestOptions = {
  method: 'GET', 
  credentials: "include"
};

export default function OTAFirmwareList() {
  const columns = [
    { field: 'id', headerName: 'ID', flex: 1, minWidth: 80 },
    { field: 'type', headerName: 'Device', flex: 1, minWidth: 100 },
    // { field: 'show', headerName: 'Status', flex: 1, minWidth: 100 },
    { field: 'visibility', headerName: 'Visibility', flex: 1, minWidth: 150, renderCell: cellRenderer },
  ];

  rows = [];
  const [data, setData] = React.useState(rows);

  const setVisibility = (id, value) => {
    const requestOptions = { 
      method: 'PUT',
      headers: { 'Accept': 'application/json', 
                 'Content-Type': 'application/json', }, 
      body: JSON.stringify({ Visible: value }),  
      credentials: "include"
    };
  
    fetch(Constants.baseURL + Constants.apiSetDeviceTypeStatus + '/' + id, requestOptions) 
      .then(response => { 
        if (response.status !== 200) {
          toast.error('Send failed. Please try again', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        } else {
          return response.json();
        }})
      .then(data => {  
        [...data.deviceTypes].forEach(element => {
          rows.push(createData(element.id, element.type, element.show ? 
          <IconButton onClick={() => {setVisibility(element.id, false)}}> <VisibilityIcon /> </IconButton> : 
          <IconButton onClick={() => {setVisibility(element.id, true)}}> <VisibilityOffIcon /> </IconButton> ))
        });
        setData(rows);
      }) 
      .catch(error => { console.log('request failed', error); }); 
  }

  React.useEffect(() => {
    fetch(Constants.baseURL + Constants.apiGetDeviceTypes, requestOptions) 
      .then(response => { 
        if (response.status !== 200) {
          toast.error('Send failed. Please try again', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        } else {
          return response.json();
        }})
      .then(data => {  
        [...data.deviceTypes].forEach(element => {
          rows.push(createData(element.id, element.type, element.show ? 
          <IconButton onClick={() => {setVisibility(element.id, false)}}> <VisibilityIcon /> </IconButton> : 
          <IconButton onClick={() => {setVisibility(element.id, true)}}> <VisibilityOffIcon /> </IconButton> ))
        });
        setData(rows);
      }) 
      .catch(error => { console.log('request failed', error); }); 
  }, [data]);

  return (
    <React.Fragment>
      <ToastContainer />
        <Stack direction="row" spacing={1} justifyContent='space-between'>
          <Title align='left'>Firmware Device Types</Title>
        </Stack>
        <div style={{ height: 380, width: '100%', 'padding-top': '10px' }}>
          <DataGrid sx={{
                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                  outline: "none !important",
                },
            }}
            rows={data}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            checkboxSelection={false}
            isRowSelectable={false}
            disableSelectionOnClick 
          />
        </div>
    </React.Fragment>
  );
}