import React, { useContext, useRef } from 'react';
import Title from '../resources/Title';
import { MainContext } from '../../context/MainContext'
import { UserContext } from '../../context/UserContext'
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { DataGrid } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as Constants from '../api/constants' 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import Container from '@mui/material/Container';

import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';

const cellRenderer = (params) => {
  return params.value;
};

function createData(id, client) {
  return { id, client };
}

function createAdminUsersData(id, email, firstName, lastName, permission, edit) {
  return { id, email, firstName, lastName, permission, edit };
}

let rows = [];

let permissionOptions = [ ];

function PermissionDialog(props) {
  const { onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = React.useState(valueProp);
  const radioGroupRef = React.useRef(null);

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle>Permissions</DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="permission"
          name="permission"
          value={value}
          onChange={handleChange}
        >
          {permissionOptions.map((option) => (
            <FormControlLabel
              value={option}
              key={option}
              control={<Radio />}
              label={option}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleOk}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}

PermissionDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ClientsList(props) {
  const clientsList = useContext(MainContext);
  
  const parent = props;
  
  // Admin Users 
  const adminColumns = [
    { field: 'id', headerName: 'ID', flex: 0.5 },
    { field: 'email', headerName: 'Email', flex: 2 },
    { field: 'firstName', headerName: 'First Name', flex: 1 },
    { field: 'lastName', headerName: 'Last Name', flex: 1 },
    { field: 'permission', headerName: 'Permission', flex: 1 },
    { field: 'edit', headerName: 'Edit', flex: 1, renderCell: cellRenderer },
  ];
 
  let adminRows = [];
 
  const tfAdminUsers = useRef('');

  const [adminData, adminSetData] = React.useState(rows);
  adminRows.push(createAdminUsersData(1, 'en@herontechnology.co.nz', 'En', 'Hoang', 'Full Admin Rights', <Button variant="contained" type="submit">Edit</Button>)); 
  // adminSetData(adminRows);
  
  // React.useEffect(() => { 
  //   [...clientsList].forEach(element => {
  //     adminRows.push(createData(element.id, element.client, <Button variant="contained" type="submit" onClick={handleClickOpen}>Edit</Button>))
  //   });

  //   setData(rows)
  // }, [clientsList])


  // Clients
  const columns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'client', headerName: 'Name', flex: 1 },
    // { field: 'editClient', headerName: 'Edit Client', flex: 1, renderCell: cellRenderer },
  ];
 
  rows = [];
 
  const tfClientName = useRef('');

  const [data, setData] = React.useState(rows);
  React.useEffect(() => { 
    [...clientsList].forEach(element => {
      rows.push(createData(element.id, element.client, <Button variant="contained" type="submit">Edit</Button>))
    });

    setData(rows)
  }, [clientsList])

  const handleSearch = () => {
    parent.getClientsList(tfClientName.current.value);
  };

  const onChangeHandler = (ev) => {
    if (tfClientName.current.value === '' && ev.key !== 'Enter') {
      parent.getClientsList(tfClientName.current.value);
    }
  }

  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('');

  const openDialog = () => {
    setOpen(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setValue(newValue);
    }
  };

  const requestOptions = {
    method: 'GET', 
    credentials: "include"
  };

  fetch(Constants.baseURL + Constants.apiGetClientPermissions, requestOptions) 
    .then(response => { 
        if (!response.ok && response.status !== 404) {
            this.props.history.push('/');
        } 
        return response.json() })
    .then(data => {  
      permissionOptions = [];
      [...data.permissions].forEach(element => {
        permissionOptions.push(element.permission);
      });
    }) 
    .catch(error => { console.log('request failed', error); }); 

  return (
    <React.Fragment>
      <ToastContainer />
        <Stack direction="row" spacing={1} justifyContent='space-between'>
          <Title align='left'>Clients</Title>
          <Stack direction="row" justifyContent={"flex-end"} spacing={1}>
            <TextField id="tfName" inputRef={tfClientName} label="Search Client" 
              variant="outlined" size="small" direction="row" 
              onKeyDown={(ev) => {
                if (ev.key === 'Enter') {
                  handleSearch()
                  ev.preventDefault();
                }
              }}
              onChange={(ev) => {onChangeHandler(ev)}}
              justifyContent={"flex-end"}  />
              <Button variant="contained" type="submit" onClick={() => {handleSearch()}}>Search</Button>
              {/* <IconButton color={"primary"} aria-label="" component="label" onClick={ () => openDialog() }>
                <PersonAddAltIcon  />
            </IconButton> */}
          </Stack>
        </Stack>
        <div style={{ height: 800, width: '100%', 'padding-top': '10px' }}>
          <DataGrid sx={{
                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                  outline: "none !important",
                },
            }}
            rows={data}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[5]}
            checkboxSelection={false}
            isRowSelectable={false}
            disableSelectionOnClick 
          />
        </div>
        <PermissionDialog
          id="permission-menu"
          keepMounted
          open={open}
          onClose={handleClose}
          value={value}
        />

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {/* Client + Admin Users */}
              Client
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Stack direction="row" spacing={1} sx={{ mb: 2 }} justifyContent='space-between'>
            <Title align='left'>Add Client</Title>
          </Stack>

        <TextField
          id="tfClientName"
          label="Client Name"
          defaultValue="Name"
          size="small"
        />

        <Button variant="contained" size="large" sx={{ ml: 2 }}>Add</Button>

        <Stack direction="row" spacing={1} justifyContent='space-between' sx={{ mt: 4 }}>
          <Title align='left'>Admin Users</Title>
          <Stack direction="row" justifyContent={"flex-end"} spacing={1}>
            <TextField id="tfName" inputRef={tfClientName} label="Search Users" 
              variant="outlined" size="small" direction="row" 
              onKeyDown={(ev) => {
                if (ev.key === 'Enter') {
                  handleSearch();
                  ev.preventDefault();
                }
              }}
              onChange={(ev) => {onChangeHandler(ev)}}
              justifyContent={"flex-end"}  />
              <Button variant="contained" type="submit" onClick={() => {handleSearch()}}>Search</Button>
          </Stack>
        </Stack>
        <div style={{ height: 500, width: '100%', 'padding-top': '10px' }}>
          <DataGrid sx={{
                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                  outline: "none !important",
                },
            }}
            rows={adminRows}
            columns={adminColumns}
            pageSize={10}
            rowsPerPageOptions={[5]}
            checkboxSelection={false}
            isRowSelectable={false}
            disableSelectionOnClick 
          />
        </div>
        </Container>
      </Dialog>
    </React.Fragment>
  );
}