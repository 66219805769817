import React, { useContext } from 'react';
import Container from '@mui/material/Container';
import { CssBaseline } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Copyright from './copyright.component/copyright'
import DashboardRFString from './dashboard.components/DashboardRFString';
import DashboardTouchpad from './dashboard.components/DashboardTouchpad';
import DashboardTemperatureSettings from './dashboard.components/DashboardTemperatureSettings';
import DashboardGeneral from './dashboard.components/DashboardGeneral';
import DashboardControl from './dashboard.components/DashboardControl';
import DashboardButtonLEDs from './dashboard.components/DashboardButtonLEDs';
import DashboardPower from './dashboard.components/DashboardPower';
import DashboardDipSwitches from './dashboard.components/DashboardDipSwitches';
import DashboardSpaDetails from './dashboard.components/DashboardSpaDetails';
import DashboardMeter from './dashboard.components/DashboardMeter';
import { MainRfContext } from '../context/MainRfContext';
import { MainContext } from '../context/MainContext';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

export default function Dashboard() {
  const state = useContext(MainRfContext);
  if ((state.selectedDevice === 0)) {
    return (<Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
    <Stack sx={{ width: '100%' }} spacing={2}>
      <Alert variant="filled" severity="error">
        No spa associated with this account
      </Alert>
    </Stack>
  </Container>)
  } else {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <MainContext.Provider value={{ state }}>
          <Grid container spacing={3}>
            {/* Offline */}
            {state.online === false ? <Stack sx={{ width: '100%', pt: 2, pl: 3 }} spacing={2}>
              <Alert variant="filled" severity="error">
                Spa is Offline
              </Alert>
            </Stack> : <div></div>}
            {/* Touchpad */}
            <Grid item xs={12} md={4} lg={4}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: 340,
                }}
              >
                <DashboardTouchpad />
              </Paper>
            </Grid>
            {/* Temp settings */}
            {/* <Grid item xs={12} md={4} lg={4}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: 340,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <DashboardTemperatureSettings />
              </Paper>
            </Grid> */}
            {/* ButtonLEDs */}
            <Grid item xs={12} md={4} lg={4}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: 340,
                }}
              >
                <DashboardButtonLEDs />
              </Paper>
            </Grid>
            {/* Dip Switches */}
            <Grid item xs={12} md={4} lg={4}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: 340,
                }}
              >
                <DashboardDipSwitches />
              </Paper>
            </Grid>
            {/* Spa Details */}
            <Grid item xs={12} md={4} lg={4}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: 365,
                }}
              >
                <DashboardSpaDetails />
              </Paper>
            </Grid>
            {/* Power */}
            <Grid item xs={12} md={4} lg={4}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: 365,
                }}
              >
                <DashboardPower />
              </Paper>
            </Grid>
            {/* General */}
            <Grid item xs={12} md={5} lg={4}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: 365,
                }}
              >
                <DashboardGeneral />
              </Paper>
            </Grid>
            {/* Control */}
            <Grid item xs={12}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <DashboardControl />
              </Paper>
            </Grid>
            {/* Meter */}
            <Grid item xs={12} md={12} lg={12}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <DashboardMeter />
              </Paper>
            </Grid>
            {/* RF String */}
            <Grid item xs={12} md={12} lg={12}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <DashboardRFString />
              </Paper>
            </Grid>
          </Grid>
        </MainContext.Provider>
        <Copyright sx={{ pt: 4 }} />
      </Container>
    );
  }
}