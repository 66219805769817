import React, { useContext } from 'react';
import Typography from '@mui/material/Typography';
import Title from '../resources/Title';
import { MainContext } from '../../context/MainContext';
import { format } from "date-fns";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

export default function HPDashboardTouchpad() {
  const state = useContext(MainContext).state;
  const re = state.RE.split(',');

  var pcbaStatus = '?';
  if (re[38] === '0') pcbaStatus = 'Slave I2C not used';
  if (re[38] === '1') pcbaStatus = 'Slave I2C OK';
  if (re[38] === '2') pcbaStatus = 'Slave I2C working';
  if (re[38] === '3') pcbaStatus = 'Slave I2C timeout';

  var warningsStatus = 'None';
  if (re[23] === '10') {
    warningsStatus = 'Ambient thermistor';
  }
  if (re[23] === '11') {
    warningsStatus = 'Condenser thermistor';
  } 
  if (re[23] === '12') {
    warningsStatus = 'Water flow';
  } 
  if (re[23] === '13') {
    warningsStatus = 'Low pressure';
  } 
  if (re[23] === '14') {
    warningsStatus = 'High pressure';
  } 
  if (re[23] === '15') {
    warningsStatus = 'Compressor';
  } 
  if (re[23] === '16') {
    warningsStatus = 'Heat exchanger';
  } 
  if (re[23] === '17') {
    warningsStatus = 'Compressor thermistor';
  } 

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item sx={{ flex: 1, flexDirection: 'column' }}>
          <Title>{state.deviceName}</Title>
        </Grid>
        <Grid item sx={{ flex: 1, flexDirection: 'column' }} container justifyContent="flex-end">
          <Box display="flex" justifyContent="flex-end">
            <Title>{state.rssi}dB</Title>
          </Box>
        </Grid>
      </Grid>
      
      <Typography color="text.secondary" sx={{ flex: 1, textAlign: 'center', pt: 5, fontSize: 20, color: 'black' }}>
        V{re[1]}
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 2, textAlign: 'center', fontSize: 20, color: 'black' }}>
        {re[40]}
      </Typography>
      {re[1] === '2' ? <Typography color="text.secondary" sx={{ flex: 2, textAlign: 'center' }}>
        HP PCBA status: {pcbaStatus}
      </Typography> : ''}
      <Typography color="text.primary" sx={{ flex: 1 }}>
        Warnings / Faults
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
        {warningsStatus}
      </Typography>
    </React.Fragment>
  );
}