import React, { useContext } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../resources/Title';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { MainContext } from '../../context/MainContext';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import DynamicSelect from '../resources/DynamicSelect';
import * as Constants from '../../components/api/constants'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let deviceId = 0;

// Generate Order Data
function createData(id, property, value, field1, field2) {
  return { id, property, value, field1, field2 };
}

const eeveviData = [];

for (let i = 100; i <= 470; i++) {
  eeveviData.push({
    id: i,
    name: i.toString()    
  });
}

const selectDataArray = [
  {
    id: 0,
    value: ''    
  },
  {
    id: 1,
    value: ''    
  }
];

const handleSelectChange = (id, selectedValue) => {
  selectDataArray[id].value = selectedValue;
}

export default function HPSpecialFunctionsTest() {
  const state = useContext(MainContext).state;
  deviceId = state.selectedDevice;

  const PumpRows = [
    createData(0, 'Pump', <Button size="medium" variant="outlined" onClick={() => sendCommand('SFPumpOn', '')}>On</Button>, <Button size="medium" variant="outlined" onClick={() => sendCommand('SFPumpOff', '')}>Off</Button>),
    createData(1, 'Fan', <Button size="medium" variant="outlined" onClick={() => sendCommand('SFFanOn', '')}>On</Button>, <Button size="medium" variant="outlined" onClick={() => sendCommand('SFFanOff', '')}>Off</Button>),
    createData(2, '4 way valve', <Button size="medium" variant="outlined" onClick={() => sendCommand('SF4WayValveOn', '')}>On</Button>, <Button size="medium" variant="outlined" onClick={() => sendCommand('SF4WayValveOff', '')}>Off</Button>),
    createData(3, 'Compressor', <Button size="medium" variant="outlined" onClick={() => sendCommand('SFCompressorOn', '')}>On</Button>, <Button size="medium" variant="outlined" onClick={() => sendCommand('SFCompressorOff', '')}>Off</Button>),
    createData(4, 'EEV boost', <Button size="medium" variant="outlined" onClick={() => sendCommand('SFEevBoostOn', '')}>On</Button>, <Button size="medium" variant="outlined" onClick={() => sendCommand('SFEevBoostOff', '')}>Off</Button>),
    createData(5, 'EVI', <Button size="medium" variant="outlined" onClick={() => sendCommand('SFEviOn', '')}>On</Button>, <Button size="medium" variant="outlined" onClick={() => sendCommand('SFEviOff', '')}>Off</Button>),
    createData(6, 'Element condensor', <Button size="medium" variant="outlined" onClick={() => sendCommand('SFElementCondenserOn', '')}>On</Button>, <Button size="medium" variant="outlined" onClick={() => sendCommand('SFElementCondenserOff', '')}>Off</Button>),
    createData(7, 'Element compressor', <Button size="medium" variant="outlined" onClick={() => sendCommand('SFElementCompressorOn', '')}>On</Button>, <Button size="medium" variant="outlined" onClick={() => sendCommand('SFElementCompressorOff', '')}>Off</Button>),
  ];
  
  const ValuesRows = [
    createData(0, 'EEV', '0', <DynamicSelect arrayOfData={eeveviData} id={0} onSelectChange={handleSelectChange} name={''} />, <Button size="medium" variant="outlined" onClick={() => {onClickListener(0)}}>Send</Button>),
    createData(1, 'EVI', '0', <DynamicSelect arrayOfData={eeveviData} id={1} onSelectChange={handleSelectChange} name={''} />, <Button size="medium" variant="outlined" onClick={() => {onClickListener(1)}}>Send</Button>),
  ];

  const onClickListener = (id) => {
    if (id === 0) {
      sendCommand('SFValvesEev', selectDataArray[id].value);
    } else if (id === 1) {
      sendCommand('SFValvesEvi', selectDataArray[id].value);
    } 
  }

  const sendCommand = (command, value) => {
    const requestOptions = { 
      method: 'POST',
      headers: { 'Accept': 'application/json', 
                 'Content-Type': 'application/json', }, 
      body: JSON.stringify({ DeviceId: deviceId,
                              Command: command, 
                              Value: value.toString() }),  
      credentials: "include"
    };
  
    fetch(Constants.baseURL + Constants.apiSendCommand, requestOptions) 
        .then((response) => { 
            if (response.ok) {
              toast.success('Command sent successfully. Refreshing...', {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
                
              setTimeout(function() {
                state.refreshDeviceRF(deviceId);
              }, 6000);

              setTimeout(function() {
                state.getUserDeviceRf(deviceId);
              }, 12000);
            } else {
              toast.error('Send failed. Please try again', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            }
        }).catch(error => { console.log('request failed', error); });
  }
  
  return (
    <React.Fragment>
      <ToastContainer />
      <Title>Test</Title>
      <Container maxWidth="lg" sx={{ flexDirection: 'row', paddingHorizontal: 0 }}>
        <Grid container spacing={3}> 
          <Grid item sx={{ flex: 1, flexDirection: 'column' }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell><b>Relays</b></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {PumpRows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.property}</TableCell>
                    <TableCell>{row.value}</TableCell>
                    <TableCell>{row.field1}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <Grid item sx={{ flex: 1, flexDirection: 'column' }}>
          <Grid item>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell><b>Values</b></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ValuesRows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.property}</TableCell>
                    <TableCell>{row.value}</TableCell>
                    <TableCell>{row.field1}</TableCell>
                    <TableCell>{row.field2}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <Grid item sx={{ pt: 6 }}>
            <Container maxWidth="lg" sx={{ mb: 1 }} >
              <Grid container spacing={1.5} align='center' justifyContent='center' alignItems='center' direction={'row'}>
                <Grid item> 
                  <Button size="medium" variant="outlined" onClick={() => sendCommand('SFTestModeOn', '')}>Enter test mode</Button>
                </Grid>
                <Grid item>
                  <Button size="medium" variant="outlined" onClick={() => sendCommand('SFTestModeOff', '')}>Exit test mode</Button>
                </Grid>
                <Grid item>
                  <Button size="medium" variant="outlined" onClick={() => sendCommand('SFReboot', '')}>Reboot</Button>
                </Grid>
              </Grid>
            </Container>
          </Grid>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
