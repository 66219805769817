import React, { Component } from 'react'; 
import * as Constants from './api/constants' 
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Copyright from './copyright.component/copyright'
import SpasList from './spa.components/SpaList';
import { MainContext } from '../context/MainContext'
import { UserContext } from '../context/UserContext'
export default class Users extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      spasList: [],
      spinner: false
    };
  }

  getSpasList = (name = '', email = '', macAddress = '') => {
    const requestOptions = {
      method: 'GET', 
      credentials: "include"
    };
    
    const parameters = new URLSearchParams({
      searchName: name,
      searchEmail: email,
      searchMacAddress: macAddress
    });

    fetch(Constants.baseURL + Constants.apiSpasList + '?' + parameters, requestOptions) 
      .then(response => { 
          if (!response.ok && response.status !== 404) {
              this.props.history.push('/');
          }
          return response.json() })
      .then(data => {  
        this.setState({ 
          spasList: data.spas,
          spinner: false
        });
      }) 
      .catch(error => { console.log('request failed', error); }); 
  }

  componentDidMount() {
    this._isMounted = true;

    this.setState({
      usersList: [],
      spinner: false
    })

    this.getSpasList();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.setState = (state,callback)=>{
      return;
    };
  }

  render() {
    return (<Container maxWidth="900px" sx={{ mt: 4, mb: 4 }}>
    <Grid container spacing={3}>
      {/* UsersList */}
      <Grid item xs={12} md={12} lg={12}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 713,
              }}
            >
              <UserContext.Provider value={this.props} >
                <MainContext.Provider value={this.state.spasList} >
                  <SpasList getSpasList={this.getSpasList} />
                </MainContext.Provider>
              </UserContext.Provider>
            </Paper>
          </Grid>
    </Grid>
    <Copyright sx={{ pt: 4 }} />
  </Container>);
  }
}