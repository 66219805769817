import React, { useState } from 'react';
import { Box, Typography, IconButton, CircularProgress } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const Thermostat = () => {
  const [targetTemp, setTargetTemp] = useState(40);
  const currentTemp = 30.0;

  const increaseTemp = () => {
    setTargetTemp(prevTemp => Math.min(prevTemp + 0.5, 40));
  };

  const decreaseTemp = () => {
    setTargetTemp(prevTemp => Math.max(prevTemp - 0.5, 0));
  };

  const percentage = (currentTemp / targetTemp) * 100;

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ width: 200, height: 300 }}
    >
      <Typography variant="h6" gutterBottom>
        Dashboard
      </Typography>
      <Box position="relative" display="inline-flex">
        <CircularProgressbar
          value={percentage}
          // text={`${targetTemp.toFixed(1)}°C`}
          styles={buildStyles({
            pathColor: `rgba(255, 99, 71)`,
            textColor: '#000',
            trailColor: '#d6d6d6',
          })}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Typography variant="h4">{currentTemp.toFixed(1)}°C</Typography>
          <Typography variant="h9">set temp</Typography>
          <Typography variant="h5">{targetTemp.toFixed(1)}°C</Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" mt={2}>
        <IconButton onClick={decreaseTemp} sx={{ border: '1px solid grey', borderRadius: '50%', mx: 1 }}>
          <Remove />
        </IconButton>
        <IconButton onClick={increaseTemp} sx={{ border: '1px solid grey', borderRadius: '50%', mx: 1 }}>
          <Add />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Thermostat;
