import React, { useContext } from 'react';
import Typography from '@mui/material/Typography';
import Title from '../resources/Title';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { MainContext } from '../../context/MainContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import * as Constants from '../api/constants'
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';

const { useState } = React;
let deviceId = 0;

export default function DashboardMeter() {
  const [ voltage, setVoltage ] = useState();
  const [ current, setCurrent ] = useState();

  const state = useContext(MainContext).state;
  deviceId = state.selectedDevice;
  const r2 = state.R2.split(',');
  const r3 = state.R3.split(',');
  const r4 = state.R4.split(',');
  const r7 = state.R7.split(',');

  function createData(id, icon, property, value, spare1, spare2) {
    return { id, icon, property, value, spare1, spare2 };
  }

  const useStyles = makeStyles({
    combinedCell: {
      alignItems: 'center',
    },
    gap: {
      marginLeft: '20px', 
    },
    rightAlign: {
      textAlign: 'right',
    },
  });

  const MainsRow = [
    createData(0, <BoltOutlinedIcon />, 'Voltage', 'N/A'),
    createData(1, <BoltOutlinedIcon />, 'Current', 'N/A'),
    createData(2, <BoltOutlinedIcon />, 'Wattage', 'N/A'),
  ];

  const VoltageAdjustRow = [
    createData(0, "", <Button size="small" variant="outlined" onClick={() => sendCommand('VoltageDec', '')}>-</Button>, 'N/A', <Button size="small" variant="outlined" onClick={() => sendCommand('VoltageInc', '')}>+</Button>),
  ];

  const CurrentAdjustRow = [
    createData(0, "", <Button size="small" variant="outlined" onClick={() => sendCommand('CurrentDec', '')}>-</Button>, 'N/A', <Button size="small" variant="outlined" onClick={() => sendCommand('CurrentInc', '')}>+</Button>),
  ];

  const OtherRow = [
    createData(0, <BoltOutlinedIcon />, 'Current 0 count', 'N/A'),
    createData(1, <BoltOutlinedIcon />, 'Current raw count', 'N/A'),
    createData(2, <BoltOutlinedIcon />, '12V port current', 'N/A'),
    createData(3, <BoltOutlinedIcon />, 'Case temp', 'N/A'),
  ];

  MainsRow[0].value = r2[2] + " V"
  MainsRow[1].value = (r2[1]/10.0).toFixed(1) + " A"
  MainsRow[2].value = (r4[10]/10.0).toFixed(1) + " W"

  VoltageAdjustRow[0].value = (r7[17]/10.0).toFixed(1);

  CurrentAdjustRow[0].value = (r7[16]/10.0).toFixed(1);

  OtherRow[0].value = r7[15];
  OtherRow[1].value = r3[19];
  OtherRow[2].value = r2[4] + ' mA';
  OtherRow[3].value = r2[3] + ' °C';

  const sendCommand = (command, value) => {
    const requestOptions = { 
      method: 'POST',
      headers: { 'Accept': 'application/json', 
                 'Content-Type': 'application/json', }, 
      body: JSON.stringify({ DeviceId: deviceId,
                              Command: command, 
                              Value: value.toString() }),  
      credentials: "include"
    };
  
    fetch(Constants.baseURL + Constants.apiSendCommand, requestOptions) 
        .then((response) => { 
            if (response.ok) {
              toast.success('Command sent successfully. Refreshing...', {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
                
              setTimeout(function() {
                state.refreshDeviceRF(deviceId);
              }, 6000);

              setTimeout(function() {
                state.getUserDeviceRf(deviceId);
              }, 12000);
            } else {
              toast.error('Send failed. Please try again', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            }
        }).catch(error => { console.log('request failed', error); });
  }

  const VoltageSendButton = () => (
    <Button size="small" variant="text" onClick={() => sendCommand('VoltageAdjustment', voltage)}>Send</Button>
  )

  const CurrentSendButton = () => (
    <Button size="small" variant="text" onClick={() => sendCommand('CurrentAdjustment', current)}>Send</Button>
  )

  const classes = useStyles();

  return (
    <React.Fragment>
      <ToastContainer />
      <Title>Meter</Title>
      <Container disableGutters maxWidth="lg" sx={{ flexDirection: 'row', paddingHorizontal: 0 }}>
        <Grid container spacing={3}> 
          <Grid item sx={{ flex: 1, flexDirection: 'column' }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell><b>Mains</b></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {MainsRow.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell colSpan={2} className={classes.combinedCell}>
                      {row.icon}
                      <span className={classes.gap}></span>
                      {row.property}
                    </TableCell>
                    <TableCell align="right" className={classes.rightAlign}>
                      {row.value}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <Grid item sx={{ flex: 1, flexDirection: 'column' }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell><b>Other</b></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {OtherRow.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell colSpan={2} className={classes.combinedCell}>
                      {row.icon}
                      <span className={classes.gap}></span>
                      {row.property}
                    </TableCell>
                    <TableCell align="right" className={classes.rightAlign}>
                      {row.value}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Grid sx={{ pt: 1 }} maxWidth="lg">  
              <Button variant="outlined" size="small" sx={{ width: '100%' }} onClick={() => sendCommand('ZeroAmps', '')}>Zero Amps</Button>
            </Grid>
          </Grid>
            <Grid item >
            <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell><b>Voltage</b></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {VoltageAdjustRow.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.property}</TableCell>
                      <TableCell>{row.value}</TableCell>
                      <TableCell>{row.spare1}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TextField
                onChange={(event) => { setVoltage(event.target.value) }}
                variant="outlined"
                size='small'
                InputProps={{endAdornment: <VoltageSendButton />}}
                sx={{ pt: 1}}
              />
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell><b>Current</b></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {CurrentAdjustRow.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.property}</TableCell>
                      <TableCell>{row.value}</TableCell>
                      <TableCell>{row.spare1}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TextField
                onChange={(event) => {setCurrent(event.target.value)}}
                variant="outlined"
                size='small'
                InputProps={{endAdornment: <CurrentSendButton />}}
                sx={{ pt: 1}}
              />
            </Grid>
          </Grid>
      </Container>
    </React.Fragment>
  );
}