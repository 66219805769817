import React, { useContext } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../resources/Title';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { MainContext } from '../../context/MainContext';

// Generate Order Data
function createData(id, property, value) {
  return { id, property, value };
}

const RefrigerationControlRows = [
  createData(0, 'Status', 'N/A'),
  createData(1, 'Mode', 'N/A'),
  createData(2, 'Compressor run timer', 'N/A'),
  createData(3, 'Sequence timer', 'N/A'),
];

const DefrostControlTimerslRows = [
  createData(0, 'Low temperature', 'N/A'),
  createData(1, 'Defrost cycle run', '...'),
  createData(2, 'Condenser element', '...'),
  createData(3, 'Compressor element', '...'),
];

const ProtectionTimersRows = [
  createData(0, 'Low temp (P.LOW)', '...'),
  createData(1, 'Freeze (P.FRE)', '00:00:00')
];

export default function HPDashboardControlTemp() {
  const state = useContext(MainContext).state;
  var re = state.RE.split(',');
  
  if (re[1] === '1') {
    if (re[16] === '0') RefrigerationControlRows[0].value = 'Standby';
    if (re[16] === '1') RefrigerationControlRows[0].value = 'Fault';
    if (re[16] === '2') RefrigerationControlRows[0].value = 'Start';
    if (re[16] === '3') RefrigerationControlRows[0].value = 'Run';
    if (re[16] === '4') RefrigerationControlRows[0].value = 'Stopping';
    if (re[16] === '5') RefrigerationControlRows[0].value = 'Stop';
    if (re[16] === '6') RefrigerationControlRows[0].value = 'Defrost start';
    if (re[16] === '7') RefrigerationControlRows[0].value = 'Defrost start 2';
    if (re[16] === '8') RefrigerationControlRows[0].value = 'Defrost run';
    if (re[16] === '9') RefrigerationControlRows[0].value = 'Defrost stop';
  
    if (re[17] === '1') RefrigerationControlRows[1].value = 'Heat';
    if (re[17] === '0') RefrigerationControlRows[1].value = 'Cool';
    
    if (re[19] !== undefined) RefrigerationControlRows[2].value = re[19].toHHMMSS();
    RefrigerationControlRows[3].value = re[22].toHHMMSS();
  
    if (re[20] !== undefined) DefrostControlTimerslRows[0].value = re[20].toHHMMSS();
    if (re[18] !== undefined) DefrostControlTimerslRows[1].value = re[18].replace('-', '').toHHMMSS();
    
    if (re[49] !== undefined) DefrostControlTimerslRows[2].value = re[49].toHHMMSS();
    if (re[50] !== undefined) DefrostControlTimerslRows[3].value = re[50].toHHMMSS();
    if (re[51] !== undefined) ProtectionTimersRows[0].value = re[51].toHHMMSS();
    if (re[52] !== undefined) ProtectionTimersRows[1].value = re[52].toHHMMSS(); 
  } else if (re[1] === '2') {
    if (re[24] === '0') RefrigerationControlRows[0].value = 'Standby';
    if (re[24] === '1') RefrigerationControlRows[0].value = 'Fault';
    if (re[24] === '2') RefrigerationControlRows[0].value = 'Start';
    if (re[24] === '3') RefrigerationControlRows[0].value = 'Run';
    if (re[24] === '4') RefrigerationControlRows[0].value = 'Stopping';
    if (re[24] === '5') RefrigerationControlRows[0].value = 'Stop';
    if (re[24] === '6') RefrigerationControlRows[0].value = 'Defrost start';
    if (re[24] === '7') RefrigerationControlRows[0].value = 'Defrost start 2';
    if (re[24] === '8') RefrigerationControlRows[0].value = 'Defrost run';
    if (re[24] === '9') RefrigerationControlRows[0].value = 'Defrost stop';
  
    if (re[25] === '1') RefrigerationControlRows[1].value = 'Heat';
    if (re[25] === '0') RefrigerationControlRows[1].value = 'Cool';
    
    if (re[27] !== undefined) RefrigerationControlRows[2].value = re[27].toHHMMSS();
    RefrigerationControlRows[3].value = re[30].toHHMMSS();
  
    if (re[28] !== undefined) DefrostControlTimerslRows[0].value = re[28].toHHMMSS();
    if (re[26] !== undefined) DefrostControlTimerslRows[1].value = re[26].replace('-', '').toHHMMSS();
    
    if (re[49] !== undefined) DefrostControlTimerslRows[2].value = re[49].toHHMMSS();
    if (re[50] !== undefined) DefrostControlTimerslRows[3].value = re[50].toHHMMSS();
    if (re[51] !== undefined) ProtectionTimersRows[0].value = re[51].toHHMMSS();
    if (re[52] !== undefined) ProtectionTimersRows[1].value = re[52].toHHMMSS(); 
  } 

  return (
    <React.Fragment>
      <Title>Control</Title>
      <Container maxWidth="lg" sx={{ flexDirection: 'row', paddingHorizontal: 0 }}>
        <Grid container spacing={3}> 
          <Grid item sx={{ flex: 1, flexDirection: 'column' }}>
          <Grid item>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell><b>Refrigeration Control</b></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {RefrigerationControlRows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.property}</TableCell>
                    <TableCell>{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <Grid item>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell><b>Defrost Control Timers</b></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {DefrostControlTimerslRows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.property}</TableCell>
                    <TableCell>{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <Grid item>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell><b>Protection Timers</b></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ProtectionTimersRows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.property}</TableCell>
                    <TableCell>{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

// eslint-disable-next-line no-extend-native
String.prototype.toHHMMSS = function () {
  var sec_num = parseInt(this, 10); 
  var hours   = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  var seconds = sec_num - (hours * 3600) - (minutes * 60);

  if (hours   < 10) {hours   = "0"+hours;}
  if (minutes < 10) {minutes = "0"+minutes;}
  if (seconds < 10) {seconds = "0"+seconds;}
  return hours+':'+minutes+':'+seconds;
}