import React, { useContext } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Title from '../resources/Title';
import { MainContext } from '../../context/MainContext';

// Generate Order Data
function createData(id, property, value) {
  return { id, property, value };
}

const rows = [
  createData(0, 'Pump', 'N/A',),
  createData(1, 'Fan', 'N/A',),
  createData(2, '4 way valve', 'N/A',),
  createData(3, 'Compressor', 'N/A',),
  createData(4, 'EEV Boost', 'N/A',),
  createData(5, 'EVI', 'N/A',),
  createData(6, 'Element condenser', 'N/A',),
  createData(7, 'Element compressor', 'N/A',),
];

export default function HPDashboardRelays() {
  const state = useContext(MainContext).state;
  var re = state.RE.split(',');

  if (re[13] === '1') rows[1].value = 'ON'; 
  if (re[13] === '0') rows[1].value = 'OFF';

  if (re[14] === '1') rows[2].value = 'ON'; 
  if (re[14] === '0') rows[2].value = 'OFF';

  if (re[12] === '1') rows[3].value = 'ON'; 
  if (re[12] === '0') rows[3].value = 'OFF';

  if (re[15] === '1') rows[6].value = 'ON'; 
  if (re[15] === '0') rows[6].value = 'OFF';

  if (re[1] === '2') {
    if (re[17] === '1') rows[0].value = 'ON'; 
    if (re[17] === '0') rows[0].value = 'OFF'; 

    if (re[21] === '1') rows[4].value = 'ON'; 
    if (re[21] === '0') rows[4].value = 'OFF';

    if (re[22] === '1') rows[5].value = 'ON'; 
    if (re[22] === '0') rows[5].value = 'OFF';

    if (re[20] === '1') rows[7].value = 'ON'; 
    if (re[20] === '0') rows[7].value = 'OFF';

    // ----

    if (re[16] === '1') rows[1].value = 'ON'; 
    if (re[16] === '0') rows[1].value = 'OFF';

    if (re[18] === '1') rows[2].value = 'ON'; 
    if (re[18] === '0') rows[2].value = 'OFF';

    if (re[15] === '1') rows[3].value = 'ON'; 
    if (re[15] === '0') rows[3].value = 'OFF';

    if (re[19] === '1') rows[6].value = 'ON'; 
    if (re[19] === '0') rows[6].value = 'OFF';
  }
  
  return (
    <React.Fragment>
      <Title>Relays</Title>
      <Table size="small">
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.property}</TableCell>
              <TableCell>{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}