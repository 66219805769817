import React, { useEffect, useState } from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Badge from '@mui/material/Badge';
import LogoutIcon from '@mui/icons-material/Logout';
import logo from './components/resources/login_logo.png';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import { mainListItems, mainListItemsHeatPump, adminListItems, dealerListItems } from './components/resources/listItems';
import SignIn from "./components/SignIn";
import SignInDealer from "./components/SignInDealer";
import SignInPIN from "./components/SignInPIN";
import Dashboard from './components/Dashboard';
import Control from './components/Control';
import History from './components/History';
import EEPROM from './components/EEPROM';
import OTA from './components/OTA';
import Blacklist from './components/Blacklist'
import HPDashboard from './components/HPDashboard';
import HPDashboardTemp from './components/HPDashboardTemp';
import HPSpecialFunctions from './components/HPSpecialFunctions';
import HPHistory from './components/HPHistory';
import HPEEPROM from './components/HPEEPROM';
import Users from './components/Users';
import Clients from './components/Clients';
import Spas from './components/Spas';
import DealerSpas from './components/DealerSpas';
import DealerList from './components/Dealers';
import { Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import * as Constants from './components/api/constants' 
import { MainRfContext } from './context/MainRfContext'
import { LoggedInUserContext } from './context/LoggedInUserContext'
import { reactLocalStorage } from 'reactjs-localstorage';
import Button from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Refresh';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const mdTheme = createTheme();

function App() {
  const history = useHistory(); 
  const [open, setOpen] = useState(true);
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(0);
  const [R2, SetR2] = useState('');
  const [R3, SetR3] = useState('');
  const [R4, SetR4] = useState('');
  const [R5, SetR5] = useState('');
  const [R6, SetR6] = useState('');
  const [R7, SetR7] = useState('');
  const [R9, SetR9] = useState('');
  const [RA, SetRA] = useState('');
  const [RB, SetRB] = useState('');
  const [RC, SetRC] = useState('');
  const [RE, SetRE] = useState('');
  const [RG, SetRG] = useState('');
  const [online, setOnline] = useState(false);
  const [rssi, setRssi] = useState(0);
  const [permission, setPermission] = useState(null);
  const [userId, setUserId] = useState(0);
  const [deviceId, setDeviceId] = useState(0);
  const [deviceName, setDeviceName] = useState('');
  const [deviceType, setDeviceType] = useState('');
  const [snWifiRf, setSnWifiRf] = useState('');
  const [dealerUsername, setDealerUsername] = useState('');
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const clearState = () => {
    reactLocalStorage.set('isLoggedIn', false);
    setDevices([]);
    setSelectedDevice(0);
    SetR2('');
    SetR3('');
    SetR4('');
    SetR5('');
    SetR6('');
    SetR7('');
    SetR9('');
    SetRA('');
    SetRB('');
    SetRC('');
    SetRE('');
    SetRG('');
    setOnline(false);
    setRssi(0);
    setUserId(0);
    setDeviceName('');
    setSnWifiRf('');
  }

  const getUserDevicesList = (userId) => {  
    if (permission === null) {
      getUserPermission();
    }

    const requestOptions = {
      method: 'GET', 
      credentials: "include"
    };
  
    const parameters = new URLSearchParams({
      userId: userId,
      deviceId: deviceId
    });
  
    fetch(Constants.baseURL + Constants.apiUserDevicesList + '?' + parameters, requestOptions) 
      .then(response => { 
          if (!response.ok && response.status !== 404) {
              // history.push('/');
          } else if (response.status === 404) {
            setDevices([]);
            setDeviceId(0)
            setSelectedDevice(0);
          }
          return response.json() })
      .then(data => {  
        if (data.devices !== undefined) {
          setDevices(data.devices);
          if (data.devices.length > 0) {
            if (reactLocalStorage.get('selectedDevice', '0') !== '0') {
              setSelectedDevice(reactLocalStorage.get('selectedDevice', 0));
              getUserDeviceRf(reactLocalStorage.get('selectedDevice', 0));
            } else {
              setSelectedDevice(data.devices[0].id);
              getUserDeviceRf(data.devices[0].id);
            }
          }
        } else {
          setDevices([]);
          setSelectedDevice(0);
          SetR2('');
          SetR3('');
          SetR4('');
          SetR5('');
          SetR6('');
          SetR7('');
          SetR9('');
          SetRA('');
          SetRB('');
          SetRC('');
          SetRE('');
          SetRG('');
          setOnline(false);
          setRssi(0);
          setDeviceName('');
          setSnWifiRf('');
        }
      }) 
      .catch(error => { console.log('request failed', error); }); 
  }

  const getUserPermission = () => {
    const requestOptions = {
      method: 'GET', 
      credentials: "include"
    };

    fetch(Constants.baseURL + Constants.apiGetUserPermissions, requestOptions) 
      .then(response => { 
          if (!response.ok && response.status !== 404) {
              history.push('/');
          } 
          return response.json() })
      .then(data => {  
        setPermission(data.permission);
        getDealerUsername();
      }) 
      .catch(error => { console.log('request failed', error); }); 
  }

  const refreshDeviceRF = (deviceId) => {
    const requestOptions = {
      method: 'GET', 
      credentials: "include"
    };

    const parameters = new URLSearchParams({
      deviceId: deviceId,
      userId: userId
    });

    fetch(Constants.baseURL + Constants.apiDeviceRfRefresh + '?' + parameters, requestOptions) 
      .then(response => { 
          if (!response.ok && response.status !== 404) {
              history.push('/');
          }})
      .catch(error => { console.log('request failed', error); }); 
  }

  const getDealerUsername = () => {
    const requestOptions = {
      method: 'GET', 
      credentials: "include"
    };

    fetch(Constants.baseURL + Constants.apiGetDealerUsername, requestOptions) 
      .then(response => { 
          return response.json() })
      .then(data => {  
        if (data.name !== "") {
          setDealerUsername("Dealer - " + data.name)
        } else {
          setDealerUsername("Dealer Spas")
        }
      }) 
      .catch(error => { console.log('request failed', error); }); 
  }

  const userDeviceRfRefresh = (deviceId) => {
    const requestOptions = {
      method: 'GET', 
      credentials: "include"
    };

    if (deviceId === 0) {
      deviceId = selectedDevice
    }
  
    const parameters = new URLSearchParams({
      deviceId: deviceId,
      userId: userId
    });
  
    fetch(Constants.baseURL + Constants.apiGetDeviceRfString + '?' + parameters, requestOptions) 
      .then(response => { 
          if (!response.ok && response.status !== 404) {
            history.push('/');
          }
          return response.json() })
      .then(data => {  
        setTimeout(function() {
          getUserDeviceRf(deviceId);
        }, 4000);
      }) 
      .catch(error => { console.log('request failed', error); }); 
  }

  const getUserDeviceRf = (deviceId) => {
    const requestOptions = {
      method: 'GET', 
      credentials: "include"
    };

    if (deviceId === 0) {
      deviceId = selectedDevice
    }
  
    const parameters = new URLSearchParams({
      deviceId: deviceId,
      userId: userId
    });
  
    fetch(Constants.baseURL + Constants.apiGetDeviceRfString + '?' + parameters, requestOptions) 
      .then(response => { 
          if (!response.ok && response.status !== 404) {
            history.push('/');
          }
          return response.json() })
      .then(data => {  
        setDeviceName(data.deviceName);
        let rfString = data.deviceRf.split(':');
        SetR2(rfString[0]);
        SetR3(rfString[1]);
        SetR4(rfString[2]);
        SetR5(rfString[3]);
        SetR6(rfString[4]);
        SetR7(rfString[5]);
        SetR9(rfString[6]);
        SetRA(rfString[7]);
        SetRB(rfString[8]);
        SetRC(rfString[9]);
        SetRE(rfString[10]);
        SetRG(rfString[11]);
        setOnline(data.online);
        setRssi(data.rssi);
        setDeviceType(data.deviceType);
        setSnWifiRf(data.snWifiRf);
      }) 
      .catch(error => { console.log('request failed', error); }); 
  }

  const handleLogout = () => {
    clearState();
    window.localStorage.clear();
    reactLocalStorage.clear();
    reactLocalStorage.set('selectedDevice', 0);
    setSelectedDevice(0);
    setDevices([]);
    SetR2('');
    SetR3('');
    SetR4('');
    SetR5('');
    SetR6('');
    SetR7('');
    SetR9('');
    SetRA('');
    SetRB('');
    SetRC('');
    SetRE('');
    SetRG('');
    setPermission(null);
    setSnWifiRf('');
    setTimeout(()=>{
      history.push('/');
    }, 1000);
  }

  const handleChangeDevice = (event) => {
    setSelectedDevice(event.target.value);
    getUserDeviceRf(event.target.value);
    reactLocalStorage.set('selectedDevice', event.target.value);
  };

  const location = useLocation();
  const [title, setTitle] = useState("/");

  useEffect(() => {
    reactLocalStorage.set('selectedDevice', 0);
    setSelectedDevice(0);
    setDevices([]);
    if (location.pathname !== '/') {
      getUserDevicesList(userId);
    }
    SetR2('');
    SetR3('');
    SetR4('');
    SetR5('');
    SetR6('');
    SetR7('');
    SetR9('');
    SetRA('');
    SetRB('');
    SetRC('');
    SetRE('');
    SetRG('');
  }, [userId]);

  useEffect(() => {
    if (selectedDevice === 0 || reactLocalStorage.get('selectedDevice', 0) === '0') {
      if (location.pathname !== '/' && userId === 0) {
        getUserDevicesList(0);
      }
    } else if (reactLocalStorage.get('selectedDevice', 0) !== '0') {
      getUserDeviceRf(reactLocalStorage.get('selectedDevice', 0));
    } else {
      getUserDeviceRf(selectedDevice);
      reactLocalStorage.set('selectedDevice', selectedDevice);
    } 

    var pathName = location.pathname.toLowerCase()
    if (!reactLocalStorage.get('isLoggedIn', false) && pathName !== "/admin" && pathName !== "/dealer") {
      history.push('/login');
    }

    switch (pathName) {
      case "/dashboard":
        getUserPermission();
        setTitle("Spa Controller | Dashboard");
        break;
      case "/control":
        setTitle("Spa Controller | Control");
        break;
      case "/history":
        setTitle("Spa Controller | History");
        break;
      case "/eeprom":
        setTitle("Spa Controller | Settings");
        break;
      case "/users":
        setTitle("Admin | Users");
        break;
      case "/spas":
        setTitle("Admin | Spas");
      break;
      case "/clients":
        setTitle("Admin | Clients");
      break;
      case "/ota":
        setTitle("Admin | OTA");
        break;
      case "/hpdashboard":
        setTitle("Heat Pump | Dashboard");
        break;
      case "/hpspecialfunctions":
        setTitle("Heat Pump | Special Functions");
      break;
      case "/hphistory":
        setTitle("Heat Pump | History");
        break;
      case "/hpeeprom":
        setTitle("Heat Pump | Settings");
        break;
      case "/admin":
        setTitle("Admin");
        break;
      case "/dealer":
        setTitle("Dealer");
        break;
      case "/dealerspas":
        setTitle(dealerUsername);
        break;
      case "/dealers":
        setTitle("Admin | Dealers");
        break;
      case "/blacklist":
        setTitle("Admin | Blacklist")
        break;
      default:
        history.push('/');
        setTitle("SpaNET");
        break;
      }
  }, [title, location.pathname]);

  if ((RG === undefined || RG === '') && (userId === undefined || userId === 0) && location.pathname !== '/' && devices.length !== 0) {
    return (<div></div>)
  } else {
    return (
      <ThemeProvider theme={mdTheme}>
        <LoggedInUserContext.Provider value={{  }}>
          <Switch>
              <Route exact path='/' component={SignInPIN} />
              <Route exact path='/Admin' component={SignIn} />
              <Route exact path='/Dealer' component={SignInDealer} />
              <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="absolute" open={open}>
                  <Toolbar
                    sx={{
                      pr: '24px', // keep right padding when drawer closed
                    }}
                  >
                    <IconButton
                      edge="start"
                      color="inherit"
                      aria-label="open drawer"
                      onClick={toggleDrawer}
                      sx={{
                        marginRight: '36px',
                        ...(open && { display: 'none' }),
                      }}
                    >
                    <MenuIcon />
                    </IconButton>
                    <Typography
                      component="h1"
                      variant="h6"
                      color="inherit"
                      noWrap
                      sx={{ flexGrow: 1 }}
                    >
                      {title}
                    </Typography>
                    <IconButton color="inherit">
                      <Badge badgeContent={0} color="secondary">
                        <RefreshIcon onClick={() => userDeviceRfRefresh(selectedDevice)} />
                      </Badge>
                    </IconButton>
                    <Select
                        labelStyle={{ color: '#ff0000' }}
                        sx={{
                          boxShadow: 'none', 
                          color: "white",
                          '.MuiOutlinedInput-notchedOutline': {
                            border: 0
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            border: 0,
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'rgba(228, 219, 233, 0.25)',
                          },
                          '.MuiSvgIcon-root ': {
                            fill: "white !important",
                          },
                        }}
                        value={selectedDevice}
                        onChange={handleChangeDevice}
                      >
                      {devices.map((el,i) => (<MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>))}
                    </Select>
                    <IconButton color="inherit">
                      <Badge badgeContent={0} color="secondary">
                        <LogoutIcon onClick={handleLogout} />
                      </Badge>
                    </IconButton>
                  </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                  <Toolbar
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      px: [1],
                    }}
                  >
                    <Box
                    component="img"
                    sx={{
                    height: 30,
                    }}
                    alt="SpaNET Logo"
                    src={logo}
                    />
                    <IconButton onClick={toggleDrawer}>
                      <ChevronLeftIcon />
                    </IconButton>
                  </Toolbar>
                  <Divider />
                  <List component="nav">
                    { RE.split(',')[1] === '1' || RE.split(',')[1] === '2' ? mainListItemsHeatPump : mainListItems }
                    { permission === 1 ? <Divider sx={{ my: 1 }} /> : <div></div> }
                    { permission === 1 ? adminListItems : ''}
                    { permission === 1 || permission === 2 ? <Divider sx={{ my: 1 }} /> : <div></div> }
                    { permission === 1 || permission === 2 ? dealerListItems : ''}
                  </List>
                </Drawer>
                <Box
                  component="main"
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                  }}
                >
                  <Toolbar />
                  <MainRfContext.Provider value={{ permission, deviceName, R2, R3, R4, R5, R6, R7, R9, RA, RB, RC, RE, RG, selectedDevice, getUserDeviceRf, refreshDeviceRF, online, rssi, deviceType, snWifiRf }}>
                    <Route exact path="/Dashboard" component={Dashboard} />
                    <Route exact path="/Control" component={Control} />
                    <Route exact path="/History" component={History} />
                    <Route exact path="/EEPROM" component={EEPROM} />
                    <Route exact path="/Users" render={() => <Users {...{userId, setUserId}} />} />
                    <Route exact path="/Clients" component={Clients} />
                    <Route exact path="/Spas" component={Spas} />
                    <Route exact path="/OTA" component={OTA} />
                    <Route exact path="/Blacklist" component={Blacklist} />
                    <Route exact path="/HPDashboard" component={HPDashboardTemp} />
                    <Route exact path="/DealerSpas" render={() => <DealerSpas {...{deviceId, setDeviceId}} />} />
                    <Route exact path="/Dealers" component={DealerList} />
                  </MainRfContext.Provider>
                </Box>
              </Box>
            </Switch>
        </LoggedInUserContext.Provider>
      </ThemeProvider>
    );
  }
}

export default App;