import React, { useContext } from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Copyright from './copyright.component/copyright'
import HistoryHourMeter from './history.components/HistoryHourMeter';
import HistoryStartDate from './history.components/HistoryStartDate';
import HistoryVoltageLogs from './history.components/HistoryVoltageLogs';
import HistoryFaultLogs from './history.components/HistoryFaultLogs';
import HistorySNWifiRF from './history.components/HistorySNWifiRF';
import { MainRfContext } from '../context/MainRfContext';
import { MainContext } from '../context/MainContext';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

function HistoryContent() {
  const state = useContext(MainRfContext);
  if ((state.selectedDevice === 0)) {
    return (<Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
    <Stack sx={{ width: '100%' }} spacing={2}>
      <Alert variant="filled" severity="error">
        No spa associated with this account
      </Alert>
    </Stack>
  </Container>)
  } else {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <MainContext.Provider value={{ state }}>
          <Grid container spacing={3}>
            {/* Offline */}
            {state.online === false ? <Stack sx={{ width: '100%', pt: 2, pl: 3 }} spacing={2}>
                <Alert variant="filled" severity="error">
                  Spa is Offline
                </Alert>
              </Stack> : <div></div>}
            {/* Hour Meter */}  
            <Grid item xs={22} md={8} lg={6}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 200 }}>
                <HistoryHourMeter />
              </Paper>
            </Grid>
            {/* Start Date */}  
            <Grid item xs={22} md={8} lg={6}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 200 }}>
                <HistoryStartDate />
              </Paper>
            </Grid>
            {/* Voltage Logs */}  
            <Grid item xs={22} md={8} lg={6}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 500 }}>
                <HistoryVoltageLogs />
              </Paper>
            </Grid>
            {/* Fault Logs */}  
            <Grid item xs={22} md={8} lg={6}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
                <HistoryFaultLogs />
              </Paper>
            </Grid>
            {/* SN-WIFI-RF */}  
            <Grid item xs={22} md={8} lg={6}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
                <HistorySNWifiRF />
              </Paper>
            </Grid>
          </Grid>
        </MainContext.Provider>
        <Copyright sx={{ pt: 4 }} />
      </Container>
    );
  }
}

export default function History() {
  return <HistoryContent />;
}