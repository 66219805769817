import React, { useContext } from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Copyright from './copyright.component/copyright'
import ControlTouchpad from './control.components/ControlTouchpad';
import ControlBlower from './control.components/ControlBlower';
import ControlLight from './control.components/ControlLight';
import ControlRebootController from './control.components/ControlRebootController';
import ControlTestMode from './control.components/ControlTestMode';
import { MainRfContext } from '../context/MainRfContext';
import { MainContext } from '../context/MainContext';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

function ControlContent() {
  const state = useContext(MainRfContext);
  if ((state.selectedDevice === 0)) {
    return (<Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
    <Stack sx={{ width: '100%' }} spacing={2}>
      <Alert variant="filled" severity="error">
        No spa associated with this account
      </Alert>
    </Stack>
  </Container>)
  } else {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <MainContext.Provider value={{ state }}>
          <Grid container spacing={3}>
            {/* Offline */}
            {state.online === false ? <Stack sx={{ width: '100%', pt: 2, pl: 3 }} spacing={2}>
                <Alert variant="filled" severity="error">
                  Spa is Offline
                </Alert>
              </Stack> : <div></div>}
            {/* Touchpad */} 
            <Grid item xs={22} md={22} lg={22}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <ControlTouchpad />
              </Paper>
            </Grid>
            {/* Light */}  
            <Grid item xs={22} md={8} lg={8}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <ControlLight />
              </Paper>
            </Grid>
            {/* Blower */}  
            <Grid item xs={22} md={4} lg={4}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 537, }}>
                <ControlBlower />
              </Paper>
            </Grid>
            {/* Reboot Controller */}  
            <Grid item xs={22} md={8} lg={8}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', }}>
                <ControlRebootController />
              </Paper>
            </Grid>
          </Grid>
        </MainContext.Provider>
        <Copyright sx={{ pt: 4 }} />
      </Container>
    );
  }
}

export default function Control() {
  return <ControlContent />;
}