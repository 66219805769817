import React, {Component} from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

class DynamicSelect extends Component{
    constructor(props){
        super(props)
    }

    //On the change event for the select box pass the selected value back to the parent
    handleChange = (event) =>
    {
        var id = event.target.id;
        if (id === undefined) {
            id = this.props.id;
        }
        let selectedValue = event.target.value;
        this.props.onSelectChange(id, selectedValue);
    }

    render() {
        let arrayOfData = this.props.arrayOfData;
        let defaultValue = this.props.defaultValue;
        let name = this.props.name;
        let options = arrayOfData.map((data) =>
                <MenuItem  
                    key={data.id}
                    value={data.name}
                >
                    {data.name}
                </MenuItem >
            );

            return (
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    {/* <InputLabel id="demo-simple-select-label">{name}</InputLabel> */}
                        <Select onChange={this.handleChange} defaultValue={defaultValue}>
                            {options}
                        </Select>
                </FormControl>
            
        )
    }
}

export default DynamicSelect;