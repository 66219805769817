/* eslint-disable no-loop-func */
import React, { useContext } from 'react';
import Title from '../resources/Title';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { MainContext } from '../../context/MainContext';
import * as Constants from '../../components/api/constants'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Generate Order Data
function createData(id, property, state, possibleStates1, possibleStates2, possibleStates3, possibleStates4, possibleStates5, possibleStates6, possibleStates7, possibleStates8) {
  return { id, property, state, possibleStates1, possibleStates2, possibleStates3, possibleStates4, possibleStates5, possibleStates6, possibleStates7, possibleStates8 };
}

const buttonMinWidth = '20%';
let deviceId = 0;

export default function ControlPumpAndBlower() {
  const state = useContext(MainContext).state;
  deviceId = state.selectedDevice;
  let r4 = state.R4.split(',');
  let r5 = state.R5.split(',');
  let rg = state.RG.split(',');

  if (rg.length > 11 && rg[7] === "0-" && rg[8] === "0-" && rg[9] === "0-" && rg[10] === "0-" && rg[11] === "0-")
  {
      rg[8] = "1-1-014";
      rg[9] = "1-1-01";
      rg[10] = "1-1-01";
  }
  
  const rows = [
    createData(0, 'Circ Pump', 'None', '', '', ''),
    createData(1, 'Pump 1', 'None', '', '', '', '', '', '', ''),
    createData(2, 'Pump 2', 'None', '', '', '', '', '', '', '', '', ''),
    createData(3, 'Pump 3', 'None', '', '', '', '', '', '', '', '', ''),
    createData(4, 'Pump 4', 'None', '', '', '', '', '', '', '', '',''),
    createData(5, 'Sanitise', 'N/A', <Button size="small" variant="outlined" onClick={() => sendCommand('sanitise', '')}>Start/Stop</Button>),
  ];

  if (rg[7][0] === '1') {
    rows[0].possibleStates1 = <Button sx={{ width: buttonMinWidth }} size="small" variant="outlined" disabled={false} onClick={() => sendPumpCommand(-1, 'off')}>OFF</Button>;
    rows[0].possibleStates2 = <Button sx={{ width: buttonMinWidth }} size="small" variant="outlined" disabled={false} onClick={() => sendPumpCommand(-1, 'on')}>ON</Button>
    rows[0].possibleStates3 = <Button sx={{ width: buttonMinWidth }} size="small" variant="outlined" disabled={false} onClick={() => sendPumpCommand(-1, 'auto')}>AUTO</Button>
    
    if (r5[18] === '0') rows[0].state = "Off";
    if (r5[18] === '1') rows[0].state = "On";
    if (r5[18] === '2') rows[0].state = "High";
    if (r5[18] === '3') rows[0].state = "Low";
    if (r5[18] === '4') rows[0].state = "Auto";
    if (r5[18] === '5') rows[0].state = "Var";
    if (r5[18] === '6') rows[0].state = "Seq1";
    if (r5[18] === '7') rows[0].state = "Seq2";
    if (r5[18] === '8') rows[0].state = "Seq3";
  }

  if (rg[8][0] === '1') {
    if (r5[19] === '0') rows[1].state = "Off";
    if (r5[19] === '1') rows[1].state = "On";
    if (r5[19] === '3') rows[1].state = "High";
    if (r5[19] === '2') rows[1].state = "Low";
    if (r5[19] === '4') rows[1].state = "Auto";
    if (r5[19] === '5') rows[1].state = "Var";
    if (r5[19] === '6') rows[1].state = "Seq1";
    if (r5[19] === '7') rows[1].state = "Seq2";
    if (r5[19] === '8') rows[1].state = "Seq3";

    if (rg[8][1] === '3') {
      if (r4[28] === '0') rows[1].state = "Vari";
      if (r4[28] === '1') rows[1].state = "Seq1";
      if (r4[28] === '2') rows[1].state = "Seq2";
      if (r4[28] === '3') rows[1].state = "Seq3";
      if (r4[28] === '4') rows[1].state = "Off";
      if (r4[28] === '5') rows[1].state = "Auto";
      if (r4[28] === '6') rows[1].state = "Auto";
      if (r4[28] === '7') rows[1].state = "Auto";
    }

    let bt = 1;
    let text = '';
    let btn1Text = '';
    let btn2Text = '';
    let btn3Text = '';
    let btn4Text = '';
    let btn5Text = '';
    let btn6Text = '';
    for (let i = 4; (i < rg[8].length) && (rg[8][i] !== '\0'); i++, bt++) {
      if (rg[8][i] === '0') text = 'OFF';
      if (rg[8][i] === '1') text = 'ON';
      if (rg[8][i] === '2') text = 'HIGH'; 
      if (rg[8][i] === '3') text = 'LOW';  
      if (rg[8][i] === '4') text = 'AUTO'; 
      if (rg[8][i] === '5') text = 'VARI'; 
      if (rg[8][i] === '6') text = 'SEQ1'; 
      if (rg[8][i] === '7') text = 'SEQ2';
      if (rg[8][i] === '8') text = 'SEQ3';

      switch(bt) {
        case 1:
          btn1Text = text;
          rows[1].possibleStates1 = <Button sx={{ width: buttonMinWidth }} size="small" variant="outlined" disabled={false} onClick={() => sendPumpCommand(1, btn1Text)}>{text}</Button> 
          break;
        case 2:
          btn2Text = text;
          rows[1].possibleStates2 = <Button sx={{ width: buttonMinWidth }} size="small" variant="outlined" disabled={false} onClick={() => sendPumpCommand(1, btn2Text)}>{text}</Button> 
          break;
        case 3:
          btn3Text = text;
          rows[1].possibleStates3 = <Button sx={{ width: buttonMinWidth }} size="small" variant="outlined" disabled={false} onClick={() => sendPumpCommand(1, btn3Text)}>{text}</Button> 
          break;
        case 4:
          btn4Text = text;
          rows[1].possibleStates4 = <Button sx={{ width: buttonMinWidth }} size="small" variant="outlined" disabled={false} onClick={() => sendPumpCommand(1, btn4Text)}>{text}</Button> 
          break;
        case 5:
          btn5Text = text;
          rows[1].possibleStates5 = <Button sx={{ width: buttonMinWidth }} size="small" variant="outlined" disabled={false} onClick={() => sendPumpCommand(1, btn5Text)}>{text}</Button> 
          break;
        case 6:
          btn6Text = text;
          rows[1].possibleStates6 = <Button sx={{ width: buttonMinWidth }} size="small" variant="outlined" disabled={false} onClick={() => sendPumpCommand(1, btn6Text)}>{text}</Button> 
          break;
        default:
      }
    }
  } 

  if (rg[9][0] === '1') {
    if (r5[20] === '0') rows[2].state = "Off";
    if (r5[20] === '1') rows[2].state = "On";
    if (r5[20] === '2') rows[2].state = "High";
    if (r5[20] === '3') rows[2].state = "Low";
    if (r5[20] === '4') rows[2].state = "Auto";
    if (r5[20] === '5') rows[2].state = "Var";
    if (r5[20] === '6') rows[2].state = "Seq1";
    if (r5[20] === '7') rows[2].state = "Seq2";
    if (r5[20] === '8') rows[2].state = "Seq3";

    let bt = 1;
    let text = '';
    let btn1Text = '';
    let btn2Text = '';
    let btn3Text = '';
    let btn4Text = '';
    let btn5Text = '';
    let btn6Text = '';
    for (let i = 4; (i < rg[9].length) && (rg[9][i] !== '\0'); i++, bt++) {
      if (rg[9][i] === '0') text = 'OFF';
      if (rg[9][i] === '1') text = 'ON';
      if (rg[9][i] === '2') text = 'HIGH'; 
      if (rg[9][i] === '3') text = 'LOW';  
      if (rg[9][i] === '4') text = 'AUTO'; 
      if (rg[9][i] === '5') text = 'VARI'; 
      if (rg[9][i] === '6') text = 'SEQ1'; 
      if (rg[9][i] === '7') text = 'SEQ2';
      if (rg[9][i] === '8') text = 'SEQ3';

      switch(bt) {
        case 1:
          btn1Text = text;
          rows[2].possibleStates1 = <Button sx={{ width: buttonMinWidth }} size="small" variant="outlined" disabled={false} onClick={() => sendPumpCommand(2, btn1Text)}>{text}</Button> 
          break;
        case 2:
          btn2Text = text;
          rows[2].possibleStates2 = <Button sx={{ width: buttonMinWidth }} size="small" variant="outlined" disabled={false} onClick={() => sendPumpCommand(2, btn2Text)}>{text}</Button> 
          break;
        case 3:
          btn3Text = text;
          rows[2].possibleStates3 = <Button sx={{ width: buttonMinWidth }} size="small" variant="outlined" disabled={false} onClick={() => sendPumpCommand(2, btn3Text)}>{text}</Button> 
          break;
        case 4:
          btn4Text = text;
          rows[2].possibleStates4 = <Button sx={{ width: buttonMinWidth }} size="small" variant="outlined" disabled={false} onClick={() => sendPumpCommand(2, btn4Text)}>{text}</Button> 
          break;
        case 5:
          btn5Text = text;
          rows[2].possibleStates5 = <Button sx={{ width: buttonMinWidth }} size="small" variant="outlined" disabled={false} onClick={() => sendPumpCommand(2, btn5Text)}>{text}</Button> 
          break;
        case 6:
          btn6Text = text;
          rows[2].possibleStates6 = <Button sx={{ width: buttonMinWidth }} size="small" variant="outlined" disabled={false} onClick={() => sendPumpCommand(2, btn6Text)}>{text}</Button> 
          break;
        default:
      }
    }
  } 
  
  if (rg[10][0] === '1') {
    if (r5[21] === '0') rows[3].state = "Off";
    if (r5[21] === '1') rows[3].state = "On";
    if (r5[21] === '2') rows[3].state = "High";
    if (r5[21] === '3') rows[3].state = "Low";
    if (r5[21] === '4') rows[3].state = "Auto";
    if (r5[21] === '5') rows[3].state = "Var";
    if (r5[21] === '6') rows[3].state = "Seq1";
    if (r5[21] === '7') rows[3].state = "Seq2";
    if (r5[21] === '8') rows[3].state = "Seq3";

    let bt = 1;
    let text = '';
    let btn1Text = '';
    let btn2Text = '';
    let btn3Text = '';
    let btn4Text = '';
    let btn5Text = '';
    let btn6Text = '';
    for (let i = 4; (i < rg[10].length) && (rg[10][i] !== '\0'); i++, bt++) {
      if (rg[10][i] === '0') text = 'OFF';
      if (rg[10][i] === '1') text = 'ON';
      if (rg[10][i] === '2') text = 'HIGH'; 
      if (rg[10][i] === '3') text = 'LOW';  
      if (rg[10][i] === '4') text = 'AUTO'; 
      if (rg[10][i] === '5') text = 'VARI'; 
      if (rg[10][i] === '6') text = 'SEQ1'; 
      if (rg[10][i] === '7') text = 'SEQ2';
      if (rg[10][i] === '8') text = 'SEQ3';

      switch(bt) {
        case 1:
          btn1Text = text;
          rows[3].possibleStates1 = <Button sx={{ width: buttonMinWidth }} size="small" variant="outlined" disabled={false} onClick={() => sendPumpCommand(3, btn1Text)}>{text}</Button> 
          break;
        case 2:
          btn2Text = text;
          rows[3].possibleStates2 = <Button sx={{ width: buttonMinWidth }} size="small" variant="outlined" disabled={false} onClick={() => sendPumpCommand(3, btn2Text)}>{text}</Button> 
          break;
        case 3:
          btn3Text = text;
          rows[3].possibleStates3 = <Button sx={{ width: buttonMinWidth }} size="small" variant="outlined" disabled={false} onClick={() => sendPumpCommand(3, btn3Text)}>{text}</Button> 
          break;
        case 4:
          btn4Text = text;
          rows[3].possibleStates4 = <Button sx={{ width: buttonMinWidth }} size="small" variant="outlined" disabled={false} onClick={() => sendPumpCommand(3, btn4Text)}>{text}</Button> 
          break;
        case 5:
          btn5Text = text;
          rows[3].possibleStates5 = <Button sx={{ width: buttonMinWidth }} size="small" variant="outlined" disabled={false} onClick={() => sendPumpCommand(3, btn5Text)}>{text}</Button> 
          break;
        case 6:
          btn6Text = text;
          rows[3].possibleStates6 = <Button sx={{ width: buttonMinWidth }} size="small" variant="outlined" disabled={false} onClick={() => sendPumpCommand(3, btn6Text)}>{text}</Button> 
          break;
        default:
      }
    }
  } 
  
  if (rg[11][0] === '1') {
    if (r5[22] === '0') rows[4].state = "Off";
    if (r5[22] === '1') rows[4].state = "On";
    if (r5[22] === '2') rows[4].state = "High";
    if (r5[22] === '3') rows[4].state = "Low";
    if (r5[22] === '4') rows[4].state = "Auto";
    if (r5[22] === '5') rows[4].state = "Var";
    if (r5[22] === '6') rows[4].state = "Seq1";
    if (r5[22] === '7') rows[4].state = "Seq2";
    if (r5[22] === '8') rows[4].state = "Seq3";

    let bt = 1;
    let text = '';
    let btn1Text = '';
    let btn2Text = '';
    let btn3Text = '';
    let btn4Text = '';
    let btn5Text = '';
    let btn6Text = '';
    for (let i = 4; (i < rg[11].length) && (rg[11][i] !== '\0'); i++, bt++) {
      if (rg[11][i] === '0') text = 'OFF';
      if (rg[11][i] === '1') text = 'ON';
      if (rg[11][i] === '2') text = 'HIGH'; 
      if (rg[11][i] === '3') text = 'LOW';  
      if (rg[11][i] === '4') text = 'AUTO'; 
      if (rg[11][i] === '5') text = 'VARI'; 
      if (rg[11][i] === '6') text = 'SEQ1'; 
      if (rg[11][i] === '7') text = 'SEQ2';
      if (rg[11][i] === '8') text = 'SEQ3';

      switch(bt) {
        case 1:
          btn1Text = text;
          rows[4].possibleStates1 = <Button sx={{ width: buttonMinWidth }} size="small" variant="outlined" disabled={false} onClick={() => sendPumpCommand(4, btn1Text)}>{text}</Button> 
          break;
        case 2:
          btn2Text = text;
          rows[4].possibleStates2 = <Button sx={{ width: buttonMinWidth }} size="small" variant="outlined" disabled={false} onClick={() => sendPumpCommand(4, btn2Text)}>{text}</Button> 
          break;
        case 3:
          btn3Text = text;
          rows[4].possibleStates3 = <Button sx={{ width: buttonMinWidth }} size="small" variant="outlined" disabled={false} onClick={() => sendPumpCommand(4, btn3Text)}>{text}</Button> 
          break;
        case 4:
          btn4Text = text;
          rows[4].possibleStates4 = <Button sx={{ width: buttonMinWidth }} size="small" variant="outlined" disabled={false} onClick={() => sendPumpCommand(4, btn4Text)}>{text}</Button> 
          break;
        case 5:
          btn5Text = text;
          rows[4].possibleStates5 = <Button sx={{ width: buttonMinWidth }} size="small" variant="outlined" disabled={false} onClick={() => sendPumpCommand(4, btn5Text)}>{text}</Button> 
          break;
        case 6:
          btn6Text = text;
          rows[4].possibleStates6 = <Button sx={{ width: buttonMinWidth }} size="small" variant="outlined" disabled={false} onClick={() => sendPumpCommand(4, btn6Text)}>{text}</Button> 
          break;
        default:
      }
    }
  }

  if (r5[16] === '0') {
    rows[5].state = "Off";
  } else {
    let wcln = ['0', '0', ':', '0', '0'];
    let len = r5[16].Length;
  
    let TempString = r5[16]
  
    if (len === 1)
    {
        wcln[4] = TempString[0];
    }
    if (len === 2)
    {
        wcln[3] = TempString[0];
        wcln[4] = TempString[1];
    }
    if (len === 3)
    {
        wcln[1] = TempString[0];
        wcln[3] = TempString[1];
        wcln[4] = TempString[2];
    }
    if (len === 4)
    {
        wcln[0] = TempString[0];
        wcln[1] = TempString[1];
        wcln[3] = TempString[2];
        wcln[4] = TempString[3];
    }
    rows[5].state = wcln.toString().split(',').join('');
  }

  const sendCommand = (command, value) => {
    const requestOptions = { 
      method: 'POST',
      headers: { 'Accept': 'application/json', 
                 'Content-Type': 'application/json', }, 
      body: JSON.stringify({ DeviceId: deviceId,
                              Command: command, 
                              Value: value }),  
      credentials: "include"
    };
  
    fetch(Constants.baseURL + Constants.apiSendCommand, requestOptions) 
        .then((response) => { 
            if (response.ok) {
              toast.success('Command sent successfully. Refreshing...', {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
                
              setTimeout(function() {
                state.refreshDeviceRF(deviceId);
              }, 6000);

              setTimeout(function() {
                state.getUserDeviceRf(deviceId);
              }, 12000);
            } else {
              toast.error('Send failed. Please try again', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            }
        }).catch(error => { console.log('request failed', error); });
  }

  const sendPumpCommand = (pumpNumber, mode) => {
    const requestOptions = { 
      method: 'POST',
      headers: { 'Accept': 'application/json', 
                 'Content-Type': 'application/json', }, 
      body: JSON.stringify({ DeviceId: deviceId,
        PumpNumber: pumpNumber, 
        Mode: mode }),  
      credentials: "include"
    };
  
    fetch(Constants.baseURL + Constants.apiSendPumpCommand, requestOptions) 
        .then((response) => { 
            if (response.ok) {
              toast.success('Command sent successfully. Refreshing...', {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
                
              setTimeout(function() {
                state.refreshDeviceRF(deviceId);
              }, 6000);

              setTimeout(function() {
                state.getUserDeviceRf(deviceId);
              }, 12000);
            } else {
              toast.error('Send failed. Please try again', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            }
        }).catch(error => { console.log('request failed', error); });
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <Title>Touchpad</Title>
      <Table size="small" >
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell><b>State</b></TableCell>
            <TableCell style={{textAlign: 'center'}}><b>Possible states</b></TableCell>
            <TableCell> </TableCell>
            <TableCell> </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.property}</TableCell>
              <TableCell>{row.state}</TableCell>
              <TableCell style={{textAlign: 'center'}}>{row.possibleStates1}</TableCell>
              <TableCell style={{textAlign: 'center'}}>{row.possibleStates2}</TableCell>
              <TableCell style={{textAlign: 'center'}}>{row.possibleStates3}</TableCell>
              <TableCell style={{textAlign: 'center'}}>{row.possibleStates4}</TableCell>
              <TableCell style={{textAlign: 'center'}}>{row.possibleStates5}</TableCell>
              <TableCell style={{textAlign: 'center'}}>{row.possibleStates6}</TableCell>
              <TableCell style={{textAlign: 'center'}}>{row.possibleStates7}</TableCell>
              <TableCell style={{textAlign: 'center'}}>{row.possibleStates8}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}