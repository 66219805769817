import React, { useContext, useRef } from 'react';
import Title from '../resources/Title';
import { MainContext } from '../../context/MainContext'
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { DataGrid } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as Constants from '../api/constants' 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

function createData(status, id, email, firstName, lastName, spaName, macAddress, isOnline, deleteSpa) {
  return { status, id, email, firstName, lastName, spaName, macAddress, isOnline, deleteSpa };
}

let rows = [];

const cellRenderer = (params) => {
  return params.value;
};

export default function DealerAssociatedSpaList(props) {
  const spaList = useContext(MainContext);
  
  const parent = props;
  const [open, setOpen] = React.useState(false);
  const [macAddress, setMacAddress] = React.useState(rows);
  const [spaId, setSpaId] = React.useState(0);

  let columns = [
    { field: 'status', headerName: '', width: 10, renderCell: cellRenderer },
    { field: 'id', headerName: 'ID', minWidth: 40 },
    { field: 'email', headerName: 'Email', flex: 2, minWidth: 250 },
    { field: 'firstName', headerName: 'First Name', flex: 1, minWidth: 120 },
    { field: 'lastName', headerName: 'Last Name', flex: 1, minWidth: 120 },
    { field: 'spaName', headerName: 'Spa Name', flex: 1, minWidth: 120 },
    { field: 'macAddress', headerName: 'MAC Address', flex: 1, minWidth: 150 },
    { field: 'isOnline', headerName: 'Online', flex: 1, minWidth: 100 },
    { field: 'deleteSpa', headerName: 'Unassign', flex: 1, minWidth: 100, renderCell: cellRenderer },
  ];
 
  rows = [];
  
  const tfName = useRef('');
  const tfEmail = useRef('');
  const tfMacAddress = useRef('');

  const [data, setData] = React.useState(rows);
  React.useEffect(() => { 
    [...spaList].forEach(element => {
      rows.push(createData(element.status === 0 ? <CheckCircleIcon style={{ color: 'green' }} /> : <CancelIcon style={{ color: 'red' }} />, 
      element.id, element.email, element.firstName, element.lastName, element.spaName, element.macAddress, element.isOnline ? 'Online' : 'Offline',
       <Button variant="contained" color="error" type="submit" onClick={() => {handleDeleteClickOpen(element.id, element.macAddress)}}>Unassign</Button>))
    });

    setData(rows)
  }, [spaList])

  const handleSearch = () => {
    parent.getSpasList(tfName.current.value, tfEmail.current.value, tfMacAddress.current.value);
  };

  const onChangeHandler = (ev) => {
    if (tfName.current.value === '' && tfEmail.current.value === '' && tfMacAddress.current.value === '' &&
    ev.key !== 'Enter') {
      parent.getSpasList(tfName.current.value, tfEmail.current.value, tfMacAddress.current.value);
    }
  }

  const handleDeleteClickOpen = (id, macAddress) => {
    setSpaId(id);
    setMacAddress(macAddress);
    setOpen(true);
  };
  const handleDeleteCloseDialog = () => {
    setOpen(false);
  }

  const handleDeleteClose = () => {
    setOpen(false);

    const requestOptions = {
      method: 'DELETE', 
      credentials: "include"
    };

    const parameters = new URLSearchParams({
      id: spaId,
      macAddress: macAddress,
    });
    
    fetch(Constants.baseURL + Constants.apiDeleteDealerSpa + '?' + parameters, requestOptions) 
      .then(response => { 
          if (!response.ok && response.status !== 404) {
              this.props.history.push('/');
          }
          
          if (response.ok) {
            parent.getSpasList();
            toast.success('Spa unassigned successfully', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });
          } else {
            toast.error('Error. Please try again', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
      .catch(error => { console.log('request failed', error); }); 
  };

  return (
    <React.Fragment>
      <ToastContainer />
        <Stack direction="row" spacing={1} justifyContent='space-between'>
          <Title align='left'>Assigned Spas</Title>
          <Stack direction="row" justifyContent={"flex-end"} spacing={1}>
          <TextField id="tfName" inputRef={tfName} label="Name" 
              variant="outlined" size="small" direction="row" 
              onKeyDown={(ev) => {
                if (ev.key === 'Enter') {
                  handleSearch()
                  ev.preventDefault();
                } 
              }}
              onChange={(ev) => {onChangeHandler(ev)}}
              justifyContent={"flex-end"} style={{ width: '30%' }} />
            <TextField id="tfEmail" inputRef={tfEmail} label="Email" 
              variant="outlined" size="small" direction="row" 
              onKeyDown={(ev) => {
                if (ev.key === 'Enter') {
                  handleSearch()
                  ev.preventDefault();
                } 
              }}
              onChange={(ev) => {onChangeHandler(ev)}}
              justifyContent={"flex-end"} style={{ width: '30%' }} />
            <TextField id="tfMacAddress" inputRef={tfMacAddress} label="MAC Address" 
              variant="outlined" size="small" direction="row" 
              onKeyDown={(ev) => {
                if (ev.key === 'Enter') {
                  handleSearch()
                  ev.preventDefault();
                }
              }}
              onChange={(ev) => {onChangeHandler(ev)}}
              justifyContent={"flex-end"} style={{ width: '30%' }} />
              <Button variant="contained" type="submit" onClick={() => {handleSearch()}}>Search</Button>
          </Stack>
        </Stack>
        <div style={{ height: 800, width: '100%', 'padding-top': '10px' }}>
          <DataGrid sx={{
                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                  outline: "none !important",
                },
            }}
            rows={data}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[5]}
            checkboxSelection={false}
            isRowSelectable={false}
            disableSelectionOnClick 
          />
          <Dialog
            open={open}
            keepMounted
            onClose={handleDeleteCloseDialog}
            aria-describedby="alert-dialog-slide-description"
          >
          <DialogTitle>{"Unassign Spa"}</DialogTitle>
          <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Are you sure you want to unassign this spa: {macAddress}?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDeleteCloseDialog}>No</Button>
              <Button onClick={handleDeleteClose}>Yes</Button>
            </DialogActions>
          </Dialog>
        </div>
    </React.Fragment>
  );
}