import React, { useContext } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Title from '../resources/Title';
import { makeStyles } from '@mui/styles';
import { MainContext } from '../../context/MainContext';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import FluorescentOutlinedIcon from '@mui/icons-material/FluorescentOutlined';
import BubbleChartOutlinedIcon from '@mui/icons-material/BubbleChartOutlined';

// Generate Order Data
function createData(id, icon, property, value) {
  return { id, icon, property, value };
}

const useStyles = makeStyles({
  combinedCell: {
    alignItems: 'center',
  },
  gap: {
    marginLeft: '20px', 
  },
  rightAlign: {
    textAlign: 'right',
  },
});

const rows = [
  createData(0, <FluorescentOutlinedIcon/>, 'PA', 'N/A',),
  createData(1, <FluorescentOutlinedIcon/>, 'PB', 'N/A',),
  createData(2, <FluorescentOutlinedIcon/>, 'PC', 'N/A',),
  createData(3, <FluorescentOutlinedIcon/>, 'PD', 'N/A',),
  createData(4, <LightbulbOutlinedIcon/>, 'Light', 'N/A',),
  createData(5, <BubbleChartOutlinedIcon/>, 'Blower', 'N/A',),
];

export default function DashboardButtonLEDs() {
  const state = useContext(MainContext).state;
  var r5 = state.R5.split(',');
  rows[0].value = r5[6] === '1' ? 'ON' : 'OFF'
  rows[1].value = r5[7] === '1' ? 'ON' : 'OFF'
  rows[2].value = r5[9] === '1' ? 'ON' : 'OFF'
  rows[3].value = r5[10] === '1' ? 'ON' : 'OFF'
  rows[4].value = r5[15] === '1' ? 'ON' : 'OFF'
  rows[5].value = r5[8] === '1' ? 'ON' : 'OFF'

  const classes = useStyles();

  return (
    <React.Fragment>
      <Title>Button LEDs</Title>
      <Table size="small">
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell colSpan={2} className={classes.combinedCell}>
                {row.icon}
                <span className={classes.gap}></span>
                {row.property}
              </TableCell>
              <TableCell align="right" className={classes.rightAlign}>
                {row.value}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}