import React, { useContext } from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../resources/Title';
import { MainContext } from '../../context/MainContext';
import { makeStyles } from '@mui/styles';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';

// Generate Order Data
function createData(id, icon, property, value) {
  return { id, icon, property, value };
}

const useStyles = makeStyles({
  combinedCell: {
    alignItems: 'center',
  },
  gap: {
    marginLeft: '20px', 
  },
  rightAlign: {
    textAlign: 'right',
  },
});

const rows = [
  createData(0, <TuneOutlinedIcon/>, '1', 'N/A',),
  createData(1, <TuneOutlinedIcon/>, '2', 'N/A',),
  createData(2, <TuneOutlinedIcon/>, '3', 'N/A',),
  createData(3, <TuneOutlinedIcon/>, '4', 'N/A',),
  createData(4, <TuneOutlinedIcon/>, '5', 'N/A',),
  createData(5, <TuneOutlinedIcon/>, '6', 'N/A',),
  createData(6, <TuneOutlinedIcon/>, 'Phases', 'N/A',),
];

export default function DashboardDipSwitches() {
  const state = useContext(MainContext).state;

  var r3 = state.R3.split(',');
  rows[0].value = r3[10] === '1' ? 'ON' : 'OFF'
  rows[1].value = r3[11] === '1' ? 'ON' : 'OFF'
  rows[2].value = r3[12] === '1' ? 'ON' : 'OFF'
  rows[3].value = r3[13] === '1' ? 'ON' : 'OFF'
  rows[4].value = r3[14] === '1' ? 'ON' : 'OFF'
  rows[5].value = r3[15] === '1' ? 'ON' : 'OFF'
  rows[6].value = r3[2]

  const classes = useStyles();

  return (
    <React.Fragment>
      <Title>Dip Switches</Title>
      <Table size="small">
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell colSpan={2} className={classes.combinedCell}>
                {row.icon}
                <span className={classes.gap}></span>
                {row.property}
              </TableCell>
              <TableCell align="right" className={classes.rightAlign}>
                {row.value}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}