import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import { MainContext } from '../context/MainContext';

export default function LoadingSpinner() {
  const show = React.useContext(MainContext)
  return (
    <div>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={show}>
        <Paper sx={{ p: 1, display: 'flex', flexDirection: 'column', boxShadow: "none", borderRadius: '30px' }}>
          <CircularProgress size="1.5rem" />
        </Paper>
      </Backdrop>
    </div>
  );
}