import React, { useContext } from 'react';
import Typography from '@mui/material/Typography';
import Title from '../resources/Title';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { MainContext } from '../../context/MainContext';
import * as Constants from '../../components/api/constants'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const buttonMinWidth = 120;
let deviceId = 0;
const { useState } = React;

export default function ControlTestMode() {
  const state = useContext(MainContext).state;
  deviceId = state.selectedDevice;

  const sendCommand = (command, value) => {
    const requestOptions = { 
      method: 'POST',
      headers: { 'Accept': 'application/json', 
                 'Content-Type': 'application/json', }, 
      body: JSON.stringify({ DeviceId: deviceId,
                              Command: command, 
                              Value: value }),  
      credentials: "include"
    };
  
    fetch(Constants.baseURL + Constants.apiSendCommand, requestOptions) 
        .then((response) => { 
            if (response.ok) {
              toast.success('Command sent successfully. Refreshing...', {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
                
              setTimeout(function() {
                state.refreshDeviceRF(deviceId);
              }, 6000);

              setTimeout(function() {
                state.getUserDeviceRf(deviceId);
              }, 12000);
            } else {
              toast.error('Send failed. Please try again', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            }
        }).catch(error => { console.log('request failed', error); });
  }

  const onClickListener = (id) => {
    if (id === 'CircOn') sendCommand('CircOn', '');
    if (id === 'CircOff') sendCommand('CircOff', '');
    if (id === 'O3On') sendCommand('O3On', '');
    if (id === 'Htr1H') sendCommand('Htr1H');
    if (id === 'Htr1L') sendCommand('Htr1L');
    if (id === 'Htr1Off') sendCommand('Htr1Off');
    if (id === 'Htr2H') sendCommand('Htr2H');
    if (id === 'Htr2L') sendCommand('Htr2L');
    if (id === 'Htr2Off') sendCommand('Htr2Off');
    if (id === 'TestMode') sendCommand('TestMode');
    if (id === 'Reboot') sendCommand('Reboot');
    if (id === 'VsbPcb') sendCommand('VsbPcb');
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <Title>Test Mode</Title>
      <Typography color="text.secondary" sx={{ flex: 1, textAlign: 'left' }}>
      Set controller to test mode to use the following:
      </Typography>

      <Container maxWidth="lg" sx={{ mt: 2 }} >
        <Grid container spacing={3}>
          <Grid item> 
            <Button sx={{ minWidth: buttonMinWidth }} variant="outlined" onClick={() => onClickListener('CircOn')}>Circ ON</Button>
          </Grid>
          <Grid item>
          <Button sx={{ minWidth: buttonMinWidth }} variant="outlined" onClick={() => onClickListener('CircOff')}>Circ OFF</Button>
          </Grid>
          <Grid item> 
            <Button sx={{ minWidth: buttonMinWidth }} variant="outlined" onClick={() => onClickListener('O3On')}>O3 ON</Button>
          </Grid>
          <Grid item>
          <Button sx={{ minWidth: buttonMinWidth }} variant="outlined" onClick={() => onClickListener('O3Off')}>O3 OFF</Button>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="lg" sx={{ mt: 2 }} >
        <Grid container spacing={3} align='center' justify='center' alignItems='center'>
        <Grid item> 
          <Typography color="text.secondary" sx={{ flex: 1, textAlign: 'left' }}>
            Vari:
          </Typography>
          </Grid>
          <Grid item> 
            <Button sx={{ minWidth: buttonMinWidth }} variant="outlined" onClick={() => onClickListener('vari80')}>80</Button>
          </Grid>
          <Grid item>
            <Button sx={{ minWidth: buttonMinWidth }} variant="outlined" onClick={() => onClickListener('vari40')}>40</Button>
          </Grid>
          <Grid item> 
            <Button sx={{ minWidth: buttonMinWidth }} variant="outlined" onClick={() => onClickListener('variOff')}>OFF</Button>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="lg" sx={{ mt: 2 }} >
        <Grid container spacing={3}>
          <Grid item> 
            <Button sx={{ minWidth: buttonMinWidth }} variant="outlined" onClick={() => onClickListener('Htr1Off')}>Htr 1 H</Button>
          </Grid>
          <Grid item>
            <Button sx={{ minWidth: buttonMinWidth }} variant="outlined" onClick={() => onClickListener('Htr1Off')}>Htr 1 L</Button>
          </Grid>
          <Grid item>
            <Button sx={{ minWidth: buttonMinWidth }} variant="outlined" onClick={() => onClickListener('Htr1Off')}>Htr 1 OFF</Button>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="lg" sx={{ mt: 2 }} >
        <Grid container spacing={3}>
          <Grid item> 
            <Button sx={{ minWidth: buttonMinWidth }} variant="outlined" onClick={() => onClickListener('Htr2H')}>Htr 2 H</Button>
          </Grid>
          <Grid item>
            <Button sx={{ minWidth: buttonMinWidth }} variant="outlined" onClick={() => onClickListener('Htr2L')}>Htr 2 L</Button>
          </Grid>
          <Grid item>
            <Button sx={{ minWidth: buttonMinWidth }} variant="outlined" onClick={() => onClickListener('Htr2Off')}>Htr 2 OFF</Button>
          </Grid>
        </Grid>
      </Container>

      <Button variant="contained" sx={{ mt: 2, width: '52%' }} onClick={() => onClickListener('TestMode')}>Test Mode</Button>
      <Button variant="outlined" sx={{ mt: 2, width: '52%' }} onClick={() => onClickListener('VsbPcb')}>VSP PCB</Button>
      <Button variant="contained" sx={{ mt: 2, width: '52%' }} onClick={() => onClickListener('Reboot')}>Reboot</Button>

    </React.Fragment>
  );
}