import React, { useContext } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Title from '../resources/Title';
import { MainContext } from '../../context/MainContext';

// Generate Order Data
function createData(id, property, value) {
  return { id, property, value };
}

const rows = [
  createData(0, 'Mode', 'N/A',),
  createData(1, 'Current step', 'N/A',),
  createData(2, 'Reset state', 'N/A',),
  createData(3, 'Control temp - measured', 'N/A',),
  createData(4, 'Control temp - target', 'N/A',)
];

export default function HPDashboardEEV() {
  const state = useContext(MainContext).state;
  var re = state.RE.split(',');

  if (re[41] === '0') {
    rows[0].value = 'Auto';
  } else {
    if (re[41] === '1') rows[0].value = 'Manual';
    if (re[41] === '2') rows[0].value = 'Disabled';
  }

  rows[1].value = re[43];

  if (re[44] === '0') rows[2].value = 'Starting';
  if (re[44] === '1') rows[2].value = 'Closing';
  if (re[44] === '2') rows[2].value = 'Close pause';
  if (re[44] === '3') rows[2].value = 'Start step';
  if (re[44] === '4') rows[2].value = 'Start pause';
  if (re[44] === '5') rows[2].value = '';

  if ((re[53]/10.0).toFixed(1) !== '0.0') {
    rows[3].value = (re[53]/10.0).toFixed(1) + '°C';
  }

  rows[4].value = (re[42]/10.0).toFixed(1) + '°C';

  return (
    <React.Fragment>
      <Title>EEV</Title>
      <Table size="small">
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.property}</TableCell>
              <TableCell>{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}