import React, { useContext } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Title from '../resources/Title';
import { MainContext } from '../../context/MainContext';

// Generate Order Data
function createData(id, property, value, type) {
  return { id, property, value, type };
}

const rows = [
  createData(0, '1', '...', 'EEV'),
  createData(1, '2', '...', 'EVI'),
  createData(2, '3', '...', 'Pump'),
  createData(3, '4', '...', ''),
  createData(4, '5', '...', ''),
  createData(5, '6', '...', '')
];

export default function HPDashboardDips() {
  const state = useContext(MainContext).state;
  var re = state.RE.split(',');

  if ((re[63] & 0x01) === 0x01) {
    rows[0].value = 'On';
  } else {
    rows[0].value = 'Off';
  }

  if ((re[63] & 0x02) === 0x02) {
    rows[1].value = 'On';
  } else {
    rows[1].value = 'Off';
  }

  if ((re[63] & 0x04) === 0x04) {
    rows[2].value = 'On';
  } else {
    rows[2].value = 'Off';
  }

  // if (re[63] === '8') {
  //   rows[3].value = 'On';
  // } else {
  //   rows[3].value = 'Off';
  // }

  // if (re[63] === '16') {
  //   rows[4].value = 'On';
  // } else {
  //   rows[4].value = 'Off';
  // }

  // if (re[63] === '32') {
  //   rows[5].value = 'On';
  // } else {
  //   rows[5].value = 'Off';
  // }
  
  return (
    <React.Fragment>
      <Title>Dips</Title>
      <Table size="small">
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.property}</TableCell>
              <TableCell>{row.value}</TableCell>
              <TableCell>{row.type}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}