import React, { useContext } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../resources/Title';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { MainContext } from '../../context/MainContext';

// Generate Order Data
function createData(id, property, value) {
  return { id, property, value };
}

function toHoursAndMinutes(totalMinutes) {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return `${padToTwoDigits(hours)}:${padToTwoDigits(minutes)}`;
}

function padToTwoDigits(num) {
  return num.toString().padStart(2, '0');
}

const GeneralRows = [
  createData(0, 'Water detected', 'N/A'),
  createData(1, 'Prime control', 'N/A'),
  createData(2, 'T.Out', 'N/A'),
  createData(3, 'T/P 1', 'N/A'),
  createData(4, 'In use', 'N/A'),
  createData(5, 'Fault', 'N/A')
];

const FiltrationControlRows = [
  createData(0, 'Awake mins left', 'N/A'),
  createData(1, 'Pump total run time', 'N/A'),
  createData(2, 'Pump required time', 'N/A'),
  createData(3, 'Filtering', 'N/A'),
];

const ThermalControlRows = [
  createData(0, 'Status', 'N/A'),
  createData(1, 'Pause timer', 'N/A'),
  createData(2, 'Pump idle timer', 'N/A'),
  createData(3, 'Pump run timer', 'N/A'),
  createData(4, 'Adaptive hysteresis', 'N/A'),
  createData(5, 'PSAV offset', 'N/A'),
];

const RefrigerationControlRows = [
  createData(0, 'Status', 'N/A'),
  createData(1, 'Mode', 'N/A'),
  createData(2, 'Compressor run timer', 'N/A'),
  createData(3, 'Sequence timer', 'N/A'),
];

const DefrostControlTimerslRows = [
  createData(0, 'Low temperature', 'N/A'),
  createData(1, 'Defrost cycle run', 'N/A'),
  createData(2, 'Condenser element', 'N/A'),
  createData(3, 'Compressor element', 'N/A'),
];

const ProtectionTimersRows = [
  createData(0, 'Low temp (P.LOW)', 'N/A'),
  createData(1, 'Freeze (P.FRE)', 'N/A')
];

export default function HPDashboardControl() {
  const state = useContext(MainContext).state;
  var r2 = state.R2.split(',');
  var r3 = state.R3.split(',');
  var r4 = state.R4.split(',');
  var re = state.RE.split(',');
  
  if (r2[14] === '0') GeneralRows[0].value = 'No';
  if (r2[14] === '1') GeneralRows[0].value = 'Yes';

  if (r4[12] === '0') GeneralRows[1].value = 'Standby';
  if (r4[12] === '1') GeneralRows[1].value = 'Priming';

  if (r2[19] !== undefined) {
    GeneralRows[2].value = r2[19].toHHMMSS();
  }

  if (r4[21] === '0') GeneralRows[3].value = 'SV-2T';
  if (r4[21] === '1') GeneralRows[3].value = 'SV-3T';
  if (r4[21] === '2') GeneralRows[3].value = 'SV-4T';
  if (r4[21] === '3') GeneralRows[3].value = 'SV-4T';
  if (r4[21] === '4') GeneralRows[3].value = 'SV-RT';
  if (r4[21] === '5') GeneralRows[3].value = 'SV-MT';
  if (r4[21] === '6') GeneralRows[3].value = 'SV-T43';

  if (r2[27] === '0') {
    GeneralRows[4].value = 'No';
  } else {
    GeneralRows[4].value = 'Yes';
  }
  
  if (r4[26] === '0') GeneralRows[5].value = 'None';
  if (r4[26] === '2') GeneralRows[5].value = 'Comms error';
  if (r4[26] === '3') GeneralRows[5].value = 'Water prime';
  if (r4[26] === '4') GeneralRows[5].value = 'Thermal trip';
  if (r4[26] === '5') GeneralRows[5].value = 'Pool too hot';
  if (r4[26] === '7') GeneralRows[5].value = 'Pool sensor';
  if (r4[26] === '8') GeneralRows[5].value = 'Mains volts';
  if (r4[26] === '9') GeneralRows[5].value = 'EEPROM error';
  if (r4[26] === '12') GeneralRows[5].value = 'Water flow';

  if (r4[26] === '17') GeneralRows[5].value = 'Water in thermistor';
  if (r4[26] === '18') GeneralRows[5].value = 'Water in thermistor';
  if (r4[26] === '10') GeneralRows[5].value = 'Ambient thermistor';
  if (r4[26] === '11') GeneralRows[5].value = 'Condenser thermistor';
  if (r4[26] === '19') GeneralRows[5].value = 'Compressor in thermistor';
  if (r4[26] === '15') GeneralRows[5].value = 'Compressor out thermistor';
  if (r4[26] === '20') GeneralRows[5].value = 'Heat exch in thermistor';
  if (r4[26] === '16') GeneralRows[5].value = 'Heat exch out thermistor';
  if (r4[26] === '23') GeneralRows[5].value = 'WaterEx out thermistor';
  if (r4[26] === '24') GeneralRows[5].value = 'Spare1 thermistor';

  if (r4[26] === '13') GeneralRows[5].value = 'Low pressure switch';
  if (r4[26] === '14') GeneralRows[5].value = 'High pressure switch';
  if (r4[26] === '21') GeneralRows[5].value = 'Spare 1 switch';
  if (r4[26] === '22') GeneralRows[5].value = 'Spare 2 switch';

  FiltrationControlRows[0].value = convertMinsToHrsTime(r2[16]);
  FiltrationControlRows[1].value = convertMinsToHrsTime(r2[17]);
  FiltrationControlRows[2].value = convertMinsToHrsTime(r2[18]);

  if (r4[11] === '0') FiltrationControlRows[3].value = 'No';
  if (r4[11] === '1') FiltrationControlRows[3].value = 'Yes';

  if (r4[5] === '0') ThermalControlRows[0].value = 'Off';
  if (r4[5] === '1') ThermalControlRows[0].value = 'Pause';
  if (r4[5] === '2') ThermalControlRows[0].value = 'Standby';
  if (r4[5] === '3') ThermalControlRows[0].value = 'Heat mix';
  if (r4[5] === '4') ThermalControlRows[0].value = 'Heat test';
  if (r4[5] === '5') ThermalControlRows[0].value = 'Heat';
  if (r4[5] === '6') ThermalControlRows[0].value = 'Cool mix';
  if (r4[5] === '7') ThermalControlRows[0].value = 'Cool test';
  if (r4[5] === '8') ThermalControlRows[0].value = 'Cool';

  if (r4[2] !== undefined) ThermalControlRows[1].value = r4[2].toHHMMSS();
  if (r4[6] !== undefined) ThermalControlRows[2].value = r4[6].toHHMMSS();
  if (r4[7] !== undefined) ThermalControlRows[3].value = r4[7].toHHMMSS();
  
  ThermalControlRows[4].value = (r4[9]/10.0).toFixed(1);
  if (r4[15] === '0') ThermalControlRows[5].value = '0°C'
  if (r4[15] === '1') ThermalControlRows[5].value = '-0.5°C'
  if (r4[15] === '2') ThermalControlRows[5].value = '+0.5°C'

  if (re[28] === '0') RefrigerationControlRows[0].value = 'Standby';
  if (re[28] === '1') RefrigerationControlRows[0].value = 'Start';
  if (re[28] === '2') RefrigerationControlRows[0].value = 'Run';
  if (re[28] === '3') RefrigerationControlRows[0].value = 'Stopping';
  if (re[28] === '4') RefrigerationControlRows[0].value = 'Stop';
  if (re[28] === '5') RefrigerationControlRows[0].value = 'Defrost start';
  if (re[28] === '6') RefrigerationControlRows[0].value = 'Defrost start2';
  if (re[28] === '7') RefrigerationControlRows[0].value = 'Defrost run';
  if (re[28] === '8') RefrigerationControlRows[0].value = 'Defrost stop';

  if (re[24] === '1') RefrigerationControlRows[1].value = 'Heat';
  if (re[24] === '0') RefrigerationControlRows[1].value = 'Cool';
  
  if (re[26] !== undefined) RefrigerationControlRows[2].value = re[26].toHHMMSS();
  RefrigerationControlRows[3].value = re[29];

  if (re[27] !== undefined) DefrostControlTimerslRows[0].value = re[27].toHHMMSS();
  if (re[25] !== undefined) DefrostControlTimerslRows[1].value = re[25].replace('-', '').toHHMMSS();
  if (re[30] !== undefined) DefrostControlTimerslRows[2].value = re[30].toHHMMSS();
  if (re.length > 31) {
    if (re[31] !== undefined) DefrostControlTimerslRows[3].value = re[31].toHHMMSS();
    if (re[51] !== undefined) ProtectionTimersRows[0].value = re[51].toHHMMSS();
    if (re[52] !== undefined) ProtectionTimersRows[1].value = re[52].toHHMMSS();
  } 
  
  return (
    <React.Fragment>
      <Title>Control</Title>
      <Container maxWidth="lg" sx={{ flexDirection: 'row', paddingHorizontal: 0 }}>
        <Grid container spacing={3}> 
          <Grid item sx={{ flex: 1, flexDirection: 'column' }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell><b>General</b></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {GeneralRows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.property}</TableCell>
                    <TableCell>{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Grid item>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell><b>Filtration Control</b></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {FiltrationControlRows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.property}</TableCell>
                    <TableCell>{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <Grid item>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell><b>Thermal Control</b></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ThermalControlRows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.property}</TableCell>
                    <TableCell>{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          </Grid>
          <Grid item sx={{ flex: 1, flexDirection: 'column' }}>
          <Grid item>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell><b>Refrigeration Control</b></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {RefrigerationControlRows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.property}</TableCell>
                    <TableCell>{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <Grid item>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell><b>Defrost Control Timers</b></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {DefrostControlTimerslRows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.property}</TableCell>
                    <TableCell>{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <Grid item>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell><b>Protection Timers</b></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ProtectionTimersRows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.property}</TableCell>
                    <TableCell>{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

// eslint-disable-next-line no-extend-native
String.prototype.toHHMMSS = function () {
  var sec_num = parseInt(this, 10); 
  var hours   = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  var seconds = sec_num - (hours * 3600) - (minutes * 60);

  if (hours   < 10) {hours   = "0"+hours;}
  if (minutes < 10) {minutes = "0"+minutes;}
  if (seconds < 10) {seconds = "0"+seconds;}
  return hours+':'+minutes+':'+seconds;
}

function convertMinsToHrsTime(minutes) {
  var sec_num = parseInt(minutes * 60, 10); 
  var hours   = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  var seconds = sec_num - (hours * 3600) - (minutes * 60);

  if (hours   < 10) {hours   = "0"+hours;}
  if (minutes < 10) {minutes = "0"+minutes;}
  if (seconds < 10) {seconds = "0"+seconds;}
  return hours+':'+minutes+':'+seconds;
}