import React, { useContext } from 'react';
import Title from '../resources/Title';
import Typography from '@mui/material/Typography';
import { MainContext } from '../../context/MainContext';

export default function DashboardRFString() {
  const state = useContext(MainContext).state;

  return (
    <React.Fragment>
      <Title>RF String</Title> 
      <Typography component="p" sx={{mt:0}} style={{wordWrap:'break-word', width:'100%'}}>
      </Typography>
      <Typography component="p" sx={{mt:1}} style={{wordWrap:'break-word', width:'100%'}}>
        {state.R2}:
      </Typography>
      <Typography component="p" sx={{mt:1}} style={{wordWrap:'break-word', width:'100%'}}>
        {state.R3}:
      </Typography>
      <Typography component="p" sx={{mt:1}} style={{wordWrap:'break-word', width:'100%'}}>
        {state.R4}:
      </Typography>
      <Typography component="p" sx={{mt:1}} style={{wordWrap:'break-word', width:'100%'}}>
        {state.R5}:
      </Typography>
      <Typography component="p" sx={{mt:1}} style={{wordWrap:'break-word', width:'100%'}}>
        {state.R6}:
      </Typography>
      <Typography component="p" sx={{mt:1}} style={{wordWrap:'break-word', width:'100%'}}>
        {state.R7}:
      </Typography>
      <Typography component="p" sx={{mt:1}} style={{wordWrap:'break-word', width:'100%'}}>
        {state.R9}:
      </Typography>
      <Typography component="p" sx={{mt:1}} style={{wordWrap:'break-word', width:'100%'}}>
        {state.RA}:
      </Typography>
      <Typography component="p" sx={{mt:1}} style={{wordWrap:'break-word', width:'100%'}}>
        {state.RB}:
      </Typography>
      <Typography component="p" sx={{mt:1}} style={{wordWrap:'break-word', width:'100%'}}>
        {state.RC}:
      </Typography>
      <Typography component="p" sx={{mt:1}} style={{wordWrap:'break-word', width:'100%'}}>
        {state.RE}:*
      </Typography>
      <Typography component="p" sx={{mt:1}} style={{wordWrap:'break-word', width:'100%'}}>
        {state.RG}:*
      </Typography>
    </React.Fragment>
  );
}