// Moved to package.json under proxy
export const baseURL = "https://app.spanet.net.au/"
// export const baseURL = "http://localhost:63783/"
// export const baseURL = "http://192.168.1.65:63788/"

export const apiLogin = "api/Admin/Login"
export const apiDealer = "api/Admin/DealerLogin"
export const apiLoginPin = "api/Login/Pin"
export const apiUsersList = "api/Admin/GetUsers"
export const apiClientsList = "api/Admin/GetClients"
export const apiDevicesList = "api/Admin/GetDevices" 
export const apiUserDevicesList = "api/Admin/GetUserDevices" 
export const apiGetDeviceData = "api/Admin/GetDeviceData"
export const apiRefreshDeviceData = "api/Admin/RefreshDeviceData"
export const apiSendCommand = "api/Admin/SendCommand"
export const apiGetFirmwareDetails = "api/Admin/Firmware"
export const apiFirmwareDownload = "api/Admin/FirmwareDownload"
export const apiUploadFirmware = "api/Admin/UploadFirmware"
export const apiFirmwareDeviceType = "api/Admin/FirmwareDeviceType"
export const apiFirmwareDelete = "api/Admin/FirmwareDelete"
export const apiDeleteUser = "api/Admin/DeleteUser"
export const apiPinLogin = "api/Admin/PinLogin"
export const apiGetDeviceRfString = "api/Admin/GetDeviceRfString"
export const apiSendPumpCommand = "api/Admin/SendPumpCommand"
export const apiVerifyUser = "api/Admin/VerifyUser"
export const apiGetUserPermissions = "api/Admin/GetUserPermissions"
export const apiGetClientPermissions = "api/Admin/GetClientPermissions"
export const apiSpasList = "api/Admin/GetSpas"
export const apiDeleteSpa = "api/Admin/DeleteSpa"
export const apiDeviceRfRefresh = "api/Admin/DeviceRfRefresh"
export const apiGetDeviceTypes = "api/Admin/GetDeviceTypes"
export const apiSetDeviceTypeStatus = "api/Admin/SetDeviceTypeStatus"
export const apiDealerSpasList = "api/Admin/GetDealerSpas"
export const apiAddDealerSpa = "api/Admin/AddDealerSpa"
export const apiDeleteDealerSpa = "api/Admin/DeleteDealerSpa"
export const apiDealersList = "api/Admin/GetDealers"
export const apiDeleteDealer = "api/Admin/DeleteDealer"
export const apiAddDealer = "api/Admin/AddDealer"
export const apiUpdateDealerNotification = "api/Admin/UpdateDealerNotification"
export const apiGetDealerUsername = "api/Admin/GetDealerUsername"
export const apiGetSpaBlacklist = "api/Admin/GetSpaBlacklist"
export const apiDeleteBlacklistSpa = "api/Admin/DeleteBlacklistSpa"





