import React, { useContext } from 'react';
import Typography from '@mui/material/Typography';
import Title from '../../resources/Title';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import * as Constants from '../../../components/api/constants' 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const { useState } = React;

export default function OTAUploadFirmware(props) {
  const [ file, setFile ] = useState();
  const [ filename, setFilename ] = useState('No file selected');
  const [ version, setVersion ] = useState();
  const parent = props;

  const handleFileUpload = (e) => {
    if (!e.target.files) {
      return;
    }

    setFile(e.target.files[0])
    setFilename(e.target.files[0].name)
  };

  const uploadFirmware = () => {
    const formData = new FormData();
    formData.append('Version', version);
    formData.append('Files', file);
    formData.append('DeviceType', parent.value.deviceType);

    const requestOptions = { 
      method: 'POST', 
      body: formData,  
      credentials: "include"
    };

    fetch(Constants.baseURL + Constants.apiUploadFirmware, requestOptions) 
        .then((response) => { 
            if (response.ok) {
              parent.reload()
              toast.success('Firmware uploaded successfully', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            } else {
              toast.error('Firmware upload failed. Please try again', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            }
        }).catch(error => { console.log('request failed', error); });
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <Title>Upload New Firmware</Title>
      <Container maxWidth="lg" sx={{ mt: 1 }}>
        <Grid container spacing={1} fullWidth align='center' justify='center' alignItems='center'>
          <Grid item> 
          <Button variant="outlined" component="label" startIcon={<UploadFileIcon />}>
          <input type="file" accept=".bin,.hex" hidden onChange={handleFileUpload} />
            Upload Firmware 
          </Button>
          </Grid>
          <Grid item>
            <Typography color="text.secondary" sx={{ flex: 5, textAlign: 'center' }}>
              {filename}
            </Typography>
          </Grid>
        </Grid>
        <TextField onChange={(event) => {setVersion(event.target.value)}} fullWidth label="Enter version number" variant="outlined" sx ={{ mt: 2 }} />
        <Button variant="contained" fullWidth sx={{ mt: 1 }} onClick={() => {uploadFirmware()}}>Upload</Button> 
      </Container>
    </React.Fragment>
  );
}