/* eslint-disable no-loop-func */
import React, { useContext } from 'react';
import Title from '../resources/Title';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { MainContext } from '../../context/MainContext';
import * as Constants from '../api/constants'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Generate Order Data
function createData(id, property, state, btn1, offset, btn2) {
  return { id, property, state, btn1, offset, btn2 };
}

let deviceId = 0;

export default function HPDashboardThermistorsAnalog() {
  const state = useContext(MainContext).state;
  deviceId = state.selectedDevice;
  let r3 = state.R3.split(',');
  let re = state.RE.split(',');
  
  const rows = [
    createData(0, 'Water in temp', '...', <Button size="small" variant="outlined" onClick={() => sendCommand('HPWaterInc', '')}>-</Button>, '0.0', <Button size="small" variant="outlined" onClick={() => sendCommand('HPWaterDec', '')}>+</Button>),
    createData(1, 'Water out temp', '...', <Button size="small" variant="outlined">-</Button>, '0.0', <Button size="small" variant="outlined">+</Button>),
    createData(2, 'Ambient temp', '...', <Button size="small" variant="outlined" onClick={() => sendCommand('HPAmbientInc', '')}>-</Button>, '0.0', <Button size="small" variant="outlined" onClick={() => sendCommand('HPAmbientDec', '')}>+</Button>),
    createData(3, 'Condenser temp', '...', <Button size="small" variant="outlined" onClick={() => sendCommand('HPCondensorInc', '')}>-</Button>, '0.0', <Button size="small" variant="outlined" onClick={() => sendCommand('HPCondensorDec', '')}>+</Button>),
    createData(4, 'Comp inlet temp', '...', <Button size="small" variant="outlined" onClick={() => sendCommand('HPCompInletInc', '')}>-</Button>, '0.0', <Button size="small" variant="outlined" onClick={() => sendCommand('HPCompInletDec', '')}>+</Button>),
    createData(5, 'Comp outlet temp', '...', <Button size="small" variant="outlined" onClick={() => sendCommand('HPCompOutletInc', '')}>-</Button>, '0.0', <Button size="small" variant="outlined" onClick={() => sendCommand('HPCompOutletDec', '')}>+</Button>),
    createData(6, 'Heat exch. inlet temp', '...', <Button size="small" variant="outlined" onClick={() => sendCommand('HPHeatExcInletInc', '')}>-</Button>, '0.0', <Button size="small" variant="outlined" onClick={() => sendCommand('HPHeatExcInletDec', '')}>+</Button>),
    createData(7, 'Heat exch. outlet temp', '...', <Button size="small" variant="outlined" onClick={() => sendCommand('HPHeatExcOutletInc', '')}>-</Button>, '0.0', <Button size="small" variant="outlined" onClick={() => sendCommand('HPHeatExcOutletDec', '')}>+</Button>),
    createData(8, 'Water heat exch. outlet temp', '...', <Button size="small" variant="outlined" onClick={() => sendCommand('HPWaterHeatExcOutletInc', '')}>-</Button>, '...', <Button size="small" variant="outlined" onClick={() => sendCommand('HPWaterHeatExcOutletDec', '')}>+</Button>),
    createData(9, 'Thermistor spare temp', '...', <Button size="small" variant="outlined" onClick={() => sendCommand('HPThermistorTempInc', '')}>-</Button>, '...', <Button size="small" variant="outlined" onClick={() => sendCommand('HPThermistorTempDec', '')}>+</Button>),
    createData(10, 'Mains voltage', '...', <Button size="small" variant="outlined" onClick={() => sendCommand('HPMainsVoltageInt', '')}>-</Button>, '...', <Button size="small" variant="outlined" onClick={() => sendCommand('HPMainsVoltageDec', '')}>+</Button>),
  ];

  if (re[1] === '1') {
    rows[0].state = re[13] === '0' ? '...' : (re[13]/10.0).toFixed(1) + '°C';
    rows[1].state = re[14] === '0' ? '...' : (re[14]/10.0).toFixed(1) + '°C';
    rows[2].state = (re[10]) + '°C';
    rows[3].state = (re[11]) + '°C';
    if (re[1] === '2') {
      rows[4].state = (re[10]/10.0).toFixed(1) + '°C';
      rows[5].state = (re[9]/10.0).toFixed(1) + '°C';
      rows[6].state = (re[11]/10.0).toFixed(1) + '°C';
      rows[7].state = (re[12]/10.0).toFixed(1) + '°C';
      rows[8].state = (re[64]/10.0).toFixed(1) + '°C';
      rows[9].state = (re[66]/10.0).toFixed(1) + '°C';
      rows[10].state = (re[68]/10.0).toFixed(1) + 'V';
    }
  
    rows[0].offset = re[61] === undefined ? '...' : re[61];
    rows[1].offset = re[62] === undefined ? '...' : re[62];
    rows[2].offset = r3[23] === undefined ? '...' : r3[23];
    rows[3].offset = r3[24] === undefined ? '...' : r3[24];
    rows[4].offset = re[61] === undefined ? '...' : re[61];
    rows[5].offset = re[62] === undefined ? '...' : re[62];
    rows[6].offset = re[55] === undefined ? '...' : re[55];
    rows[7].offset = re[56] === undefined ? '...' : re[56];
    rows[8].offset = re[65] === undefined ? '...' : re[65];
    rows[9].offset = re[67] === undefined ? '...' : re[67];
    rows[10].offset = re[69] === undefined ? '...' : re[69];
  } else if (re[1] === '2') {
    rows[0].state = re[13] === '0' ? '...' : (re[13]/10.0).toFixed(1) + '°C';
    rows[1].state = re[14] === '0' ? '...' : (re[14]/10.0).toFixed(1) + '°C';
    rows[2].state = (re[7]/10.0).toFixed(1) + '°C';
    rows[3].state = (re[8]/10.0).toFixed(1) + '°C';
    rows[4].state = (re[10]/10.0).toFixed(1) + '°C';
    rows[5].state = (re[9]/10.0).toFixed(1) + '°C';
    rows[6].state = (re[11]/10.0).toFixed(1) + '°C';
    rows[7].state = (re[12]/10.0).toFixed(1) + '°C';
    rows[8].state = (re[64]/10.0).toFixed(1) + '°C';
    rows[9].state = (re[66]/10.0).toFixed(1) + '°C';
    rows[10].state = re[68] + 'V';
  
    rows[0].offset = re[61] === undefined ? '...' : re[61];
    rows[1].offset = re[62] === undefined ? '...' : re[62];
    rows[2].offset = re[59] === undefined ? '...' : r3[23];
    rows[3].offset = re[60] === undefined ? '...' : r3[24];
    rows[4].offset = re[61] === undefined ? '...' : re[61];
    rows[5].offset = re[62] === undefined ? '...' : re[62];
    rows[6].offset = re[55] === undefined ? '...' : re[55];
    rows[7].offset = re[56] === undefined ? '...' : re[56];
    rows[8].offset = re[65] === undefined ? '...' : re[65];
    rows[9].offset = re[67] === undefined ? '...' : re[67];
    rows[10].offset = re[69] === undefined ? '...' : (re[69]/10.0).toFixed(1);
  }
  
  const sendCommand = (command, value) => {
    const requestOptions = { 
      method: 'POST',
      headers: { 'Accept': 'application/json', 
                 'Content-Type': 'application/json', }, 
      body: JSON.stringify({ DeviceId: deviceId,
                              Command: command, 
                              Value: value }),  
      credentials: "include"
    };
  
    fetch(Constants.baseURL + Constants.apiSendCommand, requestOptions) 
        .then((response) => { 
            if (response.ok) {
              toast.success('Command sent successfully. Refreshing...', {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
                
              setTimeout(function() {
                state.refreshDeviceRF(deviceId);
              }, 6000);

              setTimeout(function() {
                state.getUserDeviceRf(deviceId);
              }, 12000);
            } else {
              toast.error('Send failed. Please try again', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            }
        }).catch(error => { console.log('request failed', error); });
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <Title>Thermistors + Analog</Title>
      <Table size="small" >
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell><b></b></TableCell>
            <TableCell> </TableCell>
            <TableCell style={{textAlign: 'center'}}><b>Offset</b></TableCell>
            <TableCell> </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.property}</TableCell>
              <TableCell>{row.state}</TableCell>
              <TableCell style={{textAlign: 'center'}}>{row.btn1}</TableCell>
              <TableCell style={{textAlign: 'center'}}>{row.offset}</TableCell>
              <TableCell style={{textAlign: 'center'}}>{row.btn2}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}