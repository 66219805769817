import React, { Component } from 'react'; 
import * as Constants from '../components/api/constants' 
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Copyright from './copyright.component/copyright'
import Firmware from './admin.components/ota.components/OTAFirmware';
import UploadFirmware from './admin.components/ota.components/OTAUploadFirmware';
import FirmwareList from './admin.components/ota.components/OTAFirmwareList';
import FirmwareDeviceTypeList from './admin.components/ota.components/OTAFirmwareDeviceType';
import { OTAContext } from '../context/OTAContext'
import LoadingSpinner from '../components/LoadingSpinner'
import { MainContext } from '../context/MainContext'
import DynamicSelect from '../components/resources/DynamicSelect';
import Title from '../components/resources/Title';

export default class OTA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      version: '', 
      firmwareHistory: '',
      spinner: false, 
      deviceType: 2, 
      deviceTypeList: [{id: 2, name: 'SmartLink (SV-WIFI)'}]
    };
  }

  getOTAData = (deviceType = 2) => {
    const requestOptions = {
      method: 'GET', 
      credentials: "include"
    };
  
    fetch(Constants.baseURL + Constants.apiGetFirmwareDetails + "/" + deviceType, requestOptions) 
      .then(response => { 
          if (!response.ok && response.status !== 404) {
              this.props.history.push('/');
          }
          return response.json() })
      .then(data => {  
        this.setState({ 
          version: data.version, 
          firmwareHistory: data.firmwareList,
          spinner: false
        });
      }) 
      .catch(error => { console.log('request failed', error); }); 
  }

  reload = () => {
    this.getOTAData(this.state.deviceType)
  }

  componentDidMount() {
    this.setState({
      spinner: true
    })

    this.getOTAData()
  }

  render() {

    const requestOptions = {
      method: 'GET', 
      credentials: "include"
    };
  
    fetch(Constants.baseURL + Constants.apiFirmwareDeviceType, requestOptions) 
      .then(response => { 
          if (!response.ok && response.status !== 404) {
              this.props.history.push('/');
          }
          return response.json() })
      .then(data => {  
        this.setState({ 
          deviceTypeList: data.firmwareDeviceTypeList
        });
      }) 
      .catch(error => { console.log('request failed', error); }); 
      
    const handleSelectChange = (id, selectedValue) => {
      let selectedDevice = this.state.deviceTypeList.find(o => o.name === selectedValue);
      this.setState({
        deviceType: selectedDevice.id
      });
      this.getOTAData(selectedDevice.id);
    }

    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <MainContext.Provider value={this.state.spinner}>
        <LoadingSpinner />
      </MainContext.Provider>
      {/* Device Type */} 
      <Grid container spacing={3}>
        <Grid item xs={22} md={12} lg={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <Title>Device Type</Title>
            <DynamicSelect arrayOfData={this.state.deviceTypeList} onSelectChange={handleSelectChange} id={''} name={''} defaultValue={this.state.deviceTypeList[0].name} />
          </Paper>
        </Grid>
        {/* Firmware Details */} 
        <Grid item xs={22} md={6} lg={6}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 260 }}>
            <OTAContext.Provider value={this.state}>  
              <Firmware />
            </OTAContext.Provider>
          </Paper>
        </Grid>
        {/* UploadFirmware */}  
        <Grid item xs={22} md={6} lg={6}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 260 }}>
            <OTAContext.Provider>  
              <UploadFirmware reload={this.reload} value={this.state} />
            </OTAContext.Provider>
          </Paper>
        </Grid>
        {/* FirmwareList */}   
        <Grid item xs={22} md={8} lg={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <OTAContext.Provider value={this.state.firmwareHistory}> 
              <FirmwareList reload={this.reload} />
            </OTAContext.Provider>
          </Paper>
        </Grid>
        {/* Firmware Device Type */}   
        <Grid item xs={22} md={8} lg={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <OTAContext.Provider value={this.state.firmwareHistory}> 
              <FirmwareDeviceTypeList />
            </OTAContext.Provider>
          </Paper>
        </Grid>
      </Grid>
      <Copyright sx={{ pt: 4 }} />
    </Container>
    )
  }
}