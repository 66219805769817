import React, { useContext } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Title from '../resources/Title';
import { MainContext } from '../../context/MainContext';

// Generate Order Data
function createData(id, property, value) {
  return { id, property, value };
}

const rows = [
  createData(0, 'Mode', 'N/A',),
  createData(1, 'Current step', 'N/A',),
  createData(2, 'Reset state', 'N/A',),
  createData(3, 'Control temp - measured', 'N/A',),
  createData(4, 'Control temp - target', 'N/A',)
];

export default function HPDashboardEVI() {
  const state = useContext(MainContext).state;
  var re = state.RE.split(',');

  if (re[45] === '0') rows[0].value = 'Auto';
  if (re[45] === '1') rows[0].value = 'Manual';
  if (re[45] === '2') rows[0].value = 'Disabled';

  if (re.length > 31) {
    rows[1].value = re[47];

    if (re[48] === '0') rows[2].value = 'Starting';
    if (re[48] === '1') rows[2].value = 'Closing';
    if (re[48] === '2') rows[2].value = 'Close pause';
    if (re[48] === '3') rows[2].value = 'Start step';
    if (re[48] === '4') rows[2].value = 'Start pause';
    if (re[48] === '5') rows[2].value = '';

    rows[3].value = (re[54]/10.0).toFixed(1) + '°C';
    rows[4].value = re[46] + '°C';
  } else {
    rows[1].value = '0';
    rows[2].value = '0';
  }

  return (
    <React.Fragment>
      <Title>EVI</Title>
      <Table size="small">
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.property}</TableCell>
              <TableCell>{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}