import React, { useContext, useRef } from 'react';
import Title from '../resources/Title';
import { MainContext } from '../../context/MainContext'
import { UserContext } from '../../context/UserContext'
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { DataGrid } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as Constants from '../../components/api/constants' 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';

function createData(id, email, firstName, lastName, client, verified, platform, verifyUser, login, deleteUser) {
  return { id, email, firstName, lastName, client, verified, platform, verifyUser, login, deleteUser };
}

let rows = [];
let permission = 0;

const cellRenderer = (params) => {
  return params.value;
};

export default function UsersList(props) {
  const usersList = useContext(MainContext);
  const mainUserId = useContext(UserContext);
  
  const parent = props;
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState(rows);
  const [userId, setUserId] = React.useState(0);
  const history = useHistory();

  let columns = [
    { field: 'id', headerName: 'ID', minWidth: 60 },
    { field: 'email', headerName: 'Email', flex: 2, minWidth: 280 },
    { field: 'firstName', headerName: 'First Name', flex: 1, minWidth: 100 },
    { field: 'lastName', headerName: 'Last Name', flex: 1, minWidth: 100 },
    { field: 'client', headerName: 'Client', flex: 1, minWidth: 80 },
    { field: 'verified', headerName: 'Verified', flex: 1, minWidth: 100 },
    { field: 'platform', headerName: 'Platform', flex: 1, minWidth: 100 },
    { field: 'verifyUser', headerName: 'Verify User', flex: 1, minWidth: 100, renderCell: cellRenderer },
    { field: 'login', headerName: 'Login', flex: 1, minWidth: 100, renderCell: cellRenderer },
    { field: 'deleteUser', headerName: 'Delete User', flex: 1, minWidth: 100, renderCell: cellRenderer },
  ];

  if (permission === 2) {
    columns = [
      { field: 'id', headerName: 'ID', minWidth: 60 },
      { field: 'email', headerName: 'Email', flex: 2, minWidth: 280 },
      { field: 'firstName', headerName: 'First Name', flex: 1, minWidth: 100 },
      { field: 'lastName', headerName: 'Last Name', flex: 1, minWidth: 100 },
      { field: 'login', headerName: 'Login', flex: 1, minWidth: 100, renderCell: cellRenderer },
    ];
  }
 
  rows = [];
 
  const tfName = useRef('');
  const tfSpa = useRef('');
  const tfEmail = useRef('');
  const tfSupportPin = useRef('');
  const tfUserId = useRef('');

  const [data, setData] = React.useState(rows);
  React.useEffect(() => { 
    [...usersList].forEach(element => {
      rows.push(createData(element.id, element.email, element.firstName, element.lastName, element.client, element.verified ? "Yes" : "No",
      element.platform, element.verified ? <Button variant="contained" color="success" type="submit" disabled>Verify</Button> :
      <Button variant="contained" color="success" type="submit" onClick={() => {verifyUserAPI(element.id, element.email)}}>Verify</Button>,
      element.hasSpa ? <Button variant="contained" type="submit" onClick={() => {handleLoginClickOpen(element.id, element.email)}}>Login</Button> : 
      <Button variant="contained" color="success" type="submit" disabled>No Spa</Button>,
      <Button variant="contained" color="error" type="submit" onClick={() => {handleDeleteClickOpen(element.id, element.email)}}>Delete</Button>))
    });

    setData(rows)
  }, [usersList])

  const handleSearch = () => {
    parent.getUsersList(tfEmail.current.value, tfName.current.value, tfSpa.current.value, tfUserId.current.value);
  };

  const onChangeHandler = (ev) => {
    if (tfEmail.current.value === '' &&
    tfName.current.value === '' && tfSpa.current.value === '' &&
    ev.key !== 'Enter') {
      parent.getUsersList(tfEmail.current.value, tfName.current.value, tfSpa.current.value, tfUserId.current.value);
    }
  }

  const handleDeleteClickOpen = (id, email) => {
    setUserId(id)
    setEmail(email);
    setOpen(true);
  };
  const handleDeleteCloseDialog = () => {
    setOpen(false);
  }

  const handleDeleteClose = () => {
    setOpen(false);

    const requestOptions = {
      method: 'DELETE', 
      credentials: "include"
    };

    const parameters = new URLSearchParams({
      id: userId,
      email: email,
    });
    
    fetch(Constants.baseURL + Constants.apiDeleteUser + '?' + parameters, requestOptions) 
      .then(response => { 
          if (!response.ok && response.status !== 404) {
              history.push('/');
          }
          
          if (response.ok) {
            parent.getUsersList();
            toast.success('User deleted successfully', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });
          } else {
            toast.error('Firmware upload failed. Please try again', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
      .catch(error => { console.log('request failed', error); }); 
  };

  const [loginDialogOpen, setLoginDialogOpen] = React.useState(false);

  const handleLoginClickOpen = (id, email) => {
    setLoginDialogOpen(true);
    setUserId(id);
    setEmail(email);
  };

  const handleLoginClose = () => {
    setLoginDialogOpen(false);
  }

  const verifyUserAPI = (id, email) => {
    const requestOptions = { 
      method: 'POST',
      headers: { 'Accept': 'application/json', 
                 'Content-Type': 'application/json', }, 
      body: JSON.stringify({ Id: id, Email: email }),  
      credentials: "include"
    };

    fetch(Constants.baseURL + Constants.apiVerifyUser, requestOptions) 
        .then((response) => { 
            if (response.ok) {
              parent.getUsersList();
              toast.success('User verified successfully', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else {
              toast.error('Verification Failed. Please try again', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            }
        }).catch(error => { console.log('request failed', error); });
  }

  const handleLogin = () => {
    setLoginDialogOpen(false);

    const requestOptions = {
      method: 'GET', 
      credentials: "include"
    };

    const parameters = new URLSearchParams({
      id: userId,
      pin: tfSupportPin.current.value,
      email: email
    });

    fetch(Constants.baseURL + Constants.apiPinLogin + '?' + parameters, requestOptions) 
      .then(response => { 
          if (response.ok) {
            mainUserId.setUserId(userId);
            setTimeout(function() {
              history.push('/Dashboard');
            }, 1000);
          } else {
            toast.error('Error logging in. Please try again', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
      .catch(error => { console.log('request failed', error); }); 
  };

  return (
    <React.Fragment>
      <ToastContainer />
        <Stack direction="row" spacing={1} justifyContent='space-between'>
          <Title align='left'>Users</Title>
          <Stack direction="row" justifyContent={"flex-end"} spacing={1}>
            <TextField id="tfName" inputRef={tfName} label="Name" 
              variant="outlined" size="small" direction="row" 
              onKeyDown={(ev) => {
                if (ev.key === 'Enter') {
                  handleSearch()
                  ev.preventDefault();
                } 
              }}
              onChange={(ev) => {onChangeHandler(ev)}}
              justifyContent={"flex-end"} style={{ width: '20%' }} />
              <TextField id="tfSpa" inputRef={tfSpa} label="Spa" 
              variant="outlined" size="small" direction="row" 
              onKeyDown={(ev) => {
                if (ev.key === 'Enter') {
                  handleSearch()
                  ev.preventDefault();
                }
              }}
              onChange={(ev) => {onChangeHandler(ev)}}
              justifyContent={"flex-end"} style={{ width: '20%' }} />
              <TextField id="tfEmail" inputRef={tfEmail} label="Email" 
              variant="outlined" size="small" direction="row" 
              onKeyDown={(ev) => {
                if (ev.key === 'Enter') {
                  handleSearch()
                  ev.preventDefault();
                }
              }}
              onChange={(ev) => {onChangeHandler(ev)}}
              justifyContent={"flex-end"} style={{ width: '20%' }} />
              <TextField id="tfUserId" inputRef={tfUserId} label="User ID" 
              variant="outlined" size="small" direction="row" 
              onKeyDown={(ev) => {
                if (ev.key === 'Enter') {
                  handleSearch()
                  ev.preventDefault();
                }
              }}
              onChange={(ev) => {onChangeHandler(ev)}}
              justifyContent={"flex-end"} style={{ width: '20%' }} />
              <Button variant="contained" type="submit" onClick={() => {handleSearch()}}>Search</Button>
          </Stack>
        </Stack>
        <div style={{ height: 800, width: '100%', 'padding-top': '10px' }}>
          <DataGrid sx={{
                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                  outline: "none !important",
                },
            }}
            rows={data}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[5]}
            checkboxSelection={false}
            isRowSelectable={false}
            disableSelectionOnClick 
          />
          <Dialog
            open={open}
            keepMounted
            onClose={handleDeleteCloseDialog}
            aria-describedby="alert-dialog-slide-description"
          >
          <DialogTitle>{"Delete User"}</DialogTitle>
          <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Are you sure you want to delete this user: {email}?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDeleteCloseDialog}>No</Button>
              <Button onClick={handleDeleteClose}>Yes</Button>
            </DialogActions>
          </Dialog>

          <Dialog open={loginDialogOpen} onClose={handleLoginClose}>
          <DialogTitle>Login</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please enter the user's support PIN to login
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              inputRef={tfSupportPin}
              label="Support PIN"
              fullWidth
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleLoginClose}>Cancel</Button>
            <Button onClick={handleLogin}>Login</Button>
          </DialogActions>
        </Dialog>
        </div>
    </React.Fragment>
  );
}