import React from 'react';
import Title from '../resources/Title';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import * as Constants from '../api/constants' 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const { useState } = React;

export default function DealerAddSpas(props) {
  const [ dealerEmail, setDealerEmail ] = useState("");
  const parent = props;

  const addDealer = () => {
    const requestOptions = { 
      method: 'POST',
      headers: { 'Accept': 'application/json', 
                 'Content-Type': 'application/json', }, 
      body: JSON.stringify({ Email: dealerEmail}),  
      credentials: "include"
    };

    fetch(Constants.baseURL + Constants.apiAddDealer, requestOptions) 
        .then((response) => { 
            if (response.ok) {
              parent.reload()
              setDealerEmail('');
              toast.success('Dealer added successfully', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            } else {
              return response.json()
            }
          })
        .then(data => {  
          if (data !== undefined) {
            toast.error(data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });
          }
        }).catch(error => { console.log('request failed', error); });
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <Title>Add Dealer</Title>
      <Container maxWidth="lg" sx={{ mt: 1 }}>
      <TextField
          onChange={(event) => setDealerEmail(event.target.value)}
          value={dealerEmail} 
          fullWidth
          label="Dealer Email"
          variant="outlined"
          sx={{ mt: 2 }}
        />
        <Button variant="contained" fullWidth sx={{ mt: 2 }} onClick={() => {addDealer()}}>Add</Button> 
      </Container>
    </React.Fragment>
  );
}