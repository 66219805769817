import React, { useContext } from 'react';
import Typography from '@mui/material/Typography';
import Title from '../resources/Title';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { MainContext } from '../../context/MainContext';
import * as Constants from '../../components/api/constants'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let deviceId = 0;
let status = 'Off';
let speed = 0;

export default function ControlBlower() {
  const state = useContext(MainContext).state;
  deviceId = state.selectedDevice;
  const r6 = state.R6.split(',');
  const rc = state.RC.split(',');

  if (rc[10] === '0') {
    status = 'Vari';
  } else if (rc[10] === '1') {
    status = 'RAMP';
  } else if (rc[10] === '2') {
    status = 'OFF';
  }

  speed = r6[1];

  const sendCommand = (command, value) => {
    const requestOptions = { 
      method: 'POST',
      headers: { 'Accept': 'application/json', 
                 'Content-Type': 'application/json', }, 
      body: JSON.stringify({ DeviceId: deviceId,
                              Command: command, 
                              Value: value }),  
      credentials: "include"
    };
  
    fetch(Constants.baseURL + Constants.apiSendCommand, requestOptions) 
        .then((response) => { 
            if (response.ok) {
              toast.success('Command sent successfully. Refreshing...', {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
                
              setTimeout(function() {
                state.refreshDeviceRF(deviceId);
              }, 6000);

              setTimeout(function() {
                state.getUserDeviceRf(deviceId);
              }, 12000);
            } else {
              toast.error('Send failed. Please try again', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            }
        }).catch(error => { console.log('request failed', error); });
  }

  const onClick = (id, value) => {
    if (id === 'blowerRamp') sendCommand('blowerRamp', '');
    if (id === 'blowerOff') sendCommand('blowerOff', '');
    if (id === 'blowerVari') sendCommand('blowerVari', value);
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <Title>Blower</Title>
      <Typography component="p" variant="h5" sx={{ flex: 1, textAlign: 'center', pt: 1 }}>
        Current state
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1, textAlign: 'center', pt: 0 }}>
      Status: {status} | Speed: {speed}
      </Typography>
      <Container maxWidth="lg" sx={{ mt: 2, mb: 4 }} >
        <Grid container spacing={3} align='center' justify='center' alignItems='center'>
          <Grid item xs = {false} sm = {4} md = {6}> 
            <Button variant="outlined" onClick={() => onClick('blowerRamp')}>RAMP</Button>
          </Grid>
          <Grid item xs = {false} sm = {4} md = {6}>
          <Button variant="outlined" onClick={() => onClick('blowerOff')}>OFF</Button>
          </Grid>
        </Grid>
      </Container>
      <Typography color="text.secondary" sx={{ flex: 1, textAlign: 'center', pt: 0 }}>
      Variable speeds:
      </Typography>
      <Container maxWidth="lg" sx={{ mt: 2, mb: 4 }} >
        <Grid container spacing={1} align='center' justify='center' alignItems='center'>
          <Grid item> 
            <Button variant="outlined" onClick={() => onClick('blowerVari', '1')}>1</Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={() => onClick('blowerVari', '2')}>2</Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={() => onClick('blowerVari', '3')}>3</Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={() => onClick('blowerVari', '4')}>4</Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={() => onClick('blowerVari', '5')}>5</Button>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}