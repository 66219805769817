import React, { useContext } from 'react';
import Typography from '@mui/material/Typography';
import Title from '../resources/Title';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { MainContext } from '../../context/MainContext';
import * as Constants from '../api/constants'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const buttonMinWidth = 120;
let deviceId = 0;
const { useState } = React;

export default function ControlTestMode() {
  const state = useContext(MainContext).state;
  deviceId = state.selectedDevice;

  const sendCommand = (command, value) => {
    const requestOptions = { 
      method: 'POST',
      headers: { 'Accept': 'application/json', 
                 'Content-Type': 'application/json', }, 
      body: JSON.stringify({ DeviceId: deviceId,
                              Command: command, 
                              Value: value }),  
      credentials: "include"
    };
  
    fetch(Constants.baseURL + Constants.apiSendCommand, requestOptions) 
        .then((response) => { 
            if (response.ok) {
              toast.success('Command sent successfully. Refreshing...', {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
                
              setTimeout(function() {
                state.refreshDeviceRF(deviceId);
              }, 6000);

              setTimeout(function() {
                state.getUserDeviceRf(deviceId);
              }, 12000);
            } else {
              toast.error('Send failed. Please try again', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            }
        }).catch(error => { console.log('request failed', error); });
  }

  const onClickListener = (id) => {
    if (id === 'Reboot') sendCommand('Reboot');
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <Title>Reboot Controller</Title>
      <Button variant="contained" sx={{ mt: 2 }} onClick={() => onClickListener('Reboot')}>Reboot</Button>
    </React.Fragment>
  );
}