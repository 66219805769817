import React, { useContext } from 'react';
import Title from '../resources/Title';
import Typography from '@mui/material/Typography';
import { MainContext } from '../../context/MainContext';
import Button from '@mui/material/Button';
import * as Constants from '../../components/api/constants'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function HistoryPower() {
  const state = useContext(MainContext).state;
  const r7 = state.R7.split(',');
  let deviceId = state.selectedDevice;

  const sendCommand = (command, value) => {
    const requestOptions = { 
      method: 'POST',
      headers: { 'Accept': 'application/json', 
                 'Content-Type': 'application/json', }, 
      body: JSON.stringify({ DeviceId: deviceId,
                              Command: command, 
                              Value: value }),  
      credentials: "include"
    };
  
    fetch(Constants.baseURL + Constants.apiSendCommand, requestOptions) 
        .then((response) => { 
            if (response.ok) {
              toast.success('Command sent successfully. Refreshing...', {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
                
              setTimeout(function() {
                state.refreshDeviceRF(deviceId);
              }, 6000);

              setTimeout(function() {
                state.getUserDeviceRf(deviceId);
              }, 12000);
            } else {
              toast.error('Send failed. Please try again', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            }
        }).catch(error => { console.log('request failed', error); });
  }

  const onClickListener = () => {
    sendCommand('clearStartDate', '');
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <Title>Start Date</Title>
      <Typography component="p" variant="h5" sx={{ flex: 1, textAlign: 'center', pt: 2 }}>
        {r7[8] + '/' + r7[9] + '/' + r7[10]}
      </Typography>
      <Button variant="outlined" onClick={() => onClickListener()}>Clear</Button>
    </React.Fragment>
  );
}