import React from 'react';
import Title from '../resources/Title';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import * as Constants from '../../components/api/constants' 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const { useState } = React;

export default function DealerAddSpas(props) {
  const [ userId, setUserId ] = useState("");
  const [ supportPin, setSupportPin ] = useState("");
  const parent = props;

  const addDealerSpaAPI = () => {
    const requestOptions = { 
      method: 'POST',
      headers: { 'Accept': 'application/json', 
                 'Content-Type': 'application/json', }, 
      body: JSON.stringify({ DealerDeviceUserId: userId,
      DealerDeviceUserSupportPin: supportPin }),  
      credentials: "include"
    };

    fetch(Constants.baseURL + Constants.apiAddDealerSpa, requestOptions) 
        .then((response) => { 
            if (response.ok) {
              parent.reload()
              setUserId('');
              setSupportPin('');
              toast.success('Spa added successfully', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            } else {
              toast.error('Error. Please try again', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            }
        }).catch(error => { console.log('request failed', error); });
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <Title>Add Spa</Title>
      <Container maxWidth="lg" sx={{ mt: 1 }}>
      <TextField
          onChange={(event) => setUserId(event.target.value)}
          value={userId} 
          fullWidth
          label="User ID"
          variant="outlined"
          sx={{ mt: 2 }}
        />
        <TextField
          onChange={(event) => setSupportPin(event.target.value)}
          value={supportPin} 
          fullWidth
          label="Support PIN"
          variant="outlined"
          sx={{ mt: 2 }}
        />
        <Button variant="contained" fullWidth sx={{ mt: 2 }} onClick={() => {addDealerSpaAPI()}}>Add</Button> 
      </Container>
    </React.Fragment>
  );
}