import React, { useContext, useRef } from 'react';
import Title from '../resources/Title';
import { MainContext } from '../../context/MainContext'
import { UserContext } from '../../context/UserContext'
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { DataGrid } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as Constants from '../api/constants' 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';

function createData(id, macAddress, addedDateTime, messageCount, email, deviceVersion, firmwareVersion, mqttStatus, remove) {
  return { id, macAddress, addedDateTime, messageCount, email, deviceVersion, firmwareVersion, mqttStatus, remove };
}

let rows = [];

const cellRenderer = (params) => {
  return params.value;
};

export default function BlacklistList(props) {
  const blacklistList = useContext(MainContext);
  
  const parent = props;
  const [open, setOpen] = React.useState(false);
  const [id, setId] = React.useState(rows);
  const [macAddress, setMacAddress] = React.useState(rows);
  const history = useHistory();

  let columns = [
    { field: 'id', headerName: 'ID', minWidth: 60 },
    { field: 'macAddress', headerName: 'MAC Address', flex: 1, minWidth: 50 },
    { field: 'addedDateTime', headerName: 'Date Added', flex: 1, minWidth: 100 },
    { field: 'messageCount', headerName: 'Message Count', flex: 1, minWidth: 100 },
    { field: 'email', headerName: 'Email', flex: 2, minWidth: 100 },
    { field: 'deviceVersion', headerName: 'Version', flex: 1, minWidth: 50 },
    { field: 'firmwareVersion', headerName: 'Firmware Version', flex: 1, minWidth: 50 },
    { field: 'mqttStatus', headerName: 'MQTT Status', flex: 1, minWidth: 100 },
    { field: 'remove', headerName: 'Remove Blacklisted Spa', flex: 1, minWidth: 100, renderCell: cellRenderer },
  ];
 
  rows = [];
 
  const tfMacAddress = useRef('');

  const [data, setData] = React.useState(rows);
  React.useEffect(() => { 
    [...blacklistList].forEach(element => {
      const formattedDate = new Date(element.addedDateTime).toLocaleDateString('en-GB');
      
      rows.push(createData(
        element.id, 
        element.macAddress, 
        formattedDate, 
        element.messageCount,
        element.email, 
        element.deviceVersion, 
        element.firmwareVersion, 
        element.mqttStatus,
        <Button 
          variant="contained" 
          color="error" 
          type="submit" 
          onClick={() => {handleDeleteClickOpen(element.id, element.macAddress)}}
        >
          Remove
        </Button>
      ));
    });

    setData(rows)
  }, [blacklistList])

  const handleSearch = () => {
    parent.getBlacklistList(tfMacAddress.current.value);
  };

  const onChangeHandler = (ev) => {
    if (tfMacAddress.current.value === '' && ev.key !== 'Enter') {
      parent.getBlacklistList(tfMacAddress.current.value);
    }
  }

  const handleDeleteClickOpen = (id, macAddress) => {
    setId(id);
    setMacAddress(macAddress);
    setOpen(true);
  };
  const handleDeleteCloseDialog = () => {
    setOpen(false);
  }

  const handleDeleteClose = () => {
    setOpen(false);

    const requestOptions = {
      method: 'DELETE', 
      credentials: "include"
    };

    const parameters = new URLSearchParams({
      id,
      macAddress: macAddress,
    });
    
    fetch(Constants.baseURL + Constants.apiDeleteBlacklistSpa + '?' + parameters, requestOptions) 
      .then(response => { 
          if (!response.ok && response.status !== 404) {
              history.push('/');
          }
          
          if (response.ok) {
            parent.getBlacklistList();
            toast.success('Spa blacklist removed successfully', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });
          } else {
            toast.error('Blacklist removal failed. Please try again', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
      .catch(error => { console.log('request failed', error); }); 
  };

  return (
    <React.Fragment>
      <ToastContainer />
        <Stack direction="row" spacing={1} justifyContent='space-between'>
          <Title align='left'>Blacklisted Spas</Title>
          <Stack direction="row" justifyContent={"flex-end"} spacing={1}>
            <TextField id="tfMacAddress" inputRef={tfMacAddress} label="MAC Address" 
              variant="outlined" size="small" direction="row" 
              onKeyDown={(ev) => {
                if (ev.key === 'Enter') {
                  handleSearch()
                  ev.preventDefault();
                }
              }}
              onChange={(ev) => {onChangeHandler(ev)}}
              justifyContent={"flex-end"} style={{ width: '100%' }} />
              <Button variant="contained" type="submit" onClick={() => {handleSearch()}}>Search</Button>
          </Stack>
        </Stack>
        <div style={{ height: 800, width: '100%', 'padding-top': '10px' }}>
          <DataGrid sx={{
                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                  outline: "none !important",
                },
            }}
            rows={data}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[5]}
            checkboxSelection={false}
            isRowSelectable={false}
            disableSelectionOnClick 
          />
          <Dialog
            open={open}
            keepMounted
            onClose={handleDeleteCloseDialog}
            aria-describedby="alert-dialog-slide-description"
          >
          <DialogTitle>{"Remove Blacklisted Spa"}</DialogTitle>
          <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Are you sure you want to remove this spa from the backlist: {macAddress}?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDeleteCloseDialog}>No</Button>
              <Button onClick={handleDeleteClose}>Yes</Button>
            </DialogActions>
          </Dialog>
        </div>
    </React.Fragment>
  );
}