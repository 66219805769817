import React, { useContext } from 'react';
import Title from '../resources/Title';
import { MainContext } from '../../context/MainContext';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import * as Constants from '../../components/api/constants'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function createData(id, property, log1, log2, log3) {
  return { id, property, log1, log2, log3 };
}

let deviceId = 0;

const rows = [
  createData(0, 'Hr Meter', 'No log', 'No log', 'No log',),
  createData(1, 'Time', '...', '...', '...',),
  createData(2, 'Error Code', '...', '...', '...'),
  createData(3, 'Mains current', '...', '...', '...'),
  createData(4, 'Mains voltage', '...', '...', '...'),
  createData(5, 'Pool temp', '...', '...', '...'),
  createData(6, 'Heater temp', '...', '...', '...'),
  createData(7, 'Case temp', '...', '...', '...'),
  createData(8, 'Pool in use', '...', '...', '...'),
  createData(9, 'Element power', '...', '...', '...'),
  createData(10, 'Set temp', '...', '...', '...'),
];

export default function HistoryVoltageLogs() {
  const state = useContext(MainContext).state;
  const r9 = state.R9.split(',');
  const ra = state.RA.split(',');
  const rb = state.RB.split(',');

  deviceId = state.selectedDevice;

  // Column 1
  rows[0].log1 = r9[2]/10.0;
  rows[1].log1 = ConvertTime(r9[3]);

  console.log(r9)
  console.log(r9[4])

  rows[2].log1 = r9[4];
  if (r9[4] === '0') rows[2].log1 = "...";
  if (r9[4] === '1') rows[2].log1 = "ER1 Sensor";
  if (r9[4] === '2') rows[2].log1 = "ER2 Sensor";
  if (r9[4] === '3') rows[2].log1 = "ER3 Prime";
  if (r9[4] === '4') rows[2].log1 = "ER4 Thermal trip";
  if (r9[4] === '5') rows[2].log1 = "ER5 Pool too Hot";
  if (r9[4] === '6') rows[2].log1 = "ER6 12V overload";
  if (r9[4] === '7') rows[2].log1 = "ER7 IPTS";
  if (r9[4] === '8') rows[2].log1 = "ER8 HVS";
  if (r9[4] === '9') rows[2].log1 = "ER9 EEPROM error";

  rows[3].log1 = (r9[5]/10.0).toFixed(1) + 'A';
  rows[4].log1 = r9[6] + 'V';
  rows[5].log1 = (r9[7]/10.0).toFixed(1) + '°C';
  rows[6].log1 = (r9[8]/10.0).toFixed(1) + '°C';
  rows[7].log1 = r9[9] + '°C';

  rows[8].log1 = '?';
  if (r9[10] === '0') rows[8].log1 = 'No'; 
  if (r9[10] === '1') rows[8].log1 = 'Yes';

  if (r9[11] === '255') {
    rows[9].log1 = 'Off'; 
  } else {
    rows[9].log1 = r9[11] + '°C';
  }

  rows[10].log1 = (r9[12]/10.0).toFixed(1) + '°C';

  // Column 2
  rows[0].log2 = ra[2]/10.0;
  rows[1].log2 = ConvertTime(ra[3]);

  rows[2].log2 = ra[4];
  if (ra[4] === '0') rows[2].log2 = "...";
  if (ra[4] === '1') rows[2].log2 = "ER1 Sensor";
  if (ra[4] === '2') rows[2].log2 = "ER2 Sensor";
  if (ra[4] === '3') rows[2].log2 = "ER3 Prime";
  if (ra[4] === '4') rows[2].log2 = "ER4 Thermal trip";
  if (ra[4] === '5') rows[2].log2 = "ER5 Pool too Hot";
  if (ra[4] === '6') rows[2].log2 = "ER6 12V overload";
  if (ra[4] === '7') rows[2].log2 = "ER7 IPTS";
  if (ra[4] === '8') rows[2].log2 = "ER8 HVS";
  if (ra[4] === '9') rows[2].log2 = "ER9 EEPROM error";

  rows[3].log2 = (ra[5]/10.0).toFixed(1) + 'A';
  rows[4].log2 = ra[6] + 'V';
  rows[5].log2 = (ra[7]/10.0).toFixed(1) + '°C';
  rows[6].log2 = (ra[8]/10.0).toFixed(1) + '°C';
  rows[7].log2 = ra[9] + '°C';

  rows[8].log2 = '?';
  if (ra[10] === '0') rows[8].log2 = 'No'; 
  if (ra[10] === '1') rows[8].log2 = 'Yes';

  if (ra[11] === '255') {
    rows[9].log2 = 'Off'; 
  } else {
    rows[9].log2 = ra[11] + '°C';
  }

  rows[10].log2 = (ra[12]/10.0).toFixed(1) + '°C';

  // Column 3
  rows[0].log3 = rb[2]/10.0;
  rows[1].log3 = ConvertTime(rb[3]);

  rows[2].log3 = rb[4];
  if (rb[4] === '0') rows[2].log3 = "...";
  if (rb[4] === '1') rows[2].log3 = "ER1 Sensor";
  if (rb[4] === '2') rows[2].log3 = "ER2 Sensor";
  if (rb[4] === '3') rows[2].log3 = "ER3 Prime";
  if (rb[4] === '4') rows[2].log3 = "ER4 Thermal trip";
  if (rb[4] === '5') rows[2].log3 = "ER5 Pool too Hot";
  if (rb[4] === '6') rows[2].log3 = "ER6 12V overload";
  if (rb[4] === '7') rows[2].log3 = "ER7 IPTS";
  if (rb[4] === '8') rows[2].log3 = "ER8 HVS";
  if (rb[4] === '9') rows[2].log3 = "ER9 EEPROM error";

  rows[3].log3 = (rb[5]/10.0).toFixed(1) + 'A';
  rows[4].log3 = rb[6] + 'V';
  rows[5].log3 = (rb[7]/10.0).toFixed(1) + '°C';
  rows[6].log3 = (rb[8]/10.0).toFixed(1) + '°C';
  rows[7].log3 = rb[9] + '°C';

  rows[8].log3 = '?';
  if (rb[10] === '0') rows[8].log3 = 'No'; 
  if (rb[10] === '1') rows[8].log3 = 'Yes';

  if (rb[11] === '255') {
    rows[9].log3 = 'Off'; 
  } else {
    rows[9].log3 = rb[11] + '°C';
  }

  rows[10].log3 = (rb[12]/10.0).toFixed(1) + '°C';

  const sendCommand = (command, value) => {
    const requestOptions = { 
      method: 'POST',
      headers: { 'Accept': 'application/json', 
                 'Content-Type': 'application/json', }, 
      body: JSON.stringify({ DeviceId: deviceId,
                              Command: command, 
                              Value: value }),  
      credentials: "include"
    };
  
    fetch(Constants.baseURL + Constants.apiSendCommand, requestOptions) 
        .then((response) => { 
            if (response.ok) {
              toast.success('Command sent successfully. Refreshing...', {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
                
              setTimeout(function() {
                state.refreshDeviceRF(deviceId);
              }, 6000);

              setTimeout(function() {
                state.getUserDeviceRf(deviceId);
              }, 12000);
            } else {
              toast.error('Send failed. Please try again', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            }
        }).catch(error => { console.log('request failed', error); });
  }

  const onClickListener = () => {
    sendCommand('clearFaultLogs', '');
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <Title>Fault Logs</Title>
      <Table size="small" sx={{ mb: 3 }} >
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.property}</TableCell>
              <TableCell>{row.log1}</TableCell>
              <TableCell>{row.log2}</TableCell>
              <TableCell>{row.log3}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Button variant="outlined" onClick={() => onClickListener()}>Clear</Button>
    </React.Fragment>
  );
}

function ConvertTime(value) {
  var hours = value >> 8;
  var minutes = value & 0xFF;
  var hoursString = '';
  var minutesString = '';

  if (hours < 10) {
    hoursString = '0' + hours;
  } else {
    hoursString = hours;
  }

  if (minutes < 10) {
    minutesString = '0' + minutes;
  } else {
    minutesString = minutes; 
  }

  return hoursString + ':' + minutesString;
}