import React, { useContext } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Title from '../resources/Title';
import { MainContext } from '../../context/MainContext';
import { makeStyles } from '@mui/styles';
import WaterDropOutlinedIcon from '@mui/icons-material/WaterDropOutlined';
import BatterySaverOutlinedIcon from '@mui/icons-material/BatterySaverOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import CandlestickChartOutlinedIcon from '@mui/icons-material/CandlestickChartOutlined';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';

// Generate Order Data
function createData(id, icon, property, value) {
  return { id, icon, property, value };
}

const useStyles = makeStyles({
  combinedCell: {
    alignItems: 'center',
  },
  gap: {
    marginLeft: '20px', 
  },
  rightAlign: {
    textAlign: 'right',
  },
});

const rows = [
  createData(0, <WaterDropOutlinedIcon />, 'Water detected', 'N/A',),
  createData(1, <BatterySaverOutlinedIcon />, 'PSAV offset', 'N/A',),
  createData(2, <TimerOutlinedIcon />, 'T.Out', 'N/A',),
  createData(3, <CandlestickChartOutlinedIcon />, 'T/P 1', 'N/A',),
  createData(4, <CandlestickChartOutlinedIcon />, 'T/P 2', 'N/A',),
  createData(5, <CalculateOutlinedIcon />, '1.SER counter', 'N/A',),
  createData(6, <CalculateOutlinedIcon />, '2.SER counter', 'N/A',),
  createData(7, <CalculateOutlinedIcon />, '3.SER counter', 'N/A',),
];

export default function DashboardGeneral() {
  const state = useContext(MainContext).state;
  var r2 = state.R2.split(',');
  var r4 = state.R4.split(',');
  var r5 = state.R5.split(',');

  rows[0].value = r2[14] === '1' ? 'Yes' : 'No';
  if (r2[15].includes('0')) {
    rows[1].value = '0°C'
  }
  if (r2[15].includes('1')) {
    rows[1].value = '-0.5°C'
  }
  if (r2[15].includes('2')) {
    rows[1].value = '+0.5°C'
  }
  rows[2].value = new Date(r2[19] * 1000).toISOString().slice(11, 19);
  if (r5[4] === '0') rows[3].value = 'SV-2T';
  if (r5[4] === '1') rows[3].value = 'SV-3T';
  if (r5[4] === '2') rows[3].value = 'SV-4T';
  if (r5[4] === '3') rows[3].value = 'SV-4T';
  if (r5[4] === '4') rows[3].value = 'SV-RT';
  if (r5[4] === '5') rows[3].value = 'SV-MT';
  if (r5[4] === '6') rows[3].value = 'SV-T43';
  if (r5[2] === '0') rows[3].value = 'None';

  if (r5[3] === '0') rows[4].value = 'SV-2T';
  if (r5[3] === '1') rows[4].value = 'SV-3T';
  if (r5[3] === '2') rows[4].value = 'SV-4T';
  if (r5[3] === '3') rows[4].value = 'SV-4T';
  if (r5[3] === '4') rows[4].value = 'SV-RT';
  if (r5[3] === '5') rows[4].value = 'SV-MT';
  if (r5[3] === '6') rows[4].value = 'SV-T43';
  if (r5[1] === '0') rows[4].value = 'None';
  
  rows[5].value = r4[2];
  if (r4[2] === '0' || r4[2] === '...') rows[5].value = '0';
  rows[6].value = r4[3];
  if (r4[3] === '0' || r4[3] === '...') rows[6].value = '0';
  rows[7].value = r4[4];
  if (r4[4] === '0' || r4[4] === '...') rows[7].value = '0';

  const classes = useStyles();

  return (
    <React.Fragment>
      <Title>General</Title>
      <Table size="small">
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell colSpan={2} className={classes.combinedCell}>
                {row.icon}
                <span className={classes.gap}></span>
                {row.property}
              </TableCell>
              <TableCell align="right" className={classes.rightAlign}>
                {row.value}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}