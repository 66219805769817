import React, { useContext } from 'react';
import Title from '../resources/Title';
import { MainContext } from '../../context/MainContext';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import * as Constants from '../../components/api/constants'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function createData(id, property, value) {
  return { id, property, value };
}

const rows = [
  createData(0, 'Max Voltage', 'N/A',),
  createData(1, 'Min Voltage', 'N/A',),
  createData(2, 'Max Voltage (24 hr)', 'N/A',),
  createData(3, 'Min Voltage (24 hr)', 'N/A',)
];

export default function HistoryVoltageLogs() {
  const state = useContext(MainContext).state;
  const r7 = state.R7.split(',');
  let deviceId = state.selectedDevice;

  const sendCommand = (command, value) => {
    const requestOptions = { 
      method: 'POST',
      headers: { 'Accept': 'application/json', 
                 'Content-Type': 'application/json', }, 
      body: JSON.stringify({ DeviceId: deviceId,
                              Command: command, 
                              Value: value }),  
      credentials: "include"
    };
  
    fetch(Constants.baseURL + Constants.apiSendCommand, requestOptions) 
        .then((response) => { 
            if (response.ok) {
              toast.success('Command sent successfully. Refreshing...', {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
                
              setTimeout(function() {
                state.refreshDeviceRF(deviceId);
              }, 6000);

              setTimeout(function() {
                state.getUserDeviceRf(deviceId);
              }, 12000);
            } else {
              toast.error('Send failed. Please try again', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            }
        }).catch(error => { console.log('request failed', error); });
  }

  const onClickListener = () => {
    sendCommand('clearVoltage', '');
  }

  rows[0].value = r7[11];
  rows[1].value = r7[12];
  rows[2].value = r7[13];
  rows[3].value = r7[14];

  return (
    <React.Fragment>
      <ToastContainer />
      <Title>Voltage Logs</Title>
      <Table size="small" sx={{ mb: 3 }} >
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.property}</TableCell>
              <TableCell>{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Button variant="outlined" onClick={() => onClickListener()}>Clear</Button>
    </React.Fragment>
  );
}