import React, { useContext } from 'react';
import Title from '../../resources/Title';
import Button from '@mui/material/Button';
import { OTAContext } from '../../../context/OTAContext'
import * as Constants from '../../../components/api/constants' 
import fileDownload from 'js-file-download'
import { DataGrid } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Generate Order Data
function createData(id, version, filename, download, deleteFirmware) {
  return { id, version, filename, download, deleteFirmware };
}

const downloadFirmware = (id, filename) => {
  const requestOptions = {
    method: 'GET', 
    credentials: "include"
  };

  fetch(Constants.baseURL + Constants.apiFirmwareDownload + '/' + id, requestOptions) 
    .then(response => response.blob())
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a); 
            a.click();    
            a.remove();    
        })
    .catch(error => { console.log('request failed', error); }); 
}

let rows = [];

const cellRenderer = (params) => {
  return params.value;
};

export default function OTAFirmwareList(props) {
  const firmwareHistory = useContext(OTAContext);

  const parent = props;
  const [open, setOpen] = React.useState(false);
  const [firmwareId, setFirmwareId] = React.useState(0);

  const columns = [
    { field: 'id', headerName: 'ID', flex: 1, minWidth: 80 },
    { field: 'version', headerName: 'Version', flex: 1, minWidth: 100 },
    { field: 'filename', headerName: 'Filename', flex: 1, minWidth: 100 },
    { field: 'download', headerName: 'Download', flex: 1, minWidth: 150, renderCell: cellRenderer },
    { field: 'deleteFirmware', headerName: 'Delete', flex: 1, minWidth: 120, renderCell: cellRenderer }
  ];
 
  rows = [];
  [...firmwareHistory].forEach(element => {
    rows.push(createData(element.id, element.version, element.fileName, 
    <Button variant="contained" type="submit" onClick={() => {downloadFirmware(element.id, element.fileName)}}>Download</Button>,
    <Button variant="contained" color="error" type="submit" onClick={() => {handleDeleteClickOpen(element.id)}}>Delete</Button>))
  });

  const [data, setData] = React.useState(rows);
  React.useEffect(() => { setData(rows)}, [firmwareHistory] )

  const handleSearch = (event) => {;
    setData(rows.filter((item) =>
      item.version.toLowerCase().includes(event.target.value.toLowerCase())
    ));
  };

  const handleDeleteClickOpen = (id, macAddress) => {
    setFirmwareId(id);
    setOpen(true);
  };
  const handleDeleteCloseDialog = () => {
    setOpen(false);
  }

  const handleDeleteClose = () => {
    setOpen(false);

    const requestOptions = {
      method: 'DELETE', 
      credentials: "include"
    };

    const parameters = new URLSearchParams({
      id: firmwareId
    });
    
    fetch(Constants.baseURL + Constants.apiFirmwareDelete + '?' + parameters, requestOptions) 
      .then(response => { 
          if (!response.ok && response.status !== 404) {
              this.props.history.push('/');
          }
          
          if (response.ok) {
            parent.reload();
            toast.success('Firmware deleted successfully', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });
          } else {
            toast.error('Error. Please try again', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
      .catch(error => { console.log('request failed', error); }); 
  };


  return (
    <React.Fragment>
      <ToastContainer />
        <Stack direction="row" spacing={1} justifyContent='space-between'>
          <Title align='left'>Firmware History</Title>
          <Stack direction="row" justifyContent={"flex-end"} spacing={1}>
            <TextField id="outlined-basic" label="Search" 
              variant="outlined" onChange={handleSearch} size="small" direction="row" 
              justifyContent={"flex-end"} style={{ width: '100%' }} />
          </Stack>
        </Stack>
        <div style={{ height: 380, width: '100%', 'padding-top': '10px' }}>
          <DataGrid sx={{
                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                  outline: "none !important",
                },
            }}
            rows={data}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            checkboxSelection={false}
            isRowSelectable={false}
            disableSelectionOnClick 
          />

          <Dialog
            open={open}
            keepMounted
            onClose={handleDeleteCloseDialog}
            aria-describedby="alert-dialog-slide-description"
          >
          <DialogTitle>{"Delete Firmware"}</DialogTitle>
          <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Are you sure you want to delete this firmware with ID: {firmwareId}?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDeleteCloseDialog}>No</Button>
              <Button onClick={handleDeleteClose}>Yes</Button>
            </DialogActions>
          </Dialog>
        </div>
    </React.Fragment>
  );
}