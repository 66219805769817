import React, { useContext } from 'react';
import Title from '../resources/Title';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import DynamicSelect from '../resources/DynamicSelect';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { MainContext } from '../../context/MainContext';
import * as Constants from '../api/constants';
import Typography from '@mui/material/Typography';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// Generate Order Data
function createData(id, name, status, state, send) {
  return { id, name, status, state, send };
}

const selectDataArray1 = [
  {
    id: 0,
    value: ''    
  },
  {
    id: 1,
    value: ''    
  },
  {
    id: 2,
    value: ''    
  },
  {
    id: 3,
    value: ''    
  },
  {
    id: 4,
    value: ''    
  },
  {
    id: 5,
    value: ''    
  },
  {
    id: 6,
    value: ''    
  },
  {
    id: 7,
    value: ''    
  },
  {
    id: 8,
    value: ''    
  },
  {
    id: 9,
    value: ''    
  },
  {
    id: 10,
    value: ''    
  },
  {
    id: 11,
    value: ''    
  },
  {
    id: 12,
    value: ''    
  },
  {
    id: 13,
    value: ''    
  },
  {
    id: 14,
    value: ''    
  },
  {
    id: 15,
    value: ''    
  },
  {
    id: 16,
    value: ''    
  },
  {
    id: 17,
    value: ''    
  } 
];

const selectDataArray2 = [
  {
    id: 0,
    value: ''    
  },
  {
    id: 1,
    value: ''    
  },
  {
    id: 2,
    value: ''    
  },
  {
    id: 3,
    value: ''    
  },
  {
    id: 4,
    value: ''    
  }
];

const selectDataArray3 = [
  {
    id: 0,
    value: ''    
  },
  {
    id: 1,
    value: ''    
  },
  {
    id: 2,
    value: ''    
  },
  {
    id: 3,
    value: ''    
  },
  {
    id: 4,
    value: ''    
  },
  {
    id: 5,
    value: ''    
  },
  {
    id: 6,
    value: ''    
  },
  {
    id: 7,
    value: ''    
  },
  {
    id: 8,
    value: ''    
  },
  {
    id: 9,
    value: ''    
  },
  {
    id: 10,
    value: ''    
  },
  {
    id: 11,
    value: ''    
  },
  {
    id: 12,
    value: ''    
  },
  {
    id: 13,
    value: ''    
  },
  {
    id: 14,
    value: ''    
  },
  {
    id: 15,
    value: ''    
  }
];

const selectDataArray4 = [
  {
    id: 0,
    value: ''    
  },
  {
    id: 1,
    value: ''    
  },
  {
    id: 2,
    value: ''    
  },
  {
    id: 3,
    value: ''    
  }
];

const selectDataArray5 = [
  {
    id: 0,
    value: ''    
  },
  {
    id: 1,
    value: ''    
  },
  {
    id: 2,
    value: ''    
  },
  {
    id: 3,
    value: ''    
  },
  {
    id: 4,
    value: ''    
  },
  {
    id: 5,
    value: ''    
  },
  {
    id: 6,
    value: ''    
  },
  {
    id: 7,
    value: ''    
  }
];

const buttonWidth = '20%';
let deviceId = 0;

const omdeData = [
  {
    id: '1',
    name: 'Master'    
  },
  {
    id: '2',
    name: 'SV Slave'    
  },
  {
    id: '3',
    name: 'Flow Slave'    
  }
]

const econData = [];
var a = 10;
var counter = 1;
while (a >= -20) {
  econData.push({
    id: counter,
    name: a   
  });
  a--;
  counter++;
}

const clmtData = [];
a = 10;
counter = 1;
while (a <= 60) {
  clmtData.push({
    id: counter,
    name: a   
  });
  a++;
  counter++;
}

const lshdData = [];
a = 1;
counter = 1;
while (a <= 7) {
  lshdData.push({
    id: counter,
    name: a   
  });
  a++;
  counter++;
}

const fcycleData = [
  {
    id: '1',
    name: '1'    
  },
  {
    id: '2',
    name: '2'    
  },
  {
    id: '3',
    name: '3'    
  },
  {
    id: '4',
    name: '4'    
  },
  {
    id: '5',
    name: '6'    
  },
  {
    id: '6',
    name: '8'    
  },
  {
    id: '7',
    name: '12'    
  },
  {
    id: '8',
    name: '24'    
  }
];

const meevData = [
  {
    id: '1',
    name: 'Auto'    
  },
  {
    id: '2',
    name: 'Manual'    
  },
  {
    id: '3',
    name: 'Disabled'    
  }
];

const vmaxData = [];
a = 3;
counter = 1;
while (a <= 30) {
  vmaxData.push({
    id: counter,
    name: a   
  });
  a++;
  counter++;
}

const prmeData = [
  {
    id: '1',
    name: '10 second filt'    
  },
  {
    id: '2',
    name: '2 minute filt'    
  },
  {
    id: '3',
    name: '2 minute all'    
  },
  {
    id: '4',
    name: '4 minute all'    
  }
];

const tadjData = [];
a = -2.0;
counter = 1;
while (a <= 2.1) {
  tadjData.push({
    id: counter,
    name: a.toFixed(1)   
  });
  a = a + 0.1;
  counter++;
}

const llmData = [];
a = 1;
counter = 1;
while (a <= 5) {
  llmData.push({
    id: counter,
    name: a   
  });
  a++;
  counter++;
}

const unitData = [
  {
    id: '1',
    name: '°C'    
  },
  {
    id: '2',
    name: '°F'    
  }
];

const ahysData = [];
a = 0;
counter = 1;
while (a <= 200) {
  ahysData.push({
    id: counter,
    name: a   
  });
  a = a + 10;
  counter++;
}

const omaxData = [];
a = 1;
counter = 1;
while (a <= 24) {
  omaxData.push({
    id: counter,
    name: a   
  });
  a++;
  counter++;
}

const serData = [];
serData.push({
  id: 1,
  name: '0 = disabled'   
});
serData.push({
  id: 2,
  name: '1 wks'   
});
a = 2;
counter = 3;
while (a <= 52) {
  serData.push({
    id: counter,
    name: a   
  });
  a++;
  counter++;
}

const thysData = [];
a = 2;
counter = 1;
while (a <= 20) {
  thysData.push({
    id: counter,
    name: a.toString()  
  });
  a++;
  counter++;
}

const dbgnData = [];
a = 10;
counter = 1;
while (a >= -20) {
  dbgnData.push({
    id: counter,
    name: a  
  });
  a = a - 1;
  counter++;
}

const dendData = [];
a = 5;
counter = 1;
while (a <= 45) {
  dendData.push({
    id: counter,
    name: a  
  });
  a++;;
  counter++;
}

const dcmpData = [];
a = 20;
counter = 1;
while (a <= 150) {
  dcmpData.push({
    id: counter,
    name: a  
  });
  a++;;
  counter++;
}

const dmaxData = [];
a = 1;
counter = 1;
while (a <= 15) {
  dmaxData.push({
    id: counter,
    name: a  
  });
  a++;;
  counter++;
}

const cmaxData = [];
a = 70;
counter = 1;
while (a <= 110) {
  cmaxData.push({
    id: counter,
    name: a  
  });
  a++;;
  counter++;
}

const pminData = [];
a = 20;
counter = 1;
while (a <= 100) {
  pminData.push({
    id: counter,
    name: a  
  });
  a++;;
  counter++;
}

const pmaxData = [];
a = 50;
counter = 1;
while (a <= 100) {
  pmaxData.push({
    id: counter,
    name: a  
  });
  a++;;
  counter++;
}

const frunData = [];
a = 0;
counter = 1;
while (a <= 20) {
  frunData.push({
    id: counter,
    name: a  
  });
  a++;;
  counter++;
}

const coffData = [];
a = 5;
counter = 1;
while (a <= 30) {
  coffData.push({
    id: counter,
    name: a  
  });
  a++;;
  counter++;
}

const vetsData = [];
a = -5;
counter = 1;
while (a <= 10) {
  vetsData.push({
    id: counter,
    name: a  
  });
  a++;;
  counter++;
}

const vebvData = [];
a = 10;
counter = 1;
while (a <= 35) {
  vebvData.push({
    id: counter,
    name: a  
  });
  a++;;
  counter++;
}

const vesdData = [];
a = 20;
counter = 1;
while (a <= 450) {
  vesdData.push({
    id: counter,
    name: a  
  });
  a = a + 10;
  counter++;
}

const vesmData = [];
a = 50;
counter = 1;
while (a <= 200) {
  vesmData.push({
    id: counter,
    name: a  
  });
  a = a + 10;
  counter++;
}

const veatData = [];
a = 30;
counter = 1;
while (a <= 200) {
  veatData.push({
    id: counter,
    name: a  
  });
  a++;
  counter++;
}

const evisData = [];
a = -5;
counter = 1;
while (a >= 20) {
  evisData.push({
    id: counter,
    name: a  
  });
  a++;
  counter++;
}

const vvtsData = [];
a = -5;
counter = 1;
while (a >= 10) {
  vvtsData.push({
    id: counter,
    name: a  
  });
  a++;
  counter++;
}

const vvsmData = [];
a = 50;
counter = 1;
while (a <= 200) {
  vvsmData.push({
    id: counter,
    name: a  
  });
  a = a + 10;
  counter++;
}

const vvatData = [];
a = 30;
counter = 1;
while (a <= 200) {
  vvatData.push({
    id: counter,
    name: a  
  });
  a++;
  counter++;
}

const eviData = [];
a = 50;
counter = 1;
while (a <= 470) {
  eviData.push({
    id: counter,
    name: a  
  });
  a++;
  counter++;
}

const handleSelectChange = (id, selectedValue) =>{
  selectDataArray1[id].value = selectedValue;
}

const handleSelectChange2 = (id, selectedValue) =>{
  selectDataArray2[id].value = selectedValue;
}

const handleSelectChange3 = (id, selectedValue) =>{
  selectDataArray3[id].value = selectedValue;
}

const handleSelectChange4 = (id, selectedValue) =>{
  selectDataArray4[id].value = selectedValue;
}

const handleSelectChange5 = (id, selectedValue) =>{
  selectDataArray5[id].value = selectedValue;
}

export default function HPEEPROMOEMMenu() {
  const state = useContext(MainContext).state;
  const r6 = state.R6.split(',');
  const r7 = state.R7.split(',');
  const rc = state.RC.split(',');
  const rg = state.RG.split(',');

  deviceId = state.selectedDevice;

  const sendCommand = (command, value) => {
    const requestOptions = { 
      method: 'POST',
      headers: { 'Accept': 'application/json', 
                 'Content-Type': 'application/json', }, 
      body: JSON.stringify({ DeviceId: deviceId,
                              Command: command, 
                              Value: value.toString() }),  
      credentials: "include"
    };
  
    fetch(Constants.baseURL + Constants.apiSendCommand, requestOptions) 
        .then((response) => {
            if (response.ok) {
              toast.success('Command sent successfully. Refreshing...', {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
                
              setTimeout(function() {
                state.refreshDeviceRF(deviceId);
              }, 6000);

              setTimeout(function() {
                state.getUserDeviceRf(deviceId);
              }, 12000);
            } else {
              toast.error('Send failed. Please try again', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
            }
        }).catch(error => { console.log('request failed', error); });
  }

  const onClick1 = (id) => {
    if (id === 0) {
      sendCommand('HPEEPROMOmde', selectDataArray1[id].value);
    } else if (id === 1) {
      sendCommand('HPEEPROMFcyc', selectDataArray1[id].value);
    } else if (id === 2) {
      sendCommand('HPEEPROMUnit', selectDataArray1[id].value);
    } else if (id === 3) {
      sendCommand('HPEEPROMAhys', selectDataArray1[id].value);
    } else if (id === 4) {
      sendCommand('HPEEPROMThys', selectDataArray1[id].value);
    } else if (id === 5) {
      sendCommand('HPEEPROMPrme', selectDataArray1[id].value);
    } else if (id === 6) {
      sendCommand('HPEEPROMMeev', selectDataArray1[id].value);
    } else if (id === 7) {
      sendCommand('HPEEPROMMevi', selectDataArray1[id].value);
    } else if (id === 8) {
      sendCommand('HPEEPROMEcon', selectDataArray1[id].value);
    } else if (id === 9) {
      sendCommand('HPEEPROMEcom', selectDataArray1[id].value);
    } else if (id === 10) {
      sendCommand('HPEEPROMFrun', selectDataArray1[id].value);
    } else if (id === 11) {
      sendCommand('HPEEPROMPfre', selectDataArray1[id].value);
    } else if (id === 12) {
      sendCommand('HPEEPROMDbgn', selectDataArray1[id].value);
    } else if (id === 13) {
      sendCommand('HPEEPROMDend', selectDataArray1[id].value);
    } else if (id === 14) {
      sendCommand('HPEEPROMDcmp', selectDataArray1[id].value);
    } else if (id === 15) {
      sendCommand('HPEEPROMDmax', selectDataArray1[id].value);
    } else if (id === 16) {
      sendCommand('HPEEPROMCoff', selectDataArray1[id].value);
    } else if (id === 17) {
      sendCommand('HPEEPROMCmax', selectDataArray1[id].value);
    }
  }

  const onClick2 = (id) => {
    if (id === 0) {
      sendCommand('HPEEPROMVets', selectDataArray2[id].value);
    } else if (id === 1) {
      sendCommand('HPEEPROMVebv', selectDataArray2[id].value);
    } else if (id === 2) {
      sendCommand('HPEEPROMVesd', selectDataArray2[id].value);
    } else if (id === 3) {
      sendCommand('HPEEPROMVesm', selectDataArray2[id].value);
    } else if (id === 4) {
      sendCommand('HPEEPROMVeat', selectDataArray2[id].value);
    } 
  }

  const onClick3 = (id) => {
    if (id === 0) {
      sendCommand('HPEEPROMVeh1', selectDataArray3[id].value);
    } else if (id === 1) {
      sendCommand('HPEEPROMVeh2', selectDataArray3[id].value);
    } else if (id === 2) {
      sendCommand('HPEEPROMVeh3', selectDataArray3[id].value);
    } else if (id === 3) {
      sendCommand('HPEEPROMVeh4', selectDataArray3[id].value);
    } else if (id === 4) {
      sendCommand('HPEEPROMVeh5', selectDataArray3[id].value);
    } else if (id === 5) {
      sendCommand('HPEEPROMVeh6', selectDataArray3[id].value);
    } else if (id === 6) {
      sendCommand('HPEEPROMVeh7', selectDataArray3[id].value);
    } else if (id === 7) {
      sendCommand('HPEEPROMVeh8', selectDataArray3[id].value);
    } else if (id === 8) {
      sendCommand('HPEEPROMVec1', selectDataArray3[id].value);
    } else if (id === 9) {
      sendCommand('HPEEPROMVec2', selectDataArray3[id].value);
    } else if (id === 10) {
      sendCommand('HPEEPROMVec3', selectDataArray3[id].value);
    } else if (id === 11) {
      sendCommand('HPEEPROMVec4', selectDataArray3[id].value);
    } else if (id === 12) {
      sendCommand('HPEEPROMVec5', selectDataArray3[id].value);
    } else if (id === 13) {
      sendCommand('HPEEPROMVec6', selectDataArray3[id].value);
    } else if (id === 14) {
      sendCommand('HPEEPROMVec7', selectDataArray3[id].value);
    } else if (id === 15) {
      sendCommand('HPEEPROMVec8', selectDataArray3[id].value);
    }
  }

  const onClick4 = (id) => {
    if (id === 0) {
      sendCommand('HPEEPROMEvis', selectDataArray4[id].value);
    } else if (id === 1) {
      sendCommand('HPEEPROMVvts', selectDataArray4[id].value);
    } else if (id === 2) {
      sendCommand('HPEEPROMVvsm', selectDataArray4[id].value);
    } else if (id === 3) {
      sendCommand('HPEEPROMVvat', selectDataArray4[id].value);
    } 
  }

  const onClick5 = (id) => {
    if (id === 0) {
      sendCommand('HPEEPROMVeh1', selectDataArray5[id].value);
    } else if (id === 1) {
      sendCommand('HPEEPROMVeh2', selectDataArray5[id].value);
    } else if (id === 2) {
      sendCommand('HPEEPROMVeh3', selectDataArray5[id].value);
    } else if (id === 3) {
      sendCommand('HPEEPROMVeh4', selectDataArray5[id].value);
    } else if (id === 4) {
      sendCommand('HPEEPROMVeh5', selectDataArray5[id].value);
    } else if (id === 5) {
      sendCommand('HPEEPROMVeh6', selectDataArray5[id].value);
    } else if (id === 6) {
      sendCommand('HPEEPROMVeh7', selectDataArray5[id].value);
    } else if (id === 7) {
      sendCommand('HPEEPROMVeh8', selectDataArray5[id].value);
    } 
  }

  const rows = [
    createData(0, 'O.MDE', 'N/A', <DynamicSelect arrayOfData={omdeData} onSelectChange={handleSelectChange} id={0} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick1(0)}}>Send</Button>),
    createData(1, 'F.CYC', 'N/A', <DynamicSelect arrayOfData={fcycleData} onSelectChange={handleSelectChange} id={1} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick1(1)}}>Send</Button>),
    createData(2, 'UNIT', 'N/A', <DynamicSelect arrayOfData={unitData} onSelectChange={handleSelectChange} id={2} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick1(2)}}>Send</Button>),
    createData(3, 'A.HYS', 'N/A', <DynamicSelect arrayOfData={ahysData} onSelectChange={handleSelectChange} id={3} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick1(3)}}>Send</Button>),
    createData(4, 'T.HYS', 'N/A', <DynamicSelect arrayOfData={thysData} onSelectChange={handleSelectChange} id={4} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick1(4)}}>Send</Button>),
    createData(5, 'PRME', 'N/A', <DynamicSelect arrayOfData={prmeData} onSelectChange={handleSelectChange} id={5} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick1(5)}}>Send</Button>),
    createData(6, 'M.EEV', 'N/A', <DynamicSelect arrayOfData={meevData} onSelectChange={handleSelectChange} id={6} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick1(6)}}>Send</Button>),
    createData(7, 'M.EVI', 'N/A', <DynamicSelect arrayOfData={meevData} onSelectChange={handleSelectChange} id={7} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick1(7)}}>Send</Button>),
    createData(8, 'E.CON', 'N/A', <DynamicSelect arrayOfData={econData} onSelectChange={handleSelectChange} id={8} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick1(8)}}>Send</Button>),
    createData(9, 'E.COM', 'N/A', <DynamicSelect arrayOfData={econData} onSelectChange={handleSelectChange} id={9} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick1(9)}}>Send</Button>),
    createData(10, 'F.RUN', 'N/A', <DynamicSelect arrayOfData={frunData} onSelectChange={handleSelectChange} id={10} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick1(10)}}>Send</Button>),
    createData(11, 'P.FRE', 'N/A', <DynamicSelect arrayOfData={econData} onSelectChange={handleSelectChange} id={11} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick1(11)}}>Send</Button>),
    createData(12, 'D.BGN', 'N/A', <DynamicSelect arrayOfData={econData} onSelectChange={handleSelectChange} id={12} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick1(12)}}>Send</Button>),
    createData(13, 'D.END', 'N/A', <DynamicSelect arrayOfData={dendData} onSelectChange={handleSelectChange} id={13} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick1(13)}}>Send</Button>),
    createData(14, 'D.CMP', 'N/A', <DynamicSelect arrayOfData={dcmpData} onSelectChange={handleSelectChange} id={14} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick1(14)}}>Send</Button>),
    createData(15, 'D.MAX', 'N/A', <DynamicSelect arrayOfData={dmaxData} onSelectChange={handleSelectChange} id={15} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick1(15)}}>Send</Button>),
    createData(16, 'C.OFF', 'N/A', <DynamicSelect arrayOfData={coffData} onSelectChange={handleSelectChange} id={16} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick1(16)}}>Send</Button>),
    createData(17, 'C.MAX', 'N/A', <DynamicSelect arrayOfData={cmaxData} onSelectChange={handleSelectChange} id={17} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick1(17)}}>Send</Button>),
  ];
  
  const rows2 = [
    createData(0, 'VE.TS', 'N/A', <DynamicSelect arrayOfData={vetsData} onSelectChange={handleSelectChange2} id={0} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick2(0)}}>Send</Button>),
    createData(1, 'VE.BV', 'N/A', <DynamicSelect arrayOfData={vebvData} onSelectChange={handleSelectChange2} id={1} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick2(1)}}>Send</Button>),
    createData(2, 'VE.SD', 'N/A', <DynamicSelect arrayOfData={vesdData} onSelectChange={handleSelectChange2} id={2} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick2(2)}}>Send</Button>),
    createData(3, 'VE.SM', 'N/A', <DynamicSelect arrayOfData={vesmData} onSelectChange={handleSelectChange2} id={3} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick2(3)}}>Send</Button>),
    createData(4, 'VE.AT', 'N/A', <DynamicSelect arrayOfData={veatData} onSelectChange={handleSelectChange2} id={4} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick2(4)}}>Send</Button>),
  ];

  const rows3 = [
    createData(0, 'VE.H1', 'N/A', <DynamicSelect arrayOfData={eviData} onSelectChange={handleSelectChange3} id={0} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick3(0)}}>Send</Button>),
    createData(1, 'VE.H2', 'N/A', <DynamicSelect arrayOfData={eviData} onSelectChange={handleSelectChange3} id={1} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick3(1)}}>Send</Button>),
    createData(2, 'VE.H3', 'N/A', <DynamicSelect arrayOfData={eviData} onSelectChange={handleSelectChange3} id={2} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick3(2)}}>Send</Button>),
    createData(3, 'VE.H4', 'N/A', <DynamicSelect arrayOfData={eviData} onSelectChange={handleSelectChange3} id={3} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick3(3)}}>Send</Button>),
    createData(4, 'VE.H5', 'N/A', <DynamicSelect arrayOfData={eviData} onSelectChange={handleSelectChange3} id={4} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick3(4)}}>Send</Button>),
    createData(5, 'VE.H6', 'N/A', <DynamicSelect arrayOfData={eviData} onSelectChange={handleSelectChange3} id={5} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick3(5)}}>Send</Button>),
    createData(6, 'VE.H7', 'N/A', <DynamicSelect arrayOfData={eviData} onSelectChange={handleSelectChange3} id={6} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick3(6)}}>Send</Button>),
    createData(7, 'VE.H8', 'N/A', <DynamicSelect arrayOfData={eviData} onSelectChange={handleSelectChange3} id={7} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick3(7)}}>Send</Button>),
    createData(8, 'VE.C1', 'N/A', <DynamicSelect arrayOfData={serData} onSelectChange={handleSelectChange3} id={8} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick3(8)}}>Send</Button>),
    createData(9, 'VE.C2', 'N/A', <DynamicSelect arrayOfData={eviData} onSelectChange={handleSelectChange3} id={9} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick3(9)}}>Send</Button>),
    createData(10, 'VE.C3', 'N/A', <DynamicSelect arrayOfData={eviData} onSelectChange={handleSelectChange3} id={10} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick3(10)}}>Send</Button>),
    createData(11, 'VE.C4', 'N/A', <DynamicSelect arrayOfData={eviData} onSelectChange={handleSelectChange3} id={11} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick3(11)}}>Send</Button>),
    createData(12, 'VE.C5', 'N/A', <DynamicSelect arrayOfData={eviData} onSelectChange={handleSelectChange3} id={12} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick3(12)}}>Send</Button>),
    createData(13, 'VE.C6', 'N/A', <DynamicSelect arrayOfData={eviData} onSelectChange={handleSelectChange3} id={13} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick3(13)}}>Send</Button>),
    createData(14, 'VE.C7', 'N/A', <DynamicSelect arrayOfData={eviData} onSelectChange={handleSelectChange3} id={14} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick3(14)}}>Send</Button>),
    createData(15, 'VE.C8', 'N/A', <DynamicSelect arrayOfData={eviData} onSelectChange={handleSelectChange3} id={15} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick3(15)}}>Send</Button>),
  ];

  const rows4 = [
    createData(0, 'EVIS', 'N/A', <DynamicSelect arrayOfData={evisData} onSelectChange={handleSelectChange4} id={0} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick4(0)}}>Send</Button>),
    createData(1, 'VV.TS', 'N/A', <DynamicSelect arrayOfData={vvtsData} onSelectChange={handleSelectChange4} id={1} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick4(1)}}>Send</Button>),
    createData(2, 'VV.SM', 'N/A', <DynamicSelect arrayOfData={vvsmData} onSelectChange={handleSelectChange4} id={2} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick4(2)}}>Send</Button>),
    createData(3, 'VV.AT', 'N/A', <DynamicSelect arrayOfData={vvatData} onSelectChange={handleSelectChange4} id={3} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick4(3)}}>Send</Button>),
  ];

  const rows5 = [
    createData(0, 'VV.H1', 'N/A', <DynamicSelect arrayOfData={eviData} onSelectChange={handleSelectChange5} id={0} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick5(0)}}>Send</Button>),
    createData(1, 'VV.H2', 'N/A', <DynamicSelect arrayOfData={eviData} onSelectChange={handleSelectChange5} id={1} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick5(1)}}>Send</Button>),
    createData(2, 'VV.H3', 'N/A', <DynamicSelect arrayOfData={eviData} onSelectChange={handleSelectChange5} id={2} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick5(2)}}>Send</Button>),
    createData(3, 'VV.H4', 'N/A', <DynamicSelect arrayOfData={eviData} onSelectChange={handleSelectChange5} id={3} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick5(3)}}>Send</Button>),
    createData(4, 'VV.H5', 'N/A', <DynamicSelect arrayOfData={eviData} onSelectChange={handleSelectChange5} id={4} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick5(4)}}>Send</Button>),
    createData(5, 'VV.H6', 'N/A', <DynamicSelect arrayOfData={eviData} onSelectChange={handleSelectChange5} id={5} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick5(5)}}>Send</Button>),
    createData(6, 'VV.H7', 'N/A', <DynamicSelect arrayOfData={eviData} onSelectChange={handleSelectChange5} id={6} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick5(6)}}>Send</Button>),
    createData(7, 'VV.H8', 'N/A', <DynamicSelect arrayOfData={eviData} onSelectChange={handleSelectChange5} id={7} name={''} />, <Button sx={{ width: buttonWidth }} variant="outlined" onClick={() => {onClick5(7)}}>Send</Button>),
  ];

  if (r6[30] === '0') rows[0].status = 'Master';
  if (r6[30] === '1') rows[0].status = 'SV slave';
  if (r6[30] === '2') rows[0].status = 'Flow switch slave';
  
  rows[1].status = r6[7];
  rows[2].status = '?';
  if (r7[1] === '0') rows[2].status = '°C';
  if (r7[1] === '1') rows[2].status = '°F';
  rows[3].status = (r7[6]/10.0).toFixed(1);
  rows[4].status = r7[8];

  if (r6[24] === '0') rows[5].status = '10secF';
  if (r6[24] === '1') rows[5].status = '2minF';
  if (r6[24] === '2') rows[5].status = '2minA';
  if (r6[24] === '3') rows[5].status = '4minA';

  if (rc[11] === '0') {
    rows[6].status = 'Auto';
  } else {
    if (rc[11] === '1') rows[6].status = 'Manual';
    if (rc[11] === '2') rows[6].status = 'Disable';
  }

  if (rc[15] === '0') {
    rows[7].status = 'Auto';
  } else {
    if (rc[15] === '1') rows[7].status = 'Manual';
    if (rc[15] === '2') rows[7].status = 'Disable';
  }

  rows[8].status = r6[5];
  rows[9].status = r6[25];
  rows[10].status = r6[29];
  rows[11].status = r6[27];
  rows[12].status = r6[1];
  rows[13].status = r6[2];
  rows[14].status = r6[3];
  rows[15].status = r6[4];
  rows[16].status = r6[28];
  rows[17].status = r6[22];

  rows3[0].status = rg[1];
  rows3[1].status = rg[2];
  rows3[2].status = rg[3];
  rows3[3].status = rg[4];
  rows3[4].status = rg[5];
  rows3[5].status = rg[6];
  rows3[6].status = rg[7];
  rows3[7].status = rg[8];

  rows3[8].status = rg[9];
  rows3[9].status = rg[10];
  rows3[10].status = rg[11];
  rows3[11].status = rg[12];
  rows3[12].status = rg[13];
  rows3[13].status = rg[14];
  rows3[14].status = rg[15];
  rows3[15].status = rg[16];

  rows2[0].status = rg[25];
  rows2[1].status = rg[10];
  rows2[2].status = rg[11];
  rows2[3].status = rg[12];
  rows2[4].status = rg[30];

  rows4[0].status = r7[9];
  rows4[1].status = rg[26];
  rows4[2].status = rg[27];
  rows4[3].status = rg[29];

  rows5[0].status = rg[17];
  rows5[1].status = rg[18];
  rows5[2].status = rg[19];
  rows5[3].status = rg[20];
  rows5[4].status = rg[21];
  rows5[5].status = rg[22];
  rows5[6].status = rg[23];
  rows5[7].status = rg[24];

  return (
    <React.Fragment>
      <ToastContainer />
      <Title>OEM Menu</Title>
        <Container maxWidth="lg" sx={{ flexDirection: 'row', paddingHorizontal: 5, mt: 2 }}>
          <Grid container spacing={3}> 
          <Grid item sx={{ flex: 1, flexDirection: 'column' }} xs={12} md={6} lg={6}>
            <Table size="small">
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.status}</TableCell>
                    <TableCell>{row.state}</TableCell>
                    <TableCell>{row.send}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <Grid item sx={{ flex: 1, flexDirection: 'column' }} xs={12} md={6} lg={6}>
            <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>Expansion valve (EEV manual params)</Typography >
            <Table size="small" >
              <TableBody>
                {rows3.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.status}</TableCell>
                    <TableCell>{row.state}</TableCell>
                    <TableCell>{row.send}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <Grid item sx={{ flex: 1, flexDirection: 'column' }} xs={12} md={6} lg={6}>
            <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>Expansion valve (EEV)</Typography >
            <Table size="small" >
              <TableBody>
                {rows2.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.status}</TableCell>
                    <TableCell>{row.state}</TableCell>
                    <TableCell>{row.send}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <Grid item sx={{ pt: 5 }} xs={12} md={6} lg={6}>
            <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>Vapor valve (EVI)</Typography >
            <Table size="small" >
              <TableBody>
                {rows4.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.status}</TableCell>
                    <TableCell>{row.state}</TableCell>
                    <TableCell>{row.send}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <Grid item sx={{ pt: 5 }} xs={12} md={6} lg={6}>
            <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>Vapor valve (EVI) manual params</Typography >
            <Table size="small" >
              <TableBody>
                {rows5.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.status}</TableCell>
                    <TableCell>{row.state}</TableCell>
                    <TableCell>{row.send}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}